import React, { Component } from 'react'; 
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { apiURL, getID, isLogin, getPassword, getEmail } from '../utils';   
import axios from 'axios'; 
import Alert from '@material-ui/lab/Alert'; 
import { FormGroup, FormControlLabel, Snackbar, DialogTitle, TextField, Button, Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';   
import DialogContent from '@material-ui/core/DialogContent'; 
import LockOpenIcon from '@material-ui/icons/LockOpen';

const ResetPasswordSchema = yup.object().shape({
    old_password: yup.string().required("This field is required."), 
    new_password: yup.string().required("This field is required."), 
    confirm_password: yup.string().required("This field is required.").when("new_password", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf(
          [yup.ref("new_password")],
          "Both New password and Confirm Password need to be the same"
        )
      }), 
}); 
 
const md5 = require('md5');
class ResetPassword extends React.Component {
 
constructor(props){
    super(props);
    this.state = {
        oldPasswordError: false,
        oldPasswordErrorMsg: '',
        showSnackbar: false,
        snackBarMessage: '',
        severityColor: '',
        snackBarTimer: '',
        dialogOpen: true,
    }
}

checkOldPassword = (e) => {
    const password = md5(e.target.value);
    if(password !== getPassword())
    {
        this.setState({ oldPasswordError: true, oldPasswordErrorMsg: "Invalid Old Oassword"})
    }
    else{
        this.setState({ oldPasswordError: false, oldPasswordErrorMsg: ""})
    }
}

onSubmit = (values) => { 
    const headers = { 
        'Content-type': 'application/json'
    }
    const body = { 
        "email": getEmail(),
        "password": values.new_password,
    }
    axios.post(apiURL('register/reset_password'), body, {headers: headers})
    .then(response => { 
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        {  
        this.setState({ 
            snackBarMessage:resultData.message,
            showSnackbar: true, 
            severityColor:'success',
            snackBarTimer: 6000, 
            dialogOpen: false
            });
            
            localStorage.setItem('user_detail', JSON.stringify(resultData.result));
            localStorage.setItem('login_status', true); 
        } 
        else{
        this.setState({ snackBarMessage: resultData.message,showSnackbar: true , severityColor:'error', snackBarTimer: 6000});
        }  
    })

    .catch(error => {
        console.log(error)
    }) 
}


    
snackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    this.setState({ showSnackbar: false }); 
}


 render () { 
    return (
      <React.Fragment>
       <Dialog  fullWidth={true} maxWidth={"sm"} open={this.state.dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="boxMain">  
                    <DialogTitle id="max-width-dialog-title"><LockOpenIcon /> Reset Password</DialogTitle>
                    <DialogContent className="dialouge">
                        <Formik
                            initialValues={{
                                old_password: "", 
                                new_password: "",
                                confirm_password: ""
                            }}
                            validationSchema={ResetPasswordSchema}
                            onSubmit={values => {
                                if(!this.state.oldPasswordError){
                                    //alert(JSON.stringify(values))
                                    this.onSubmit(values);
                                }
                            }}
                            >
                            {({ errors, handleChange, touched }) => ( 
                                <Form style={{ display: 'flex',padding:"0px 25px 25px 25px"}} id="forgot-password"> 
                                <Grid container>
                                    <Grid item xs={12} sm={12}>  
                                        <TextField variant="outlined" fullWidth id="standard-basic" type="password" className="mt-4" name="old_password" label="Enter Old Password" onChange={handleChange} onBlur={(e) => this.checkOldPassword(e)} value={this.state.old_password} error={errors.old_password && touched.old_password || this.state.oldPasswordError} helperText={errors.old_password && touched.old_password ? errors.old_password : null || this.state.oldPasswordErrorMsg} />

                                        <TextField variant="outlined" fullWidth id="standard-basic" type="password" className="mt-4" name="new_password" label="Enter New Password" onChange={handleChange} value={this.state.new_password} error={errors.new_password && touched.new_password} helperText={errors.new_password && touched.new_password ? errors.new_password : null} />

                                        <TextField variant="outlined" fullWidth id="standard-basic" type="password" className="mt-4" name="confirm_password" label="Confirm Password" onChange={handleChange} value={this.state.confirm_password} error={errors.confirm_password && touched.confirm_password} helperText={errors.confirm_password && touched.confirm_password ? errors.confirm_password : null} />
                                    </Grid> 
                                    <Grid item xs={12} sm={12}>  
                                     
                                        <Button type="submit" fullWidth variant="contained" color="primary" className="mt-4 text-capitalize" >Submit</Button>
                                    </Grid>
                                </Grid>
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                </Dialog>
                
                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
      </React.Fragment>
    );
  }
};
  
export default ResetPassword;