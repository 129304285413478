import firebase from 'firebase'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAkwIk6mSQvHxMfO0-xtbM7uZ408SVdt6I",
    authDomain: "navdisha-firebase-messanging.firebaseapp.com",
    databaseURL: "https://navdisha-firebase-messanging.firebaseio.com",
    projectId: "navdisha-firebase-messanging",
    storageBucket: "navdisha-firebase-messanging.appspot.com",
    messagingSenderId: "15890222363",
    appId: "1:15890222363:web:73d3b2a481a2a256edd7be" // beeonline email address

    // apiKey: "AIzaSyBmlPL4x8OPyV5phDzA4FjwUhKU0bmggXw",
    // authDomain: "navdisah-firebase-messaging.firebaseapp.com",
    // databaseURL: "https://navdisah-firebase-messaging.firebaseio.com",
    // projectId: "navdisah-firebase-messaging",
    // storageBucket: "navdisah-firebase-messaging.appspot.com",
    // messagingSenderId: "84701848496",
    // appId: "1:84701848496:web:a811e1eec9f77f94016c75"  // personal email address
}

 // Initialize Firebase
 firebase.initializeApp(firebaseConfig);

 export default firebase;