import React, { Component } from 'react';   
import { withStyles } from "@material-ui/core/styles"; 
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types"; 
import { apiURL, getID } from '../utils'; 
import axios from 'axios';   
import { Grid} from '@material-ui/core';   
import TextareaAutosize from '@material-ui/core/TextareaAutosize'; 
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 

const styles = theme => ({
   root: {
      '& > *': {
         margin: theme.spacing(0),
         width: '100%',
      },
   },
});

class WebinarForm extends Component {    
   _isMounted = false; 
   constructor(props){
      super(props);
      this.state = { 
         checked: false,
         selected: null,
         selected1:null,
         hasError: false,
         stateList: [],
      } 
      this.submitFeedback = this.submitFeedback.bind(this);
   }

   componentDidMount()
   {
      this._isMounted = true; 
      window.scrollTo(0, 0);
   }
   
   submitFeedback = (e) =>{
      e.preventDefault();
      const headers = {
         'content-type': 'multipart/form-data',
         'Accept': 'application/json'
      }
      const body = {
         "webinar_id": this.props.webinarId,
         "user_id": getID(),
         "feedback" : this.state.feedback
      } 
      axios.post(apiURL('webinar_feedback/add'), body, {headers: headers})
         .then(response => {
         const resultData = response.data; 
         if(resultData.status === true || resultData.status === 1)
         { 
            this.setState({ 
               showSnackbar: true,
               snackBarMessage: resultData.message,
               severityColor: 'success',
               snackBarTimer: '3000'
            });
            this.props.formClose(true);
         } 
         else{
            this.setState({ 
               showSnackbar: true,
               snackBarMessage: resultData.message,
               severityColor: 'error',
               snackBarTimer: '3000'
            });
         }
      })
      .catch(error => {
         console.log(error)
      })  
   }

   componentWillUnmount() {
      this._isMounted = false; 
   }
   
   handleChange(e) {
      this.setState({
         [e.target.name]: e.target.value
      });
   }
 
   snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
        this.setState({ showSnackbar: false }); 
    }
 
render() {
   const { classes } = this.props;
   const { selected, hasError , selected1} = this.state;
   return (
      <React.Fragment>
         <form className="careerForm feedBackForm" onSubmit={(e) => this.submitFeedback(e)} noValidate autoComplete="off" >
            <Grid container>
               <Grid item xs={12} sm={12}>
                  <TextareaAutosize
                     rowsMax={4}
                     className="w-100 mt-4 textAreaBox"
                     aria-label="maximum height"
                     placeholder="Message"
                     defaultValue={this.state.feedback}
                     onChange={(e) => this.handleChange(e)}
                     name="feedback"
                     />
               </Grid>
               <Grid item xs={12} sm={12} > 
                  <Button type="submit"    className=" CheckButton text-center mt-3" >
                  SUBMIT
                  </Button>
               </Grid>
            </Grid>
         </form>
         <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
            <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
            { this.state.snackBarMessage }
            </Alert>
         </Snackbar>
      </React.Fragment>
      );
   }
}
WebinarForm.propTypes = {
classes: PropTypes.object.isRequired
};
export default withStyles(styles)(WebinarForm);