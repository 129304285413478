import React,  {Component} from 'react';
import ReactDom from 'react-dom';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';  
import Footer from '../components/Footer';
import {  Container,  Row, Col } from 'bootstrap-4-react';    
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab"; 
import "../style/Discussion.scss";   
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';  
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table, TableContainer, TableBody, TableCell, TableHead, TableRow,} from '@material-ui/core';
import { List } from 'bootstrap-4-react';
import axios from 'axios'; 
import { Link } from 'react-router-dom'; 
import { apiURL, isLogin } from '../utils'; 
import SanitizedHTML from 'react-sanitized-html'
import Sign from '../login/Sign';  ;
 
const styles = theme => ({
bigIndicator: {
height: 0,
},
});
class DiscussionBoard extends Component { 
   //state = { value: 0 }
   _isMounted = false; 
   constructor(props){
      super(props);
      this.state = {
         value: 0, 
         arrForumData: [],
         page: 1,
         per:10,
         total_pages: null,
         loading: false, 
         pageUpdate: true, 
         getCategory: [],
         getTags:[],
         changeCategory: [],
         changeTag:[],
         latestLabel : '',
         mostLikedLabel: '',
         searchAllForum: '',
         noData: '',
         showComponent: false,
         id:'',
         title:""
      }
      this.getCategoryValue   = this.getCategoryValue.bind(this);
      this.getTagValue        = this.getTagValue.bind(this);
      this.forumListData      = this.forumListData.bind(this);
      this.onClickMostLiked   = this.onClickMostLiked.bind(this);
      this.onClickLatest      = this.onClickLatest.bind(this);
      
   }

   componentDidMount()
   {
      this._isMounted = true; 
      window.addEventListener('scroll', this.signNav)
      this._isMounted = true; 
      this.forumListData();
      this.scrollListener = window.addEventListener("scroll", e => {
         this.handleScroll(e);
      });
      this.getCategory();
      this.tagList();


      window.scrollTo(0, 0);
   }

 

   getCategory = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('category/get_all_category'), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({getCategory: resultData.result}); 
          }
          else{ 
            this.setState({getCategory: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }

    
   tagList = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('tags/get_tags'), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({getTags: resultData.result}); 
          }
          else{
            console.log('no data')
            this.setState({getTags: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }
 
   componentWillUnmount() {
      this._isMounted = false; 
   }

   forumListData = () => {
      this.setState({ loading: true });
      const { per, page, arrForumData } = this.state; 
      const headers = { 
          'Content-type': 'application/json'
      }
      const body = { 
         "category_id": this.state.changeCategory,
         "tag_id": this.state.changeTag,
         "user_status": 1,
         "search": this.state.searchAllForum,
         "latest": this.state.latestLabel,
         "most_liked" : this.state.mostLikedLabel
      }
      axios.post(apiURL('forum?per_page='+per+'&page='+page), body, {headers: headers})
      .then(response => {
          
      if(this._isMounted){
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          { 
              this.setState({  
                  arrForumData: (resultData.total < 10)?resultData.result:[...this.state.arrForumData, ...resultData.result],
                  scrolling: false,
                  total_pages: resultData.total_pages,
                  noData: ''
              });
              this.setState({ loading: false, scrolling: true, arrForumData:[...this.state.arrForumData] });
          }
          else{
              this.setState({ loading: false, scrolling: false, noData: "Coming Soon", arrForumData:[]}); 
              //this.forumListData();
          }
      }
      })
      .catch(error => {
          console.log(error)
      })
  }

  
  loadMore = () => { 
      if(this._isMounted){
         this.setState(
         prevState => ({
            page: prevState.page + 1,
            scrolling: true
         }),
         this.articleListData
         ); 
      }
   };

   handleScroll = () => {   
      if(this._isMounted){ 
         var lastLi = document.querySelector("Table"); 
         if(lastLi !== null){
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset) {
               if(this.state.scrolling){
                  this.loadMore();
               }
            } 
         }
      }
   };


   handleChange = (event, value) => {
      this.setState({ value }); 
   };

   getCategoryValue = (e, values) => {  
      this.setState({
         changeCategory: values,
         mostLikedLabel: '',
         latestLabel: '',
         changeTag: [],
         page: 1
       }, () => {
         this.forumListData();
       });
   }

   getTagValue = (e, values) => { 
      this.setState({
         changeTag: values,
         mostLikedLabel: '',
         latestLabel: '',
         changeCategory: [],
         page: 1
       }, () => {
         this.forumListData();
       });
   }

   onClickLatest = (e, label) => {
        this.setState({
         latestLabel: label,
         mostLikedLabel: '',
         changeTag: [],
         changeCategory: [],
         page: 1,
       }, () => {
         this.forumListData(); 
       });
   }
   onClickMostLiked = (e, label) => {
        this.setState({
         mostLikedLabel: label,
         latestLabel: '',
         changeTag: [],
         changeCategory: [],
         page: 1
       }, () => {
         this.forumListData(); 
       });
   }

   searchAllForum = (e) => {
      this.setState({
         mostLikedLabel: '',
         latestLabel: '',
         changeTag: [],
         changeCategory: [],
         page: 1,
         searchAllForum : e.target.value
       }, () => { 
         this.forumListData(); 
        //this.componentDidMount();
       }); 
   }

   
   checkJoinNow = () => { 
      this.setState({ showComponent: false })
      this.props.history.push('thread-answer/'+this.state.id+'/'+this.state.title.replace(/ /g, '-').toLowerCase());
   }

   redirectDiscussion = (e, id, title) => { 
     if(!isLogin())
     {
        this.setState({ showComponent: true })
     }
     else{
        this.props.history.push('thread-answer/'+id+'/'+title.replace(/ /g, '-').toLowerCase());
     }
     this.setState({ id: id, title:title })
   }

   fiterTags = (e, id) => {
      this.setState({
         changeTag: {"id":id}, 
         changeCategory: {"fldi_id":""},
         page: 1,
        }, () => { 
         this.forumListData();  
       })
   }

   fiterCategory = (e, id) => {
      this.setState({
         changeCategory: {"fldi_id":id},
         changeTag: {"id":""}, 
         page: 1,
        }, () => { 
         this.forumListData();  
       })
   }

   render() {
      const { classes } = this.props;
      const {value} = this.state; 
      return (
            <React.Fragment>
            {this.state.showComponent?<Sign ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)} />:
             <div>
               <Appbar />
               {/* <div className="NavBar  pb-0 sticky-top ">
                  <Navmenu />
               </div> */}
               <div className="sticky-top sign-light">
                <Navmenu />    
               </div>  
               <section className="BannerBox discussionBanner">
                  <div className="sixteen-nine">
                     <div className="content text-center text-white invisible">
                        <h2>Discussion Board</h2>
                        <p>Lorem ipsume</p>
                     </div>
                  </div>
               </section>
               <section>
                  <Container>
                     <Row>
                        <Col sm="12" className="discussionMain mt-5" >
                        <Row>
                           <Col sm="12"> 
                           <div position="static" key="appbar" className="appBox d-flex justify-content-between flex-md-nowrap flex-wrap" >
                              <form className="d-flex flex-md-nowrap flex-wrap">
                                 <Autocomplete
                                    id="category-box"
                                    className="customField mr-2" 
                                    options={this.state.getCategory} 
                                    getOptionLabel={(option) =>  option.fldv_category}
                                    onChange={this.getCategoryValue} 
                                 renderInput={(params) => 
                                 <TextField {...params} label="All Categories" variant="filled" />
                                 }
                                 />
                                 <Autocomplete
                                    id="tag-box"
                                    className="customField" 
                                    options={this.state.getTags} 
                                    getOptionLabel={(option) => option.title}
                                    onChange={this.getTagValue} 
                                 renderInput={(params) => 
                                 <TextField {...params} label="Tags" variant="filled" />
                                 }
                                 />
                              </form>
                              <Tabs classes={{ indicator: classes.bigIndicator }} value={this.state.value} onChange={this.handleChange} >
                                 <Tab  className="text-capitalize" onClick={(e) => this.onClickLatest(e, 'latest')} label="Latest" />
                                 {/*<Tab className="text-capitalize " label="Unread" />
                                 <Tab className="text-capitalize " label="Rising" />*/}
                                 <Tab className="text-capitalize" onClick={(e) => this.onClickMostLiked(e, 'most_liked')} label="Most Liked" />
                                 {/*<Tab className="text-capitalize " label="Follow Feed" />*/}
                              </Tabs>
                              <form>
                                 <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                    <SearchIcon />
                                 </IconButton>
                                 <InputBase
                                 className={classes.input}
                                 placeholder="Search all forums"
                                 inputProps={{ 'aria-label': 'search google maps' }}
                                 onChange={(e) => this.searchAllForum(e)}
                                 />
                              </form>
                              <figure className="d-sm-block d-none">
                                 <img src="images/discussion-icon.jpg" className="img-fluid mt-1" alt="" />
                              </figure>
                           </div>
                           </Col>
                           <Col sm="12" key="tab-content" className=""> 
                           <Row>
                              <Col sm="12" >
                              <div className="table-responsive">
                                 <TableContainer>
                                    <Table className="simpleTable">
                                       <TableHead>
                                          <TableRow>
                                             <TableCell  className="w-50">Topic</TableCell>
                                             <TableCell> Category</TableCell>
                                             <TableCell> Users</TableCell>
                                             <TableCell> Replies</TableCell>
                                             {/*<TableCell> Views</TableCell>
                                             <TableCell> Activity</TableCell>*/}
                                          </TableRow>
                                       </TableHead>
                                       <TableBody>
                                       {this.state.arrForumData.map((arrForums, index) => {    
                                          return(
                                          <TableRow key={index}>
                                             <TableCell>
                                                {/*<Link to={'/thread-answer/'+arrForums.fldi_id+'/'+arrForums.fldv_title.replace(/ /g, '-')}>*/}
                                                   <h5  onClick={(e) => this.redirectDiscussion(e, arrForums.fldi_id, arrForums.fldv_title)} >{arrForums.fldv_title}</h5>
                                                {/*</Link> */}
                                                <SanitizedHTML  onClick={(e) => this.redirectDiscussion(e, arrForums.fldi_id, arrForums.fldv_title)}  html={arrForums.fldv_content.replace( /(<([^>]+)>)/ig, '').substring(0, 150)+'.....'} />
                                                {arrForums.tags.map((arrTags, index) => {
                                                   return( 
                                                  <span className="badge" key={arrTags.fldi_id}><Link  style={{color:"#fff"}}  to={{pathname:'/discussion-board-tagged/'+arrTags.fldi_id}}>{arrTags.fldv_title}</Link></span>
                                                      
                                                   /*<span className="badge" key={arrTags.fldi_id} onClick={(e) => this.fiterTags(e, arrTags.fldi_id)}>{arrTags.fldv_title}</span>*/
                                                   ) 
                                                })}
                                             </TableCell>
                                             <TableCell>
                                                {/*<Link to={'discussion-board/'+arrForums.fldv_category_id+'_category'}>{arrForums.fldv_category}</Link>*/}
                                                <Link to={{pathname:'/discussion-board-category/'+arrForums.fldv_category_id}}>{arrForums.fldv_category}</Link>
                                                {/*<span onClick={(e) => this.fiterCategory(e, arrForums.fldv_category_id)}>{arrForums.fldv_category}</span>*/}
                                                </TableCell>
                                             <TableCell>
                                                <List inline className="mb-0">
                                                   <List.Item inline>{(arrForums.author_name)?arrForums.author_name.charAt(0):arrForums.author_name}</List.Item>
                                                   {/*<List.Item inline>b</List.Item>
                                                   <List.Item inline>c</List.Item>*/}
                                                </List>
                                             </TableCell>
                                                <TableCell> {arrForums.fldi_total_answers} </TableCell>
                                             {/*<TableCell> 15.1k</TableCell>
                                             <TableCell> 11d</TableCell>*/}
                                          </TableRow> 
                                          );
                                       })} 
                                       </TableBody>
                                    </Table>
                                 </TableContainer>
                                    {/* <span>{this.state.noData}</span> */}
                                    <h3 className="text-center d-block w-100 mt-4">{this.state.noData}</h3>
                              </div>
                              </Col>
                           </Row> 
                           </Col>
                        </Row>
                        </Col>
                     </Row>
                  </Container>
               </section>
               <Footer />
               </div>
               }
            </React.Fragment>
         );
      }
   }
export default withStyles(styles)(DiscussionBoard);
