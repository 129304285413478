import React, { Component } from 'react';  
import {  Row, Col, Container } from 'bootstrap-4-react';
import aboutTeacher from '../images/about.jpg';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { withStyles } from "@material-ui/core/styles"; 
// import Image from 'react-image-webp';
// import {isWebpSupported} from 'react-image-webp/dist/utils';






 const styles = theme => ({
   demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 0),
      fontSize:'1.75rem',
      [theme.breakpoints.down('sm')]: {
       fontSize:'1.5rem',  
      },
    },
 });


 
 
class AboutTeacher extends Component {

render() {
   const { classes } = this.props; 

  
return (
<React.Fragment>
   <section className="AboutBox  ">
      <Container>
         <Row>
            <Col col="lg-8 md-12 sm-12" className="order-2">
             <p>Greetings!</p>
             <p>Welcome to Navneet’s ‘NAVदिशा ’ portal. Wishing you all a Happy Teachers’ Day! On this special occasion, we are delighted to interact with you through our new portal. </p>
             <p>We celebrate Teachers’ Day on 5th September, the birthday of the former President of India, Bharat Ratna Dr. Sarvepalli Radhakrishnan, an eminent philosopher, an idealist, and a great teacher. Even prior to becoming the President, he had made significant contributions in the field of education as Professor, Principal and Vice-Chancellor. He lived by the adage “Once a teacher, always a teacher.” On his 132nd birth anniversary, we dedicate ‘NAVदिशा ’ portal to all teachers! </p>
            
            <p >We are launching this portal with the objective of updating teachers on the important developments and recent changes in the education system and interacting with teachers to help their professional development.</p>
        
          </Col>
            <Col  col="lg-4 md-12 sm-12" className="order-lg-2 order-1">
             <img src={aboutTeacher} className="img-fluid w-100 rounded"/>  
           
            </Col>
          
             <Col col="sm-12" className="order-3 mt-1">      
         <p>Navneet is a leading institution developing educational material and publications for over 63 years. In the spirit of corporate social responsibility, it established Navneet Foundation which has made noteworthy contributions in the fields of education, health, disaster management, etc.</p>
         <p>Since 2016, the Foundation has conducted over 322 hands-on training workshops for teachers at various places across Maharashtra. More than 15,000 teachers benefited from this initiative. Due to the unprecedented situation caused by Covid-19, we were unable to conduct physical workshops this year and hence started interacting with the teachers through webinars, wherein thousands of teachers participated actively. Expert dignitaries guided the teachers through webinars in both Marathi and English on various topics such as education during school-closures, Corona & students’ mental and emotional wellbeing, online teaching techniques, etc.</p>
         <p>We are making the past webinars available on the portal for the benefit of teachers who were unable to participate in these events. Additionally, the Navneet Portal would bring to you a variety of thematic articles, interviews, innovative and unique activities contributed by  our senior and expert teachers, question-answers and problem-solving for students by teachers, circulars and resolutions (GRs) issued by the Government. The portal would be periodically refreshed with new content. </p>
         <p>We aim to make this portal as teacher friendly as possible. Your suggestions are welcome to make it comprehensive and effective. Please feel free to also leave your valuable feedback.</p>
         <p>Thank you.</p>
         <h3 class="font-weight-bold">Navneet Parivar</h3>
            </Col>
           
           
           
         </Row>
      </Container>
   </section>
</React.Fragment>
);
}
}


export default withStyles(styles) (AboutTeacher);