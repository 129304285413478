import React, { Component } from 'react';  
import ReactDOM from 'react-dom';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';   
import {  Container,  Row, Col, List  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
// import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';

import "../style/Terms.scss";   
const styles = theme => ({
root: {
maxWidth: "100%",  
},    
});
class PrivacyPolicy extends Component {   

    componentDidMount() {
        window.scrollTo(0, 0);
        }
        
render() {  
return (
<React.Fragment>
   <Appbar />


   <section className="BannerBox privacyBanner">
      <div className="sixteen-nine">
         <div className="content text-center text-white d-none">
            <h2>Pricacy Policy </h2>
            <p>Update</p>
         </div>
      </div>
   </section>



   {/* <div className="NavBar   pb-0 sticky-top ">
      <Navmenu />
   </div> */}
   <div className="sticky-top sign-light">
     <Navmenu />    
    </div>  
   <section className="BannerBox TrainingBox termBoxBanner  d-none">
      <div className="sixteen-nine">
         <div className="content text-center text-white d-none">
            <h2>Terms and Conditions</h2>
            <p>Lorem Ipsum</p>
         </div>
      </div>
   </section>
   <section className="termBox">
      <Container>
          <Row>
              <Col sm="12">
                  <h2>Privacy Statement</h2>  
                  <p className="mt-3 mb-0">This Privacy Statement applies to the NavDisha.co.in Web Site, Content, Products, and Services and governs data collection and usage. By using NavDisha.co.in Web Site, Content, Products, and/or Services, you consent to the data practices described in this Privacy Statement.</p>
              </Col>
              <Col>
              <List unstyled className="ml-0">
        <List.Item>
              <h2>Privacy Policy Overview</h2>
             <p>WE DO NOT SEND SPAM OR UNSOLICITED EMAILS. WE CONTACT OUR CUSTOMERS ONLY IN RELATION TO THE SERVICES THEY HAVE REQUESTED. WE DO NOT SELL CONTACT INFORMATION OF OUR USERS</p>
             <p>WE PLACE THIRD PARTY ADVERTISEMENT BANNERS ON SOME OF OUR PAGES. THESE ADS ARE SERVED BY SUCH PROVIDERS AS GOOGLE ADSENSE. PLACING THESE ADS HELPS US KEEP PROVIDING CERTAIN CONTENT AND SERVICES FREE OF CHARGE. IF YOU CLICK ON THESE ADS, YOU MAY BE DIRECTED TO A THIRD PARTY WEB SITE, AND YOU MAY BE PROMPTED BY A THIRD PARTY TO ENTER PERSONAL OR OTHER INFORMATION. NAVदिशा  HAS NO CONTROL OVER VISITOR’S INTERACTION WITH THIRD PARTY ADS AND/OR WEB SITES, AND WE HAVE NO INFORMATION ABOUT THEIR INFORMATION POLICIES. FOR ANY THIRD PARTY SERVICES LINKED TO OR ADVERTISED ON OUR WEB SITE, YOU MUST REFER TO THEIR OWN POLICIES AND TERMS OF USE. WE HAVE NO RESPONSIBILITY WHATSOEVER FOR ANY THIRD PARTY SERVICES THAT MAY BE LINKED TO, OR ADVERTISED ON OUR WEB SITE, AND THEIR INFORMATION PRACTICES. GOOGLE’S INFORMATION POLICY IS AVAILABLE HERE:<a  href="http://www.google.com/policies/privacy/partners/" target="_blank">http://www.google.com/policies/privacy/partners/</a> </p>
        </List.Item>

        <List.Item>
              <h2>Collection of Information</h2>
              <p>When you use services from NavDisha.co.in, or contact NavDisha.co.in, Navneet Foundation. obtains personally identifiable information such as your e-mail address, name, contact address and/or telephone number. Your name and email address may also have to be obtained in order to email your test or assessment results to you. (collectively the “Personal Information”)</p>
             <p>NAVदिशा  does not collect your credit card or your other method of payment information. Your payments to NAVदिशा  are processed by a third party payment service provider. Third party paymenet gateway terms of use and information practices are not covered by our policies.</p>
              <p>Anonymous information about your network, hardware, software and Web Site usage activity that is automatically collected by NAVदिशा  can include, without limitation, your IP address, your device type, your browser information, domain names, access times, referring web sites addresses, Web Site pages you visit. Your responses to  Navneet Foundation. Questionnaires, surveys, and your Navatar assessment scores may be anonymously (i.e. without any personally identifying information attached to questionnaires and scores) collected by Navneet Foundation. NAVदिशा  may also collect anonymous demographic information, which is not unique to you, such as your country, city, zip code, age, gender, occupation, and preferences. (collectively the “Anonymous Information”)</p>
              <p>Your name, email address, responses to Navneet Foundation. questionnaires, surveys and resulting scores may be saved in the NAVदिशा ’ back end system, in order to generate result reports that you purchase or obtain from NAVदिशा.</p>
              <p>Navneet Foundation. may refer, advertise, and/or offer for sale, via its Web Site, Content or Services, certain Third Party Services, including, without limitation, Linked Sites, and Ads presented by third parties. An example of Ads, without limitation, is an advertisement banner of an educational institution, or Ads served by Google AdSense. If you click Ads, you may be directed to a Third Party web site, and prompted by a Third Party to enter personal and other information. NAVदिशा  has no control over the Third Party Services and over visitor’s interaction with them. Terms of use, privacy and information policies of the Third Party Services are not covered by our policies.</p>
       </List.Item>
       <List.Item>
              <h2>Use of Personal Information</h2>
              <p>Navneet Foundation. does not sell, rent or lease its customers’ Personal Information.</p>
              <p>The Personal Information is collected by Navneet Foundation. to deliver services that you have purchased and/or requested from NavDisha.co.in , provide test results to you, and provide customer support to you. Navneet Foundation. may use your contact information to inform you of other products or services available from NAVदिशा  and its affiliates. Navneet Foundation. may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.</p>
              <p>NAVदिशा may share your contact information with trusted partners to send you email regarding the service you have requested, or provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Navneet Foundation. and its clients.</p>
              <p>Navneet Foundation. will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Navneet Foundation. or the Web Site; (b) protect and defend the rights or property of Navneet Foundation; and, (c) act under exigent circumstances to protect the personal safety of users of NAVदिशा.</p>
        </List.Item>
        <List.Item>
              <h2>Use of Anonymous Information</h2>
              <p>The Anonymous Information is used by NAVदिशा for the operation, maintaining the quality, enhancements and analysis regarding use, of the NavDisha.co.in Web Site, Content, Products, and Services, and for research. The Anonymous Information may be collected by NAVदिशा or its service providers (e.g., Google Analytics or Google AdSense). The content and Ads that appear on the Web Site’s pages may be customized based on the information about the pages you visit, your geographic location based on your IP address, and/or results of your personality assessment. You grant NAVदिशा an irrevocable, perpetual, royalty-free right and license to use the Anonymous Information for, without limitation, quality assurance, personalizing content, research, marketing, new content, products and services.</p>
        </List.Item>
        <List.Item>
              <h2>Use of Cookies</h2>
              <p>When you visit the Web Site, we or one of our third party service providers (e.g., Google Analytics) place a text file called a “cookie” in the browser directory of your computer’s hard drive. The Web Site uses cookies to help you personalize your online experience and provide certain features. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to your browser, and can only be read by a web server in the domain that issued the cookie. One of the primary purposes of cookies is to provide a convenience feature. The purpose of a cookie is to tell the web server that you have returned to a specific page, or have been authenticated, or to personalize content based on result of a personality test you take on the Web Site, or recall your specific information on subsequent visits. You have the ability to accept or decline cookies via your browser. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer, or delete cookies after using the Web Site. Some browsers may provide “privacy” or “incognito” mode in which cookies are automatically deleted after closing the browser. If you choose to decline cookies, you will not be able to use certain features and fully experience the interactive features of the Web Site.</p>
        </List.Item>
        <List.Item>
              <h2>Security of Personal Information</h2>
              <p>NavDisha.co.in takes steps to secure personal information from unauthorized access, use or disclosure. NavDisha.co.in keeps the personally identifiable information you provide on computers in a controlled environment, protected from unauthorized access, use or disclosure. Online payments are accepted via third party vendors and protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
        </List.Item>
        <List.Item>
              <h2>Changes to this Privacy Statement</h2>
              <p>Navneet Foundation. will occasionally update this Privacy Statement. NAVदिशा encourages you to periodically review this Privacy Statement.</p>
        </List.Item>


       </List>

              </Col>
          </Row>
      </Container>
   </section>
  
  
   <div className="customFooter">
      <Footer />
   </div>
</React.Fragment>
);
}
}
export default withStyles(styles) (PrivacyPolicy);  