import React, { Component } from 'react'; 
import "../style/pastraining.scss";
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';  
import { apiURL } from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';   
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
   dialogPaper: {
   // minHeight: '90vh',
   // maxHeight: '90vh',
   borderRadius:'25px',
   maxWidth:'48.75rem',
   },
   });


class RecentTopics extends Component {
   _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        pastEventsList: [],
        noData: '',
    }
    this.getPastEvents = this.getPastEvents.bind(this); 
  }


  componentDidMount()
  {
    this._isMounted = true; 
    this.getPastEvents(); 
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
 getPastEvents = () =>{
   const headers = {
     'content-type': 'multipart/form-data',
     'Accept': 'application/json'
     }
     const body = { 
         "status": 1,
         "event_type": "PE",
         "user_id": ''
     }
     axios.post(apiURL('webinar/events'), body, {headers: headers})
     .then(response => {
         const resultData = response.data; 
         //alert(resultData.message);
         if(resultData.status === true || resultData.status === 1)
         { 
             this.setState({ pastEventsList: resultData.result});
         }
         else{
             this.setState({noData: "Comeing Soon"});
         }
         
       
     })
     .catch(error => {
         console.log(error)
     })  
  }
 
   

render() {
   const { classes } = this.props;
return (
<React.Fragment>
   <section class="careerMain eventMain">
      <Container>
         <Row className="Blogbox careerBox EventBox PastTraing position-relative homeTopic">
            <h1 className="text-center d-block w-100 position-absolute">Recent Webinars</h1>
            {
            this.state.pastEventsList.slice(0, 3).map((arrList, index) => {
            return  <Col key={index} col="sm-10 md-8 lg-4" className="mx-sm-auto">  
            <Link to={"/past-webinar-schedule/"+arrList.fldi_id+"/"+arrList.fldv_title.replace(/ /g, '-').toLowerCase()}>
            <Card>
               <div className="position-relative clearfix">
                  <figure>
                     <figcaption>
                        <div className="dateBox text-center " >
                           <h3>{Moment(arrList.fldv_select_date).format('D')}</h3> 
                           <h5>{Moment(arrList.fldv_select_date).format('MMMM')}</h5> 
                           <h6>{Moment(arrList.fldv_select_date).format('YYYY')}</h6>
                        </div>
                     </figcaption>
                     <div className="imgBoxtraining">
                     <img style={{width:"100%"}} src={arrList.fldv_image_url} className="img-fluid" alt={arrList.speaker_name} />
                     </div>
                     <figcaption>
                        <div className="SpeakerBox text-center">
                           <div className="Speaker">
                              <div className="d-block imgBox">
                                 <img src={arrList.speaker_profile_image} className="img-fluid rounded-circle" alt={arrList.speaker_name} />
                              </div>
                              <h4>{arrList.speaker_name}</h4>
                           </div>
                        </div>
                     </figcaption>
                  </figure>
               </div>
               <Card.Body className="h-100">  
                  <Card.Text>
                     <div className="headBox text-center">
                        <h4>Topic: {arrList.fldv_title}</h4>
                        {/* <h5>
                           <AccessTimeIcon />
                           {Moment(arrList.fldv_select_date).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}
                        </h5> */}
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer>
               <div className="headBox text-center">
                       <h5>
                           <AccessTimeIcon />
                           {Moment(arrList.fldv_select_date).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}
                        </h5>
               </div>  
               </Card.Footer>
            </Card>
            </Link>
            </Col>   
              })
            }   
            <div className="clearfix"></div>
            <Col col="sm-12" className="text-center noFound mt-md-5 mt-4"> <h3>{this.state.noData}</h3></Col>
         </Row>
      </Container>
   </section>
</React.Fragment>
);
}
}

export default withStyles(styles) (RecentTopics);