import React,  {Component} from 'react'; 
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';  
import Footer from '../components/Footer';
import {  Container,  Row, Col } from 'bootstrap-4-react';    
import { withStyles } from "@material-ui/core/styles"; 
import "../style/Discussion.scss";     
import { Media, BH5, BH6 } from 'bootstrap-4-react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Divider from '@material-ui/core/Divider';
import { List } from 'bootstrap-4-react'; 
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined'; 
import ReplyIcon from '@material-ui/icons/Reply'; 

//import { Link, Redirect, useHistory, withRouter  } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { apiURL } from '../utils'; 
import axios from 'axios';   
import Moment from 'moment'; 
import SanitizedHTML from 'react-sanitized-html';
import Replydialog from './Replydialog';
import SuggestedTopics from './SuggestedTopics';


const styles = theme => ({
   bigIndicator: {
   height: 0,
   },
});

class ReadThread extends Component {  
   _isMounted = false; 
   constructor(props){
      super(props);
      this.state = {
         value: 0, 
         forumValues: [],
         forumTotalCount:0,
         forumTitle:'',
         forumContent:'',
         forumCreateDate:'',
         forumCategory:'',
         tags:[],
         editorValue:'',
         answers: [],
         bgcolor: '', 
         showDialog: false, 
         suggestesTopicRefresh: false,
      }
      this.discussionReplyDialogOpen   = this.discussionReplyDialogOpen.bind(this);
      this.discussionReplyDialogClose  = this.discussionReplyDialogClose.bind(this);
      this.getForumByID                = this.getForumByID.bind(this);
      this.getForumAnswers             = this.getForumAnswers.bind(this);
   }

   componentDidMount()
   {
      this._isMounted = true; 
      window.addEventListener('scroll', this.signNav);
      this.getForumByID();
      this.getForumAnswers();

      window.scrollTo(0, 0);
   }
 
    
   componentWillUnmount() {
      this._isMounted = false; 
   }

   getForumByID = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('forum/get_forum_by_id/'+this.props.match.params.threadId), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({
                  forumValues: resultData.result, 
                  tags: resultData.result.tags,  
              }); 
          }
          else{ 
            this.setState({forumValues: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }

    getForumAnswers = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
	  const body = {'thread_id': this.props.match.params.threadId}
	  
      axios.get(apiURL('forum/answer/'+this.props.match.params.threadId), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({
                answers: resultData.result.arrChilds, 
              }); 
          }
          else{
            this.setState({answers: []});
          }
		  console.log('answers '+JSON.stringify(resultData));
      })
      .catch(error => {
          console.log(error)
      })
    }

   handleChange = (event, value) => {
      this.setState({ value }); 
   };

   discussionReplyDialogOpen = (e, parentID) => { 
      this.setState({ replyEditor: true, parent_id: parentID  })
   }

   discussionReplyDialogOpen = (e) => {    
      e.preventDefault();
      e.stopPropagation(); 
      this.setState({ showDialog: true })
   }

   discussionReplyDialogClose = () => {
      this.componentDidMount();
      this.setState({ showDialog: false }) 
    }

    parentRefresh = () => {
      this.componentDidMount(); 
    }

    componentDidUpdate(prevProps) {
      const { match: { params: { threadId } } } = this.props
      if (prevProps.match.params.threadId !== threadId){  
        this.componentDidMount();  
      }
    }

   render() {
   const { classes } = this.props;
   //const {value} = this.state
   return (
      <React.Fragment>
         <Appbar />
         {/* <div className="NavBar   pb-0 sticky-top ">
            <Navmenu />
         </div> */}
         <div className="sticky-top sign-light">
            <Navmenu />    
         </div>  
         <section className="BannerBox discussionBanner">
            <div className="sixteen-nine">
               <div className="content text-center text-white">
                  <h2>Discussion Board</h2>
                  <p>Lorem ipsume</p>
               </div>
            </div>
         </section>
         <section className="">
            <Container>
               <Row>
                  <Col sm="12" className="discussionMain mt-5" >
                  <Row>

                     {/*<Col sm="12">
                     <div position="static" key="appbar" className="appBox d-flex justify-content-between flex-md-nowrap flex-wrap" >
                        <form className="d-flex flex-md-nowrap flex-wrap">
                           <Autocomplete id="combo-box-demo" className="customField mr-2" options={top100Films}
                              getOptionLabel={
                                 (option) => option.title
                              }
                              renderInput={(params) => <TextField {...params} label="All Categories" variant="filled" /> }
                           />

                           <Autocomplete  id="combo-box-demo2" className="customField" options={top100Films} 
                           getOptionLabel={
                              (option) => option.title
                           } 
                           renderInput={(params) =>  <TextField {...params} label="Tags" variant="filled" /> }
                           />
                        </form>
                        <Tabs classes={{ indicator: classes.bigIndicator }} value={this.state.value} onChange={this.handleChange} >
                           <Tab  className="text-capitalize " label="Latest" />
                           <Tab className="text-capitalize " label="Unread" />
                           <Tab className="text-capitalize " label="Rising" />
                           <Tab className="text-capitalize " label="Most Liked" />
                           <Tab className="text-capitalize " label="Follow Feed" />
                        </Tabs>
                        <form>
                           <IconButton type="submit" className={classes.iconButton} aria-label="search">
                              <SearchIcon />
                           </IconButton>
                           <InputBase
                           className={classes.input}
                           placeholder="Search all forums"
                           inputProps={{ 'aria-label': 'search google maps' }}
                           />
                        </form>
                        <figure className="d-sm-block d-none">
                           <img src="images/discussion-icon.jpg" className="img-fluid mt-1" alt="" />
                        </figure>
                     </div>
                     </Col>*/}


                     <Col sm="12" key="tab-content" className=""> 
                     <Row className="threadBox mt-4" >
                        <Col sm="12" >
                        <h3>{this.state.forumValues.fldv_title}</h3>
                        <SanitizedHTML html={this.state.forumValues.fldv_content} />
                        <span className="category"> {this.state.forumValues.fldv_category} </span> 
                        {this.state.tags.map((arrTags, index) => {
                           return(
                              <span className="badge" key={arrTags.fldv_title}>{arrTags.fldv_title}</span>
                           )
                        })} 
                        </Col>

                        <ReplyAnswer threadTitle={this.state.forumValues.fldv_title} answer={this.state.answers} threadId={this.props.match.params.threadId} pageRender={(e) => this.parentRefresh(e) }  />
                        
                        <Col sm="12">
                           <Button variant="contained" size="large" className="shareBox"  startIcon={ <InsertLinkOutlinedIcon  className="replyIcon"   /> } > Share </Button>

                           <Button onClick={(e) => this.discussionReplyDialogOpen(e) } variant="contained" size="large" color="primary" className="shareBox" startIcon={ <ReplyIcon /> } > Reply </Button>

                           <Replydialog threadTitle={this.state.forumValues.fldv_title} threadId={this.props.match.params.threadId} visible={this.state.showDialog} onDialogClose={(e) => this.discussionReplyDialogClose(e) }  parentId={null}/>
                        </Col>

                        {/* suggested Topics start from here */}
                        <Col sm="12">
                        <Divider className="mt-sm-5 mt-4 mb-3"  />
                        </Col>

                        <Col sm="12"> 
                           <SuggestedTopics forumId={this.state.forumValues.fldi_id} categoryID={this.state.forumValues.fldv_category_id} propsData={this.props} />
                        </Col>
                        {/* suggessted topics ends here*/}

                     </Row> 
                     
                     </Col>
                  </Row>
                  </Col>
               </Row>
            </Container>
         </section>
         <Footer />

      </React.Fragment>
      );
   }
}

function ReplyAnswer(props)
{
    const [open, setOpen] = React.useState(false); 
    const [parentId, setParentId] = React.useState(null); 

    const handleClickOpen = (e, parent_id) => {
      e.preventDefault();
      e.stopPropagation(); 
      setOpen(true);
      setParentId(parent_id) 
    };  

    const discussionReplyDialogClose = (e) => { 
      setOpen(false);  
      props.pageRender(); 
    }
    return (
        <React.Fragment>
            {props.answer.map((arrAnswers, index) => { 
              return <Col sm="12" key={index}>
              <Media border  className="flex-md-nowrap flex-wrap mt-sm-5 mt-4" >
                 <span className="usersBox green">{arrAnswers.fldv_name.charAt(0)}</span>
                 <Media.Body>
                    <div className="d-flex justify-content-between headBox flex-md-nowrap flex-wrap">
                       <BH5 className="font-weight-bold" >{/*Media heading*/}{arrAnswers.fldv_name}</BH5>
                       <div className="d-flex  flex-md-nowrap flex-wrap">
                          <BH6 className="iconBox ml-sm-4" >
                             <small>
                                <AccessTimeIcon className="iconBox" />
                             </small> 
                             {Moment(arrAnswers.flddt_created_date).format('D MMMM, YYYY')}
                          </BH6>
                       </div>
                    </div>
                    <div>
                       <p> 
                          <SanitizedHTML html={arrAnswers.fldt_content} />
                       </p>
                    </div>
                    <Divider   className="mt-sm-5 mt-4" />
                    <Row className="mt-4">
                       <Col col="6">
                       {/*<List inline>
                          <List.Item inline className="likeIcon mr-3">
                             <ForwardOutlinedIcon/>
                             201
                          </List.Item>
                          <List.Item inline className="likeIcon">
                             <ForwardOutlinedIcon/>
                             201
                          </List.Item>
                       </List>*/}
                       </Col>
                       <Col col="6">
                       <List inline className="float-right rightBox">
                         {/*<List.Item inline className="likeIcon mr-3">
                             <InsertLinkOutlinedIcon/>
                          </List.Item>
                          <List.Item inline className="likeIcon">
                             <BookmarkBorderIcon/>
                          </List.Item>*/}
                          <List.Item inline style={{cursor:"pointer"}} className="likeIcon" onClick={(e) => handleClickOpen(e, arrAnswers.fldi_id)} >
                             <ReplyIcon/> 
                          </List.Item>
                       </List>
                       </Col>
                       
                    </Row>
                       <ReplyAnswer threadTitle={props.threadTitle} answer={arrAnswers.arrChilds} threadId={props.threadId} parentId={arrAnswers.fldi_id}  pageRender={(e) => discussionReplyDialogClose(e)} />
                 </Media.Body>
              </Media>
              </Col>
            })} 

            <Replydialog visible={open} threadTitle={props.threadTitle} threadId={props.threadId} parentId={parentId} onDialogClose={(e) => discussionReplyDialogClose(e) } />
        </React.Fragment>
    )
}

export default withStyles(styles)(ReadThread);    
