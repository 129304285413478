import React, { Component } from 'react';  
import ReactDom from 'react-dom';
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import futureStudents from '../images/future-students.png';
import ourCommunity from '../images/our-community.png';
import bestCurriculum from '../images/best-curriculum.png';
import flexibleTiming from '../images/flexible-timing.png';
import govtcirculars from '../images/govt-circulars.png';
import articles from '../images/articlesi.png';
    



import { Link } from 'react-router-dom';

class Student extends Component {
    render() {
      return (
        <React.Fragment>
         
         <Row>
              <Col col="sm-6 md-6 lg-3" className="text-center">
              <Link to="/overview" className="link">
        <img src={futureStudents} className="img-fluid" alt="true"/>
        <Card.Body>
          <Card.Title>Teacher Trainings</Card.Title>   
          {/* <Card.Text>Navneet Foundation has setup a team and created a training workshop which aims to acquaint teachers with the significance of activity based teaching-learning</Card.Text>
        */}
        <Card.Text>
        Navneet Foundation initiated the Teachers' Training Programme to provide opportunities to the teachers for self-improvement, stay updated with new trends and enhance their skills.
        </Card.Text>
       
        </Card.Body>  
             </Link> 
              </Col>

              <Col col="sm-6 md-6 lg-3" className="text-center">
              <Link to="/past-webinars">
              <img src={ourCommunity} className="img-fluid" alt="true"/>
              <Card.Body>
                <Card.Title>Webinars</Card.Title>   
                <Card.Text>Webinars around various topics, which help teachers to add to their knowledge and expertise resulting in their overall development.</Card.Text>
              </Card.Body>   
                </Link>  
                    </Col>
                  
                    <Col col="sm-6 md-6 lg-3" className="text-center">
                    <Link to="/govt-circulars-news">   
              {/* <img src={flexibleTiming}  className="img-fluid" alt="true"/> */}
              <img src={govtcirculars}  className="img-fluid" alt="true"/>
              <Card.Body>
                <Card.Title>Govt. Circulars/GR</Card.Title>   
                <Card.Text>Get updated with all the new announcements/circulars from the Maharashtra State Board and all relevant news from the education industry</Card.Text>
              </Card.Body>  
                    </Link> 
                    </Col>   

                    <Col col="sm-6 md-6 lg-3" className="text-center">
                    <Link to="/articles">   
              {/* <img src={flexibleTiming}  className="img-fluid" alt="true"/> */}
              <img src={articles}  className="img-fluid" alt="true"/>
              <Card.Body>
                <Card.Title>Articles</Card.Title>   
                <Card.Text>
                This section brings all the relevant articles from various sources at one place. Read on subjects like motivational thoughts, book reviews, subject FAQs etc and update your knowledge using this  section.
                </Card.Text>
              </Card.Body>  
                    </Link> 
                    </Col>   

            </Row>
            
        </React.Fragment>
      );
    }
  }

export default Student;