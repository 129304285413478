import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/pastraining.scss";
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Link } from 'react-router-dom';  
import { apiURL, isLogin } from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';  
import { Typography } from '@material-ui/core';
import FeedBackForm from './FeedBackForm';
import Dialog from '@material-ui/core/Dialog'; 
import DialogContent from '@material-ui/core/DialogContent'; 
import Sign from "../login/Sign";
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import { withStyles } from "@material-ui/core/styles";
import Video_dialog from "../Video_dialog";
import ReactPlayer from 'react-player/lazy' ; 
import "../style/Glimpses.scss";   
import Meta from '../meta/Meta'; 

import aboutTeacher from '../images/about.jpg';

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Bootstrap, { Carousel} from 'bootstrap-4-react';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';  
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'; 


import groupwork from '../images/groupwork1.jpg';
import phadke from '../images/madhura-phadke.jpg';
// import group from '../images/satara-group.jpg';
import inauguaration from '../images/inauguaration.jpg';
import mumbai from '../images/mumbai-group.jpg';
import nashik from '../images/nashik-group.jpg';

const styles = theme => ({
   dialogPaper: {
      // minHeight: '90vh',
      // maxHeight: '90vh',
      // borderRadius:'25px',
      // maxWidth:'42.75rem',
      // width:'42.75rem',
      borderRadius:'25px',    
      maxWidth:'42.75rem',
   },
   dialogPaper1: {
      // minHeight: '90vh',
      // maxHeight: '90vh',
      // borderRadius:'25px',
      // maxWidth:'42.75rem',
      // width:'42.75rem',
      borderRadius:'0px',    
      maxWidth:'48.75rem',
      width:'60rem',
   },
   title: {
      margin: theme.spacing(4, 0, 0),
      fontSize:'1.75rem',
      [theme.breakpoints.down('sm')]: {
         fontSize:'1.5rem',  
        },
    },
    title1: {
      // margin: theme.spacing(0, 0, 0),
      fontSize:'1.25rem',
      fontFamily:'gotham rounded medium',
      width:'17rem'

    },
  
});


class PastTraining extends Component {
   _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        pastEventsList: [],
        videoUrl: '', 
        noData: '', 
        showComponent: false,
        showSnackbar: false,
        snackBarMessage: '',
        severityColor: '',
        snackBarTimer: '',
        feedBack: false,
        dialogOpen: false,
        videoUrl: '',
    }
    this.getPastEvents = this.getPastEvents.bind(this); 
  }

  componentDidMount()
  {
    this._isMounted = true; 
    this.getPastEvents(); 
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
 getPastEvents = () =>{
   const headers = {
     'content-type': 'multipart/form-data',
     'Accept': 'application/json'
     }
     const body = { 
         "status": 1,
         "event_type": "PE",
         "user_id": ""
     }
     axios.post(apiURL('training_schedule/events'), body, {headers: headers})
     .then(response => {
         const resultData = response.data; 
         //alert(resultData.message);
         if(resultData.status === true || resultData.status === 1)
         { 
             this.setState({ pastEventsList: resultData.result});
         }
         else{
             this.setState({noData: "Coming Soon"});
         }
         
       
     })
     .catch(error => {
         console.log(error)
     })  
  }

  handlevideoClickOpen = (e,videoUrl) => {
   this.setState({ open: true, videoUrl:videoUrl })
   }
   handlevideoClose = () => {
   this.setState({ open: false, videoUrl:'' })
   }

  handleClickOpen = (e, id, title) => {
      if(!isLogin())
      {
         this.setState({ showComponent: true })
      }
      else{
      this.setState({ feedBack: true, id: id, title: title })
      }
     
   }
 
   checkJoinNow = () => {
      this.getPastEvents();
      this.setState({ showComponent: false })
  }

   
   snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
        this.setState({ showSnackbar: false }); 
    }

    handleClose = (e) => {  
      if(e === true){
         this.setState({ 
            showSnackbar: true,
            snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
            severityColor: 'success',
            snackBarTimer: '3000',
            //open: false 
         }); 
      }
      this.setState({  feedBack: false   }); 
      
   }

  /* videoDialog = (e, videoUrl) =>{
     // alert(videoUrl);
      this.setState({
         dialogOpen: true,
         videoUrl: videoUrl
      })
   }*/

   videoDialog = (e, videoUrl) =>{
      // alert(videoUrl);
      if(!isLogin())
      {
         this.setState({ showComponent: true })
      }
      else{
       this.setState({
          dialogOpen: true,
          videoUrl: videoUrl
       })
      }
   }

   videoDialogClose = () => {
      //alert("close");
      this.setState({
         dialogOpen: false,
         videoUrl: ''
      })
   }

render() {
   const { classes } = this.props;
return (
<React.Fragment>
      <Meta tableName={"menu"} objectId={8} forPage={"main_page"} />
{this.state.showComponent? <Sign  ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)}/>:
<div>
   <Appbar /> 
   <div className="sticky-top sign-light">
       <Navmenu />    
    </div>  
   <section className="BannerBox TrainingBox">
      <div className="sixteen-nine">  
         <div className="content text-center text-white invisible" >
            <h2>Past Training Schedule</h2>
            <p>PAST  TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
         </div>
      </div>
   </section>

   <section className="AboutBox  ">
      <Container>
         <Row>
            <Col col="md-12 sm-12">
            <h2> 
               <span>About Teacher Empowerment Programme</span>
            </h2>
           
             <p><sup><FormatQuoteIcon style={{transform:" scaleX(-1)"}}/></sup>The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn and relearn.<sup><FormatQuoteIcon/></sup> - Alvin Toffler</p>
         
            </Col>
           
           <Col sm="12" className="mt-2">
              <p>To enable teachers to learn, unlearn and relearn training is a key factor in Educational Excellence. Keeping this in view, in order to attain quality education, it is essential to provide opportunities for self-improvement and to update teachers with new trends, sharpen and enhance their skills and build their  capacities. 
              </p>
              <p>
              Navneet Foundation, under its CSR programme, initiated the Teachers' Training Programme in June 2016. The programme continued in the four academic years that followed, up to 2019-20 in which, teachers from various parts of state participated. Thus, the government efforts were strengthened by such contribution from Navneet Foundation.
              </p>
              <p>
              The adage goes - “Tell me and I'll forget; show me and I may remember; involve me and I'll understand.”  This idea was infused to design the modules. Once the need assessment was conducted, based on content and pedagogy, the training modules were designed by expert teachers of the subjects concerned. The training methodology adopted included group discussion, group work, presentation and feedback. All the three modes of learning namely expert directed, peer directed and self directed learning, were incorporated in the training. This made it more participatory which in turn made active learning happen. This is especially so when the Activity Based Teaching-  Learning is emphasised. 
              </p>
              <p>
              The above teachers training was preceded by a series of Trainers’ Training Programme. In the last 4 years 322 workshops were conducted and 15,798 teachers across the state benefited. 

              </p>
        
            </Col> 

            <Col col="lg-12 md-10" className="mt-4 tableAbout">
              <h3 className="font-weight-bold mb-4">Year wise workshops and Beneficiary Teachers 2016-2020</h3>
            <TableContainer component={Paper}>
      <Table  size="small" >
        <TableHead className="font-weight-bold">
          <TableRow>
            <TableCell className="font-weight-bold">Division</TableCell>
            <TableCell className="font-weight-bold" align="right">2016-17</TableCell>
            <TableCell className="font-weight-bold" align="right">2017-18</TableCell>
            <TableCell className="font-weight-bold" align="right">2018-19</TableCell>
            <TableCell  className="font-weight-bold" align="right">2019-2020</TableCell>
            <TableCell  className="font-weight-bold" align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow >
              <TableCell component="th" scope="row">
                Mumbai
              </TableCell>
              <TableCell align="right">20</TableCell>
              <TableCell align="right">4</TableCell>
              <TableCell align="right">16</TableCell>
              <TableCell align="right">12</TableCell>
              <TableCell align="right">52</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Nashik
              </TableCell>
              <TableCell align="right">35</TableCell>
              <TableCell align="right">12</TableCell>
              <TableCell align="right">24</TableCell>
              <TableCell align="right">14</TableCell>
              <TableCell align="right">85</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Pune
              </TableCell>
              <TableCell align="right">19</TableCell>
              <TableCell align="right">11</TableCell>
              <TableCell align="right">12</TableCell>
              <TableCell align="right">8</TableCell>
              <TableCell align="right">50</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Nagpur
              </TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">12</TableCell>
              <TableCell align="right">20</TableCell>
              <TableCell align="right">9</TableCell>
              <TableCell align="right">41</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Aurangabad
              </TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">10</TableCell>
              <TableCell align="right">23</TableCell>
              <TableCell align="right">5</TableCell>
              <TableCell align="right">38</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Satara
              </TableCell>
              <TableCell align="right">21</TableCell>
              <TableCell align="right">8</TableCell>
              <TableCell align="right">13</TableCell>
              <TableCell align="right">14</TableCell>
              <TableCell align="right">56</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Total Workshops
              </TableCell>
              <TableCell align="right">95</TableCell>
              <TableCell align="right">57</TableCell>
              <TableCell align="right">108</TableCell>
              <TableCell align="right">62</TableCell>
              <TableCell align="right">322</TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th" scope="row">
              Beneficiary Teachers
              </TableCell>
              <TableCell align="right">3466</TableCell>
              <TableCell align="right">3284</TableCell>
              <TableCell align="right">5879</TableCell>
              <TableCell align="right">3169</TableCell>
              <TableCell align="right">15798</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
            </Col> 
           <Col sm="12" className="mt-2">
            <Typography variant="h5" className={classes.title}>
           Subjects Covered
          </Typography>
          <div className={classes.demo}>
            <List className="aboutList">  
                <ListItem className="pl-0 w-50  float-right">
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText> English First Language (HL)/ English Third Language (LL) </ListItemText>
                </ListItem>
                <ListItem className="pl-0 w-50 float-right">
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText> Marathi First Language (HL)/ Marathi Third Language (LL) </ListItemText>
                </ListItem>
                <ListItem className="pl-0 w-50 float-right">
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText> Hindi Second Language (LL)  </ListItemText>  
                </ListItem>
                <ListItem className="pl-0 w-50 float-right">
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText>  Primary Math Concepts (प्राथमिक गणित संबोध) (Std. I to IV) </ListItemText>
                </ListItem>
            </List>
          </div>
            </Col>
             <Col sm="12" className="mt-4">
               <p>The trainings included areas of evaluation of languages at IX, X syllabi and Math (primary) and Leadership Training for Principals.
              </p>
              <p>On Teachers Day 2020, Navneet Foundation launched NAVदिशा, a portal for Teachers.
The aim is to enhance reach and provide opportunities for teachers to stay abreast of
latest trends and useful information. It will also help to keep teachers continuously
inspired and motivated.</p>
            </Col> 

         </Row>
      </Container>
   </section>   


    <section>
            <Container>
              <Row className="Testimonial TrainingGallery mt-5">
                <Col col="sm-12" className="text-center">
                  <h2 className="mb-0">Glimpses of Past Trainings</h2>
                </Col>

                <Col col="lg-8 md-8 sm-10 " className="mx-auto TestBox"> 
                  <Carousel w="100" id="carouselExampleControls">  
                    <Carousel.Inner>
                       <Carousel.Item active>  
                          <Card>
                            <img src={groupwork} className="img-fluid w-100" alt="" />
                          </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Card>
                            <img src={phadke} className="img-fluid w-100" alt="" />
                          </Card>
                        </Carousel.Item>

                        {/* <Carousel.Item>
                           <Card>
                            <img src={group} className="img-fluid w-100" alt="" />
                          </Card>
                        </Carousel.Item> */}
                        <Carousel.Item>
                        <Card>
                            <img src={inauguaration} className="img-fluid w-100" alt="" />
                          </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                           <Card>
                            <img src={mumbai} className="img-fluid w-100" alt="" />
                          </Card>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Card>
                            <img src={nashik} className="img-fluid w-100" alt="" />
                          </Card>
                        </Carousel.Item>
                      </Carousel.Inner>
                      <Carousel.Prev href="#carouselExampleControls">
                      <ArrowLeftIcon className="ArrowLeft" />  
                      </Carousel.Prev>
                      <Carousel.Next href="#carouselExampleControls">
                      <ArrowRightIcon className="ArrowRight" />  
                      </Carousel.Next>
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </section>  


   
      <Footer />
      
    </div>
   }
</React.Fragment>
);
}
}
export default withStyles(styles)(PastTraining);