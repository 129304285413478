import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import CareerForm from '../training/CareerForm';
import "../style/training.scss";
import "../style/pastraining.scss";  
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button'; 
import { apiURL, isLogin, getID,  getName, getEmail, getContact } from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';  
import SanitizedHTML from 'react-sanitized-html';
import { withStyles, makeStyles } from "@material-ui/core/styles"; 
import Sign from "../login/Sign";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import Meta from "../meta/Meta";

const styles = theme => ({
   dialogPaper: {
   minHeight: '90vh',
   maxHeight: '90vh',
   borderRadius:'25px',
   maxWidth:'42.75rem',
   },
   });
class Training extends Component {
   _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        trainingSchedule: [],
        open:false,
        showComponent: false,
        alreadyRegister: false,
        alreadyRegister: false,
        userId: '',
        showSnackbar: false,
        snackBarMessage: '',
        severityColor: '',
        snackBarTimer: '',
        title: '', 
        joinNow: '',
        name: getName(),
        email: getEmail(),
        contact: getContact(),
    }
    this.trainingSchedule = this.trainingSchedule.bind(this); 
    this.registerNow          = this.registerNow.bind(this);
    this.checkUserRegister    = this.checkUserRegister.bind(this);
  }

   handleClickOpen = () => {
      if(!isLogin())
      {
         this.setState({ showComponent: true })
      }
      else{
        // this.setState({ open: true })
         //this.registerNow();
         this.setState({ registerDialog: true})
      }
   }

   
   registerTrainingClose = () => {
      this.setState({ registerDialog: false, id: '', title: ''})
   }

   handleClose = () => {
   this.setState({ open: false })
   }
   

  componentDidMount()
  {
    this._isMounted = true; 
    this.trainingSchedule(); 
    this.checkUserRegister();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  snackBarClose = (event, reason) => {
   if (reason === 'clickaway') {
       return;
     }
     this.setState({ showSnackbar: false }); 
 }
  
  checkUserRegister = () =>{
   const headers = {
     'content-type': 'multipart/form-data',
     'Accept': 'application/json'
     }
     const body = { 
         "training_id": this.props.match.params.trainingId, 
         "user_id": getID(), 
     }
     axios.post(apiURL('join_training_user/check_join_user'), body, {headers: headers})
     .then(response => {
         const resultData = response.data;  
         if(resultData.status === true || resultData.status === 1)
         { 
             this.setState({ alreadyRegister: true });
         } 
     })
     .catch(error => {
         console.log(error)
     })  
  }
 
  
  trainingSchedule = () =>{
  const headers = {
    'content-type': 'multipart/form-data',
    'Accept': 'application/json'
    }
    axios.get(apiURL('training_schedule/get_training_schedule_by_id/'+this.props.match.params.trainingId), {headers: headers})
    .then(response => {
        const resultData = response.data; 
        //alert(resultData.message);
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({ trainingSchedule: resultData.result});
        }
      
    })
    .catch(error => {
        console.log(error)
    })  
 }

 
 checkJoinNow = () => {
   this.trainingSchedule();
   this.setState({ 
      showComponent: false,
      registerDialog: true,
      name: getName(),
      email: getEmail(),
      contact: getContact(), 
    })
}


registerNow = () => {
   const headers = {
       'content-type': 'multipart/form-data',
       'Accept': 'application/json'
       }
       const body = { 
           "training_id": this.props.match.params.trainingId, 
           "user_id": getID(), 
       }
       axios.post(apiURL('join_training_user/add'), body, {headers: headers})
       .then(response => {
           const resultData = response.data;  
           if(resultData.status === true || resultData.status === 1)
           { 
               this.setState({ joinNow: true });
               this.componentDidMount();
               
               this.setState({ 
                  registerDialog: false,
                  name: '',
                  email: '',
                  contact: '',   
                  showSnackbar: true,
                  snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
                  severityColor: 'success',
                  snackBarTimer: '3000', 
               })
           }
           else{
               this.setState({noData: "Coming Soon"});
           }
           
       
       })
       .catch(error => {
           console.log(error)
       })  
   }
  

   render() {
      const { classes } = this.props;
      return (
         <React.Fragment>
         <Meta tableName={"training_schedule"} objectId={this.props.match.params.trainingId} forPage={"inner_page"} />
        {this.state.showComponent? <Sign  ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)}/>:
         <div>
         <Appbar />
         {/* <div className="NavBar   pb-0 sticky-top ">
            <Navmenu />
         </div> */}
         <div className="sticky-top sign-light">
            <Navmenu />    
         </div>  
         <section className="BannerBox TrainingBox upcoming">
            <div class="sixteen-nine">
               <div class="content text-center text-white invisible">
                  <h2>Upcoming Trainings Schedule</h2>
                  <p>UPCOMING TRAININGS SCHEDULE TO FEED YOUR BRAIN</p>
               </div>
            </div>
         </section>

         <section class="careerMain">
            <Container>
               <Row className="Blogbox  careerBox">
                  <Col col="sm-12 md-12"  className="mx-sm-auto " >
                     <Card>
                        <div class="clearfix">
                           <div className="headBox float-left pt-5 w-75">
                              <h2>{this.state.trainingSchedule.fldv_title}</h2>
                              <h5>{Moment(this.state.trainingSchedule.fldv_select_date).format('ddd')}, {Moment(this.state.trainingSchedule.fldv_from_time).format('LT')} to {Moment(this.state.trainingSchedule.fldv_to_time).format('LT')}</h5>  
                           </div>
                           <div className="float-right  dateBox text-center " > 
                              <h3>{Moment(this.state.trainingSchedule.fldv_select_date).format('D')}</h3> 
                              <h5>{Moment(this.state.trainingSchedule.fldv_select_date).format('MMMM')}</h5> 
                              <h6>{Moment(this.state.trainingSchedule.fldv_select_date).format('YYYY')}</h6>
                           </div>
                        </div>
                     <div className="SpeakerBox">
                        <div className="Speaker">
                           {/*<img src="../images/speaker-1.png" className="img-fluid float-left mr-3" alt="" />*/}
                           <img src={this.state.trainingSchedule.speaker_profile_image} className="img-fluid float-md-left mr-3" alt="" />
                           <h5>Speaker :</h5>
                           <h4>{this.state.trainingSchedule.speaker_name}</h4>
                        </div>
                     </div>
                     <img src={this.state.trainingSchedule.fldv_image_url} className="img-fluid w-100" alt={this.state.trainingSchedule.fldv_title}/>
                     <Card.Body className=" " > 
                        <Card.Text><SanitizedHTML allowedAttributes={false} allowedTags={false} html={this.state.trainingSchedule.fldv_description} /></Card.Text>
                     </Card.Body>
                     </Card>
                     {/* <Button className="CheckButton checkBtnSchedule text-center"> Register Now </Button> */}
                     {this.state.alreadyRegister?
                        <Button className="CheckButton checkBtnSchedule text-center"> You Have Already Register </Button>:
                        <Button className="CheckButton checkBtnSchedule text-center" onClick={this.handleClickOpen}>
                           Register Now
                        </Button>
                     }
                     
                     <Dialog open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description" classes={{ paper: classes.dialogPaper }} >
                           <h2 className="text-center  headBoxRegister text-uppercase">Register</h2>
                           <DialogContent>
                              <CareerForm />
                           </DialogContent> 
                     </Dialog>

                     <Dialog onClose={(e) => this.registerTrainingClose(e)} aria-labelledby="customized-dialog-title" open={this.state.registerDialog}>
                        <DialogTitle id="customized-dialog-title" onClose={(e) => this.registerTrainingClose(e)}>
                           {this.state.trainingSchedule.fldv_title}
                        </DialogTitle>
                        <DialogContent>
                           {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
                              <Grid container>
                              <Grid item xs={12} sm={12}>  
                                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="name" label="Name" onChange={(e) => this.handleChange(e)} value={this.state.name} variant="outlined" size="small" />
                              </Grid> 
                              <Grid item xs={12} sm={12}>  
                                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="Email" label="Email" onChange={(e) => this.handleChange(e)} value={this.state.email} variant="outlined" size="small"/>
                              </Grid>
                              <Grid item xs={12} sm={12}>  
                                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="Contact" label="Contact" onChange={(e) => this.handleChange(e)} value={this.state.contact} variant="outlined" size="small"/>
                              </Grid>
                              <Grid item xs={12} sm={12}>  
                                 <Button onClick={(e) => this.registerNow(e)} type="submit" fullWidth variant="contained" color="primary" className="mt-4  text-capitalize" >Submit</Button>
                              </Grid>
                              </Grid>
                           {/*</form>*/}
                        </DialogContent>
                        <DialogActions>
                        </DialogActions>
                  </Dialog>
                  
                  <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                     <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                     { this.state.snackBarMessage }
                     </Alert>
                  </Snackbar>

                  </Col>   
               </Row>
            </Container>
         </section>

         <div className="customFooter">
         <Footer />  
         </div>
         </div>
         }
         </React.Fragment>
      );
   }
}
export default withStyles(styles)(Training);