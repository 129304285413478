import React, { Component } from 'react';  
import ReactDom from 'react-dom';
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import ReactPlayer from 'react-player/lazy' ; 

class Video extends Component {
    render() {
      return (
        <React.Fragment>
         
         <div className='player-wrapper'>
        <ReactPlayer
          //  url='https://www.youtube.com/watch?v=1aZyMcRW7u0'  
          // url='https://www.youtube.com/watch?v=1aZyMcRW7u0&feature=youtu.be'
          url='https://www.youtube.com/watch?v=5b_gycY6CoE&feature=youtu.be'
          className='react-player'
          width='100%'
          height='100%'
          controls='true'
  // config={{ file: { attributes: {
  //   autoPlay: true,
  //   muted: true, 
  //   playing: true,
  //   controls: true
  // }
  // }
  // }}  



        />
      </div> 

            
        </React.Fragment>
      );
    }
  }

export default Video;