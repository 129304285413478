import React, { Component } from 'react'; 
import {Helmet} from "react-helmet";
import axios from 'axios'; 
import { apiURL } from '../utils';   

 
class Meta extends Component{
    constructor(props){
        super(props);
        this.state = {
            setMeta: [],
        }
    }
    componentDidMount(){
        //console.log(this.props.tableName)
        this.getMetaData();
    }

    getMetaData = () => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = { 
            "object_id": this.props.objectId,
            "table": this.props.tableName,
            "use_for": this.props.forPage,
        }
        axios.post(apiURL('meta/get_meta'), body, {headers: headers})
        .then(response => { 
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
               //console.log(resultData)
               this.setState({ setMeta: resultData.result }) 
               localStorage.setItem('meta_details', JSON.stringify(resultData.result));
              // var x = JSON.parse(localStorage.getItem("meta_details")); 
                var title = resultData.result.fldv_title;
                document.title = title;
            } 
        })
    
        .catch(error => {
            console.log(error)
        }) 
    }
  

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    {
                        this.props.title?
                        <title>{this.props.title}</title>:
                        <title>{this.state.setMeta.fldv_title}</title>
                        
                    }
                    
                    <meta charset="utf-8" />
                    <link rel="icon" href="/navdisha-favicon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="theme-color" content="#000000" />
                    <meta http-equiv='cache-control' content='no-cache' />
                     <meta http-equiv='expires' content='0' /> 
                     <meta http-equiv='pragma' content='no-cache' />
                     <meta name='robots' content={this.state.setMeta.fldv_meta_description} />
                     <link rel="canonical" href={window.location.href} />

                    <meta name="description" content={this.state.setMeta.fldv_meta_description} />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    {/*Google / Search Engine Tags */}
                    <meta itemprop="name" content={this.state.setMeta.fldv_title} />
                    <meta itemprop="description" content={this.state.setMeta.fldv_meta_description} />
                    <meta itemprop="image" content="" />

                                        
                    {/*Facebook Meta Tags */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={ this.props.title?this.props.title:this.state.setMeta.fldv_title} />
                    <meta property="og:description" content={this.state.setMeta.fldv_meta_description} />
                    <meta property="og:image" content="" />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={this.props.title?this.props.title:this.state.setMeta.fldv_title} />
                    <meta name="twitter:description" content={this.state.setMeta.fldv_meta_description} />
                    <meta name="twitter:image" content="" />

                    {/*<meta property="og:description" name="description" content={this.state.setMeta.fldv_meta_description} />
                    <meta property="og:keywords" name="keywords" content={this.state.setMeta.fldv_meta_key} />
                    <meta property="og:author"name="author" content={this.state.setMeta.fldv_meta_name} />*/}
                    <meta httpEquiv="Content-Type" content="text/tml" charSet="utf-8" />
                    
                    {/*{
                        this.props.title?
                        <meta property="og:image" content={this.props.title} />:  
                        <meta property="og:title" content={this.state.setMeta.fldv_title} />
                    }
                    {
                        this.props.imageSource?
                        <meta property="og:image" content={this.props.imageSource} />:""
                    }*/}
                </Helmet>
            </React.Fragment>
        )
    }
}
export default Meta;