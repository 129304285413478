import React, { Component } from 'react';  
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
//import PasswordField from 'material-ui-password-field';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as yup from 'yup'; 

import { FormGroup, FormControlLabel, Snackbar, InputAdornment, IconButton, Grid } from "@material-ui/core";
// import SearchIcon from '@material-ui/icons/Search';
import { apiURL, login, getID } from '../utils'; 
import axios from 'axios';   
import Alert from '@material-ui/lab/Alert'; 
// import { Link, useHistory, withRouter } from "react-router-dom";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import firebase from '../firebase';


 const FormControl    = require('@material-ui/core/FormControl').default;
 const FormHelperText = require('@material-ui/core/FormHelperText').default;
//  const InputLabel     = require('@material-ui/core/InputLabel').default;
 
 const styles = theme => ({
    root: {
        '& > *': {
          margin: theme.spacing(0),
          width: '100%',
        },
      },
  });

  const LoginSchema = yup.object().shape({
    username: yup.string().required("This field is required."),
    password: yup.string().required("This field is required."),  
    //agree: yup.string().required('Please Check, I agree to the Terms & Conditions'),
  }); 
 
class SignIn extends Component {
  //state = { checked: false }
  _isMounted = false;
  constructor(props){
    super(props);
    this.state = { 
      checked: false,
      checkBoxError: false,
      checkBoxErrorMsg: "",
      showSnackbar: false,
      snackBarMessage: '',
      severityColor:'',
      reload: false
   }
  }
    
componentDidMount()
{
  this._isMounted = true;
  //console.log('props '+JSON.stringify(this.props.history)); 
  //alert('login page'+JSON.stringify(this.props.propsData.history));
  
 // this.props.propsData.history.push(this.props.propsData.history.location.pathname); 
 // alert(this.props.propsData.history.location.pathname)

}

insertToken = (token, userId) => {
  const headers = { 
  'Content-type': 'application/json'
  }
  const body = { 
      "token": token, 
      "user_id": userId,
  }
  axios.post(apiURL('firebase_notification/add'), body, {headers: headers})
  .then(response => { 
      const resultData = response.data; 
      if(resultData.status === true || resultData.status === 1)
      { 
        console.log(resultData)
        this.setState({ setMeta: resultData.result })
      } 
  })
 }

componentWillUnmount() {
  this._isMounted = false; 
}

  handleCheckboxChange = event => {
    this.setState({ checked: event.target.checked }) 
    if(event.target.checked === false)
    {
      this.setState({
        checkBoxError: true,
        checkBoxErrorMsg: "Please Check, I agree to the Terms & Conditions"
      })
    }
    else{
      this.setState({
        checkBoxError: false,
        checkBoxErrorMsg: ""
      })
    }
  }

  onSubmit = (values) => { 
    const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
    }
    axios.post(apiURL('teacher'),values, {headers: headers})
    .then(response => {
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        {  
            localStorage.setItem('user_detail', JSON.stringify(resultData.result));
            localStorage.setItem('login_status', true);  
            //alert(JSON.stringify(this.props.propsData));
            //this.props.propsData.history.push('/'); 
            const path = this.props.propsData.history.location.pathname;  
            if(path !== '/sign')
            { 
              this.props.propsData.history.push(path);

              if(this.props.checkJoinNow) // this prop send from webinar as per require
              {
                this.props.checkJoinNow(true);
              }
            }
            else{ 
              this.props.propsData.history.push('/');
            }
            
              if (firebase.messaging.isSupported()){ 
                const messaging = firebase.messaging();
                messaging.requestPermission().then(() => {
                  return messaging.getToken()
                }).then(token => {
                  console.log('Token', token);
                  this.insertToken(token, getID());
                }).catch(() => {
                  console.log("firebase error");
                })
              }
              else{
                console.log("firebase snot supported");
              }
        }
        else if(resultData.status === "authrntication_failed")
        {
          this.setState({ snackBarMessage: resultData.message,showSnackbar: true , severityColor:'warning'});
        }
        else{
          this.setState({ snackBarMessage: resultData.message,showSnackbar: true , severityColor:'error'});
        }  
       
    })
    .catch(error => {
        console.log(error)
    })  
 }

 passwordChange = (event) => {
  this.setState({ password: event.target.value })
  
};

handleClickShowPassword = (event) => {
  this.setState({ showPassword: !this.state.showPassword })
};

handleMouseDownPassword = (event) => {
  event.preventDefault();
  this.setState({ showPassword: !this.state.showPassword })
};

 snackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
      }
      this.setState({ showSnackbar: false }); 
  }

    render() {
        const { classes } = this.props;
      return (
        <React.Fragment>
          <Formik
          initialValues={{
            username: "",
            password: "", 
            //agree:"",
          }}
          validationSchema={LoginSchema}
          onSubmit={values => { 
            if(!this.state.checkBoxError)
            {
              this.onSubmit(values)
            } 
          }}
        >
          {({ errors, handleChange, touched }) => ( 
            <Form id="login" className={classes.root}>
              
              <Grid container>
                <Grid item xs={12} sm={12}>  
                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="username" label="Username" onChange={handleChange} value={this.state.username} error={errors.username && touched.username} helperText={errors.username && touched.username ? errors.username : null} />
                </Grid>
               {/*<TextField  type="password"className="mt-4" name="password" id='name-label' label="Password" onChange={handleChange} value={this.state.password} error={errors.password && touched.password}  helperText={errors.password && touched.password ? errors.password : null} />  */}

                <Grid item xs={12} sm={12}>
                      <FormControl fullWidth  className="mt-4"> 
                        <TextField
                            label='Password'
                            variant="standard"
                            type={this.state.showPassword ? "text" : "password"} // <-- This is where the magic happens
                            id="password"
                            onChange={(e) => this.passwordChange(e, 'password')}
                            onBlur={handleChange}
                            onKeyUp={handleChange}
                            onKeyDown={handleChange} 
                            error={errors.password && touched.password} 
                            helperText={errors.password && touched.password ? errors.password : null} 
                            InputProps={{ // <-- This is where the toggle button is added.
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => this.handleClickShowPassword(e)}
                                    onMouseDown={(e) => this.handleMouseDownPassword(e)}
                                  >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                      </FormControl>
                    </Grid>
 
                    {/*W<Grid item xs={12} sm={12}>  
                      <FormControl required error={errors.agree && touched.agree || this.state.checkBoxError} component="fieldset" className="mt-4" size="small"> 
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={this.state.checked} onClick={this.handleCheckboxChange} onKeyUp={handleChange} onKeyDown={handleChange} onChange={handleChange} name="agree" />}
                            label="I agree to the Terms & Conditions"
                          />  
                        </FormGroup>
                        <FormHelperText> {errors.agree && touched.agree ? errors.agree : null || (this.state.checkBoxError)?this.state.checkBoxErrorMsg:""}</FormHelperText>
                      </FormControl>
                  </Grid>*/}
                  
                  <Grid item xs={12} sm={12}>  
                    <Button type="submit" fullWidth variant="contained" color="primary" className="mt-4 text-capitalize" >Sign In</Button>
                  </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Snackbar  anchorOrigin={{  vertical: "top", horizontal: "center" }}  open={this.state.showSnackbar === true} autoHideDuration={6000} onClose={this.snackBarClose}>
            <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
            { this.state.snackBarMessage }
            </Alert>
        </Snackbar>
        </React.Fragment>
      );
    }
  }


  export default withStyles(styles)(SignIn);