import React, { Component } from 'react';   
import { Row, Col, Card  } from 'bootstrap-4-react';
import { apiURL } from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';  
import { Link } from 'react-router-dom';    


class Blog extends Component {
  _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        blogList: []
    }
    this.getBlog = this.getBlog.bind(this); 
  }

  componentDidMount()
  {
    this._isMounted = true; 
    this.getBlog(); 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getBlog = () =>{
    const headers = {
      'content-type': 'multipart/form-data',
      'Accept': 'application/json'
      }
      const body = {"search": ""}
      axios.post(apiURL('blog?per_page=3&page=1'), body, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          //alert(resultData.message);
          if(resultData.status === true || resultData.status === 1)
          { 
              this.setState({ blogList: resultData.result});
          }
        
      })
      .catch(error => {
          console.log(error)
      })  
   }

    render() {
      return (
        <React.Fragment>
            <Row className="Blogbox home-blog">
              <Col col="sm-12" className="text-center">
                <h2>Recent Blogs</h2>   
              </Col>
              {
                this.state.blogList.map((arrList, index) => { 
                  return <Col col="sm-10 md-8 lg-4"  className="mx-sm-auto" key={arrList.fldi_id} >
                    <Link target="blank" to={'blog/'+arrList.fldi_id+'/'+arrList.fldv_title.replace(/ /g, '-').toLowerCase()}>
                    <Card>
                      <img src={arrList.fldv_image_url} className="img-fluid" alt={arrList.fldv_title}/>
                      <Card.Body className="text-center">
                        <Card.Title>{arrList.fldv_title}</Card.Title> 
                        <h5 className="d-none" > 
                          {Moment(arrList.fldv_selected_date).format('D MMMM, YYYY')}
                        </h5>
                        </Card.Body> 
                      </Card>
                      </Link>
                  </Col>
                })
              }
            </Row>      
        </React.Fragment>
      );
    }
  }

export default Blog;