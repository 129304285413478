import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/training.scss";
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';  
import { apiURL, isLogin, getName, getEmail, getContact, getID } from '../utils'; 
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import axios from 'axios'; 
import Moment from 'moment';   
import Sign from "../login/Sign";  
import Meta from '../meta/Meta'; 

class Upcoming_webinar extends Component {
  _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        upcomeingEventsList: [],
        noData: '',
         registerDialog: false,
        showComponent: false,
        name: getName(),
        email: getEmail(),
        contact: getContact(),
        title: '',
        id: '',
        joinNow: '',
        alreadyRegister: false,
        userId: '',
        showSnackbar: false,
        snackBarMessage: '',
        severityColor: '',
        snackBarTimer: ''
    }
    this.getUpComeinEvents = this.getUpComeinEvents.bind(this); 
    this.registerNow       = this.registerNow.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true; 
    this.getUpComeinEvents(); 
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  snackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
      }
      this.setState({ showSnackbar: false }); 
  }

  
 getUpComeinEvents = () =>{
  const headers = {
    'content-type': 'multipart/form-data',
    'Accept': 'application/json'
    }
    const body = { 
        "status": 1,
        "event_type": "UE",
        "user_id": (isLogin())?getID():this.state.userId
    }
    axios.post(apiURL('webinar/events'), body, {headers: headers})
    .then(response => {
        const resultData = response.data;  
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({ upcomeingEventsList: resultData.result});
        }
        else{
            this.setState({noData: "Coming Soon"});
        }
        
      
    })
    .catch(error => {
        console.log(error)
    })  
 }

 
 registerNow = () => {
  const headers = {
      'content-type': 'multipart/form-data',
      'Accept': 'application/json'
      }
      const body = { 
          "webinar_id": this.state.id, 
          "user_id": getID(), 
      }
      axios.post(apiURL('join_webinar_user/add'), body, {headers: headers})
      .then(response => {
          const resultData = response.data;  
          if(resultData.status === true || resultData.status === 1)
          { 
              this.setState({ 
                joinNow: true, 
                registerDialog: false,
                showSnackbar: true,
                snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
                severityColor: 'success',
                snackBarTimer: '3000', 
              });
              this.componentDidMount();
          }
          else{
              this.setState({noData: "Coming Soon"});
          }
          
      
      })
      .catch(error => {
          console.log(error)
      })  
  }

  registerForWebinar = (e, webinarId, title) => {
      if(!isLogin())
      {
        this.setState({ showComponent: true })
      }
      else{
        this.getUpComeinEvents();
        this.setState({ registerDialog: true, id: webinarId, title: title })
      }
    }
 
  registerWebinarClose = () => {
    this.setState({ registerDialog: false, id: '', title: ''})
  }
 
 checkJoinNow = () => {
   this.getUpComeinEvents();
   this.setState({ 
     showComponent: false, 
     //registerDialog: true,
     name: getName(),
     email: getEmail(),
     contact: getContact(),
     userId: getID(),
     id: this.state.id,
     title: this.state.title
   })
 }

 handleChange(e) {
   this.setState({
      [e.target.name]: e.target.value
   });
 }
 
  render() {
    return (
      <React.Fragment>
      <Meta tableName={"menu"} objectId={10} forPage={"main_page"} />
      {this.state.showComponent? <Sign  ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)}/>:
       <div>
          <Appbar />
          <div className="sticky-top sign-light">
            <Navmenu />    
         </div>  
        
      <section className="BannerBox TrainingBox upComeingWebinar d-none">{/* remove d-none for sow*/}
          <div class="sixteen-nine">
            <div class="content text-center text-white invisible">
                <h2>Past Webinars</h2>
                <p className="text-uppercase">Past Webinars TO FEED YOUR BRAIN</p>
            </div>
          </div>
      </section>

          <section class="careerMain eventMain">
              <Container>
              <Row className="Blogbox  careerBox EventBox position-relative">
                      <h1 className="text-center d-block w-100 position-absolute">Upcoming Webinars</h1>
                    {
                    this.state.upcomeingEventsList.map((arrList, index) => {
                     return <Col col="sm-12 md-8 lg-4" className="mx-auto">
                       <Link to={"/webinar/"+arrList.fldi_id+"/"+arrList.fldv_title.replace(/ /g, '-').toLowerCase()}>   
                        <Card>  
                            <div className="position-relative clearfix">
                                <figure>
                                    <figcaption>
                                      <div className="dateBox text-center " >
                                        <h3>{Moment(arrList.fldv_select_date).format('D')}</h3> 
                                        <h5>{Moment(arrList.fldv_select_date).format('MMMM')}</h5> 
                                        <h6>{Moment(arrList.fldv_select_date).format('YYYY')}</h6>
                                      </div>
                                    </figcaption>
                                    <div className="imgBoxtraining">
                                    <img style={{width:"100%"}} src={arrList.fldv_image_url} className="img-fluid" alt=""/>
                                    </div>
                                    <figcaption >
                                      <div className="SpeakerBox text-center ">
                                        <div className="Speaker">
                                          <div className="d-block imgBox">
                                            <img src={arrList.speaker_profile_image} className="img-fluid rounded-circle" alt={arrList.fldv_title} />
                                          </div>
                                          <h4>{arrList.speaker_name}</h4>
                                        </div>
                                      </div>
                                    </figcaption>
                                </figure>
                            </div>
                            <Card.Body>
                              <Card.Text>
                                <div className="headBox text-center">
                                    <h4 >Topic: {arrList.fldv_title}</h4>
                                    {/* <h5> <AccessTimeIcon /> {Moment(arrList.fldt_time).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}
                                    </h5>   */}
                                </div>
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                             <div className="headBox text-center ">
                               <h5> <AccessTimeIcon /> {Moment(arrList.fldv_select_date).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}</h5>
                               </div>  
                        </Card.Footer>
                        </Card>
                        </Link>
                        <div className="d-flex justify-content-center position-absolute boxFeedback">
                          {
                            (arrList.join_webinar)?
                            <Button className="CheckButton text-center"> You Have Already Registered</Button>:
                            <Button className="CheckButton text-center" onClick={(e) => this.registerForWebinar(e, arrList.fldi_id, arrList.fldv_title)}> Register Now </Button>
                          }
                        </div>
                    </Col>
                    })
                  }   
                  
                  <Col col="sm-12" className="text-center noFound"> <h3>{this.state.noData}</h3></Col>
                  </Row>
              </Container>
          </section>

          <div className="customFooter mt-0">
            <Footer />  
          </div>
          <Dialog onClose={(e) => this.registerWebinarClose(e)} aria-labelledby="customized-dialog-title" open={this.state.registerDialog}>
              <DialogTitle id="customized-dialog-title" onClose={(e) => this.registerWebinarClose(e)}>
                {this.state.title}
              </DialogTitle>
              <DialogContent>
                {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
                  <Grid container>
                    <Grid item xs={12} sm={12}>  
                        <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="name" label="Name" onChange={(e) => this.handleChange(e)} value={this.state.name} variant="outlined" size="small" />
                    </Grid> 
                    <Grid item xs={12} sm={12}>  
                        <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="Email" label="Email" onChange={(e) => this.handleChange(e)} value={this.state.email} variant="outlined" size="small"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>  
                        <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="Contact" label="Contact" onChange={(e) => this.handleChange(e)} value={this.state.contact} variant="outlined" size="small"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>  
                      <Button onClick={(e) => this.registerNow(e)} type="submit" fullWidth variant="contained" color="primary" className="mt-4 text-capitalize" >Submit</Button>
                    </Grid>
                  </Grid>
                {/*</form>*/}
              </DialogContent>
              <DialogActions>
                {/*<Button autoFocus onClick={(e) => this.registerTrainingClose(e)} color="primary">
                  Save changes
                </Button>*/}
              </DialogActions>
        </Dialog>
            <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
               <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
               { this.state.snackBarMessage }
               </Alert>
            </Snackbar>
          </div>
        }
      </React.Fragment>
      );
    }
  }
export default Upcoming_webinar;  
