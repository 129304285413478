import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/training.scss";
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';  
import { apiURL, isLogin, getName, getEmail, getContact, getID} from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';  
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Sign from "../login/Sign"; 
import Alert from '@material-ui/lab/Alert'; 
import { withStyles } from "@material-ui/core/styles"; 
import Meta from '../meta/Meta'; 



import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';



const styles = theme => ({
  dialogPaper: {
  borderRadius:'25px',
  maxWidth:'42.75rem',
  },
  title: {
    margin: theme.spacing(4, 0, 0),
    fontSize:'1.75rem',
    [theme.breakpoints.down('sm')]: {
       fontSize:'1.5rem',  
      },
  },
  title1: {
    // margin: theme.spacing(0, 0, 0),
    fontSize:'1.25rem',
    fontFamily:'gotham rounded medium',
    width:'17rem'

  },
  });

class TrainingSchedule extends Component {
  _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        upcomeingEventsList: [],
        noData: '',
        registerDialog: false,
        showComponent: false,
        name: getName(),
        email: getEmail(),
        contact: getContact(),
        title: '',
        id: '',
        joinNow: '',
        alreadyRegister: false,
        userId: '',
        showSnackbar: false,
        snackBarMessage: '',
        severityColor: '',
        snackBarTimer: ''
    }
    this.getUpComeinEvents = this.getUpComeinEvents.bind(this); 
    this.registerTraining  = this.registerTraining.bind(this);
    this.registerTrainingClose  = this.registerTrainingClose.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true; 
    this.getUpComeinEvents(); 
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  snackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
      }
      this.setState({ showSnackbar: false }); 
  }
  
 getUpComeinEvents = () =>{
  const headers = {
    'content-type': 'multipart/form-data',
    'Accept': 'application/json'
    }
    const body = { 
        "status": 1,
        "event_type": "UE",
        "user_id": (isLogin())?getID():this.state.userId
    }
    axios.post(apiURL('training_schedule/events'), body, {headers: headers})
    .then(response => {
        const resultData = response.data; 
        //alert(resultData.message);
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({ upcomeingEventsList: resultData.result});
        }
        else{
            this.setState({noData: "Coming Soon"});
        }
        
      
    })
    .catch(error => {
        console.log(error)
    })  
 }

 registerTraining = (e, trainingId, title) => {
   if(!isLogin())
   {
      this.setState({ showComponent: true })
   }
   else{
     this.getUpComeinEvents();
     this.setState({ registerDialog: true, id: trainingId, title: title })
   }
 }

 registerTrainingClose = () => {
   this.setState({ registerDialog: false, id: '', title: ''})
 }

checkJoinNow = () => {
  this.getUpComeinEvents();
  this.setState({ 
    showComponent: false, 
    //registerDialog: true,
    name: getName(),
    email: getEmail(),
    contact: getContact(),
    userId: getID(),
  })
}

handleChange(e) {
  this.setState({
     [e.target.name]: e.target.value
  });
}


registerNow = () => {
  const headers = {
      'content-type': 'multipart/form-data',
      'Accept': 'application/json'
      }
      const body = { 
          "training_id": this.state.id, 
          "user_id": getID(), 
      }
      axios.post(apiURL('join_training_user/add'), body, {headers: headers})
      .then(response => {
          const resultData = response.data;  
          if(resultData.status === true || resultData.status === 1)
          { 
              this.setState({ 
                joinNow: true, 
                registerDialog: false,
                showSnackbar: true,
                snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
                severityColor: 'success',
                snackBarTimer: '3000', 
              });
              this.componentDidMount();
          }
      })
      .catch(error => {
          console.log(error)
      })  
  }
 


  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
      <Meta tableName={"menu"} objectId={7} forPage={"main_page"} />
        {this.state.showComponent? <Sign  ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)}/>:
         <div>
        <Appbar /> 
        <div className="sticky-top sign-light">
            <Navmenu />    
        </div>  
        <section className="BannerBox TrainingBox upcoming">
            <div class="sixteen-nine">
              <div class="content text-center text-white invisible" >
                  <h2>Upcoming Trainings Schedule</h2>
                  <p>UPCOMING TRAININGS SCHEDULE TO FEED YOUR BRAIN</p>
              </div>
            </div>
        </section>


   <section className="AboutBox mt-0 ">
      <Container>
         <Row>
            <Col col="md-12 sm-12" className="order-2">
            <h2 className="mt-sm-5 mt-0"> 
               <span class="blueHead">Introduction</span>
            </h2>
           
          <p>The teachers are provided training by Education
Department of Govt. of Maharashtra. To
acquaint the teachers with the new concept of
‘Krutipatrika’, Navneet Foundation decided to
strengthen the efforts of Education
Department and thus initiated the training in
‘Krutipatrika’ on Workshop mode for teachers
since June 2016.</p>
         
            </Col>
            {/* <Col  col="md-5 sm-12" className="order-sm-2 order-1">
            <img src={aboutTeacher} className="img-fluid w-25 invisible"/> 
            </Col> */}
          
           
          
           <Col sm="12" className="mt-2 order-3">
            <Typography variant="h3" className={classes.title}>
            Objectives of the Workshops for teachers:
          </Typography>
          <div className={classes.demo}>
            <List className="aboutList">  
                <ListItem className="pl-0  float-right">
                <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText> To acquaint teachers with the significance of activity based teaching
learning </ListItemText>
                </ListItem>
                <ListItem className="pl-0  float-right">
                <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText> To enable teachers to understand the concept and format of ‘Krutipatrika’
(languages) introduced in Std. IX &amp; X </ListItemText>
                </ListItem>
                <ListItem className="pl-0 float-right">
                <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText> To develop skill of activity based evaluation based on ‘Krutipatrika’ (Activity
Sheet)  </ListItemText>  
                </ListItem>
                <ListItem className="pl-0  float-right">
                <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText>  To develop skills to design ‘Krutipatrika’ in the prescribed format </ListItemText>
                </ListItem>

                <ListItem className="pl-0  float-right">
                <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                 <ListItemText> To empower teachers with skill of performance assessment of their students
in the revised pattern </ListItemText>
               </ListItem>

                
            </List>
          </div>
            </Col> 

            <Col sm="12" className="mt-2 order-4">
            <Typography variant="h3" className={classes.title}>
            Training Module:
          </Typography>
          <div className={classes.demo}>
            <List className="aboutList trainingList smalltraining">  
                <ListItem className="pl-0">
                <Typography variant="h5" className={classes.title1}>
                Format:
                  </Typography>
                  <ListItemText> Workshop mode to provide hands-on learning experience to the
teachers </ListItemText>
                </ListItem>
                <ListItem className="pl-0 ">
               
                  <Typography variant="h5" className={classes.title1}>
                  Number of Participants:
          </Typography>

                  <ListItemText> about 40 – 50</ListItemText>
                </ListItem>
                <ListItem className="pl-0">
                <Typography variant="h5" className={classes.title1}>
                Duration:
                </Typography>
                  <ListItemText> One day (from 9.30 a.m. to 5.30 p.m.) </ListItemText>  
                </ListItem>
                <ListItem className="pl-0">
                <Typography variant="h5" className={classes.title1}>
                Mode of training:
                </Typography>
                  <ListItemText>  Cascade </ListItemText>
                </ListItem>

               

                
            </List>
          </div>
            </Col>

         
            
         <Col sm="12" className="order-5">   
            <p>The training is imparted by well trained &amp; knowledgeable Resource Persons, who
are initially trained to conduct such workshops.</p>
            <p>Navneet Foundation has created a bank of more than <b> 500 </b>such Resource
Persons of different subjects till date to conduct Krutipatrika workshops
across Maharashtra.</p>
            <p>Entire Krutipatrika is covered by conducting each section one – by – one by
three Resource Persons.</p>
         </Col>

            
         </Row>
      </Container>
   </section>  





          {/* <section class="careerMain eventMain">
              <Container>
              <Row className="Blogbox  careerBox EventBox position-relative">
                      <h1 className="text-center d-block w-100 position-absolute"> Upcoming Trainings</h1>
                    {
                    this.state.upcomeingEventsList.map((arrList, index) => {
                     return <Col col="sm-12 md-4" key={index}>  
                      <Link to={"/training/"+arrList.fldi_id+"/"+arrList.fldv_title.replace(/ /g, '-')}> 
                          <Card className="h-auto">  
                              <div className="position-relative clearfix">
                                  <figure>
                                      <figcaption>
                                        <div className="dateBox text-center " >
                                          <h3>{Moment(arrList.fldv_select_date).format('D')}</h3> 
                                          <h5>{Moment(arrList.fldv_select_date).format('MMMM')}</h5> 
                                          <h6>{Moment(arrList.fldv_select_date).format('YYYY')}</h6>
                                        </div>
                                      </figcaption>
                                      <div className="imgBoxtraining">
                                      <img style={{width:"100%"}}  src={arrList.fldv_image_url} className="img-fluid" alt={arrList.fldv_title}/> 
                                      </div>
                                      <figcaption >
                                        <div className="SpeakerBox text-center ">
                                          <div className="Speaker">
                                            <div className="d-block  imgBox">
                                              <img src={arrList.speaker_profile_image} className="img-fluid rounded-circle " alt={arrList.speaker_name} />
                                            </div>
                                            <h4>{arrList.speaker_name}</h4>
                                          </div>
                                        </div>
                                      </figcaption>
                                  </figure>
                              </div>
                              <Card.Body className="" >
                                <Card.Text>
                                  <div className="headBox text-center ">
                                      <h4 >Topic: {arrList.fldv_title}</h4>
                                       <h5> <AccessTimeIcon /> {Moment(arrList.fldv_select_date).format('ddd')}, {Moment(arrList.fldv_from_time).format('LT')} to {Moment(arrList.fldv_to_time).format('LT')}</h5>  
                                  </div>
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                              <div className="headBox text-center ">
                              <h5> <AccessTimeIcon /> {Moment(arrList.fldv_select_date).format('ddd')}, {Moment(arrList.fldv_from_time).format('LT')} to {Moment(arrList.fldv_to_time).format('LT')}</h5> 
                             </div>  
                               </Card.Footer>
                            </Card>
                          </Link>
                          <div className="d-flex justify-content-center position-absolute boxFeedback">
                          {
                            (arrList.join_training)?
                            <Button className="CheckButton text-center"> You Have Already Register</Button>:
                            <Button className="CheckButton text-center" onClick={(e) => this.registerTraining(e, arrList.fldi_id, arrList.fldv_title)}> Register Now </Button>
                          }
                          </div>   
                             
                    </Col>
                    })
                  }   
                  <h3 className="text-center d-block w-100 noFound">{this.state.noData}</h3>
                  </Row>
              </Container>
          </section> */}

          <div className="customFooter mt-0">
            <Footer />  
          </div>

        <Dialog onClose={(e) => this.registerTrainingClose(e)} aria-labelledby="customized-dialog-title" classes={{ paper: classes.dialogPaper }} open={this.state.registerDialog}>
        <h2 className="text-center  headBoxRegister text-uppercase">Register</h2>
              {/* <DialogTitle id="customized-dialog-title" onClose={(e) => this.registerTrainingClose(e)}>
             
              </DialogTitle>   */}
              <h3 onClose={(e) => this.registerTrainingClose(e)} className="text-center font-weight-bold mt-3">{this.state.title}</h3>   
              
              <DialogContent className="careerForm feedBackForm">  
                {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
               
                  <Grid container>
                  <Grid item xs={12} sm={12}> 
                  <TextField fullWidth id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="name" label="Name" onChange={(e) => this.handleChange(e)} value={this.state.name}/>
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                  <TextField fullWidth id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="Email" label="Email" onChange={(e) => this.handleChange(e)} value={this.state.email}/>
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                  <TextField fullWidth id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="Contact" label="Contact" onChange={(e) => this.handleChange(e)} value={this.state.contact}/>
                  </Grid>
                  <Grid item xs={12} sm={12} > 
                  <Button type="submit" onClick={(e) => this.registerNow(e)} className="CheckButton text-center mt-4 mb-3" >
                  Submit
                </Button>
                </Grid>
                  </Grid>
                
                {/*</form>*/}
              </DialogContent>
              <DialogActions>
                {/*<Button autoFocus onClick={(e) => this.registerTrainingClose(e)} color="primary">
                  Save changes
                </Button>*/}
              </DialogActions>
        </Dialog>
            <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
               <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
               { this.state.snackBarMessage }
               </Alert>
            </Snackbar>
        </div>
        }
      </React.Fragment>
      );
    }
  }  
export default withStyles(styles)(TrainingSchedule);
