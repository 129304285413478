import React,  {Component} from 'react';
import ReactDom from 'react-dom';
import Navmenu from '../components/Navmenu';
import Bootstrap, { Carousel} from 'bootstrap-4-react';
import Appbar from '../components/Appbar';  
import "../style/About.scss";    
import Footer from '../components/Footer';  
import Banner from '../about/Banner';
import AboutTeacher from './AboutTeacher';
import Team from '../about/Team';
import Video from '../home/Video';
import Teacher from '../about/Teacher';
import Activities from '../about/Activities';
import Meta from '../meta/Meta'; 

class About extends Component {
  

  componentDidMount() {
   window.scrollTo(0, 0)
 }

componentWillMount() {
window.setTimeout(() => Bootstrap.carousel('#carouselExampleCaptions'), 6000);
}



render() {
   
return (
<React.Fragment >
   <Appbar  />
   {/* <div className="NavBar pb-0 sticky-top">
      <Navmenu />
   </div> */}


           <div className="sticky-top sign-light">
            <Navmenu />    
          </div>  

   
   <Banner />
   <AboutTeacher />
   {/*<Team />*/}
   <section className="videoBox aboutVideobox">
      <Video />
   </section>
   {/*<section className="BestInstructor">
      <Teacher />
   </section>*/}
   {/*<div className="clearfix"></div>*/}
   <section className="Activities pb-0">
      <Activities />
   </section>
   <div className="custom-footer mt-0">
   <Footer /> 
   </div> 
   <Meta tableName={"menu"} objectId={2} forPage={"main_page"} />  
</React.Fragment>
);
}
}
export default About;
