import React, { Component } from 'react';  
import { List } from 'bootstrap-4-react';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/Webinar.scss";
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import { apiURL, isLogin, getID,  getName, getEmail, getContact, getCurrentTime, getCurrentDate, getCurrentMonth,getCurrentYear} from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';   
import SanitizedHTML from 'react-sanitized-html'; 
import Sign from '../login/Sign'; 
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 

import dateImage from '../images/date.png';
import timeImage from '../images/time.png';
import durationImage from '../images/duration.png';
import priceImage from '../images/price.png';
import Video_dialog from "../Video_dialog";
import Meta from "../meta/Meta";

const styles = theme => ({
    root: {
    maxWidth: "100%",  
    },
});
class Webinar extends Component {   
    _isMounted = false;
    constructor(props){
      super(props);
      this.state = {  
        eventData: [],
        noData: '',
        points: [],
        speakers: [],
        joinNow: false,
        showComponent: false, 
        title: '', 
        joinNow: '',
        name: getName(),
        email: getEmail(),
        contact: getContact(),
        dialogOpen: false,
        videoUrl: '',
      }
      
      this._sign = React.createRef();
      this.getWebinar           = this.getWebinar.bind(this); 
      this.checkUserRegister    = this.checkUserRegister.bind(this);
      this.registerNow          = this.registerNow.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true; 
        this.getWebinar(); 
        this.checkUserRegister();
    } 
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  
    
    checkUserRegister = () =>{
        const headers = {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json'
          }
          const body = { 
              "webinar_id": this.props.match.params.webinarId, 
              "user_id": getID(), 
          }
          axios.post(apiURL('join_webinar_user/check_join_user'), body, {headers: headers})
          .then(response => {
              const resultData = response.data;  
              if(resultData.status === true || resultData.status === 1)
              { 
                  this.setState({ joinNow: true });
              } 
          })
          .catch(error => {
              console.log(error)
          })  
       }
      
    getWebinar = () =>{
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        const body = { 
            "webinar_id": this.props.match.params.webinarId,  
        }
        axios.post(apiURL('webinar/get_webinar_by_id'), body, {headers: headers})
        .then(response => {
            const resultData = response.data;  
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({ 
                        eventData: resultData.result, 
                        points: JSON.parse(resultData.result.fldt_points), 
                        speakers: resultData.result.speaker, 
                    }); 
            }
            else{
                this.setState({noData: "Coming Soon"});
            }
        })
        .catch(error => {
            console.log(error)
        })  
     }

     registerNow = () => {
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
            }
            const body = { 
                "webinar_id": this.props.match.params.webinarId, 
                "user_id": getID(), 
            }
            axios.post(apiURL('join_webinar_user/add'), body, {headers: headers})
            .then(response => {
                const resultData = response.data;  
                if(resultData.status === true || resultData.status === 1)
                { 
                    this.setState({ joinNow: true });
                    this.componentDidMount();
                    this.setState({ 
                       registerDialog: false,
                       name: '',
                       email: '',
                       contact: '',   
                       showSnackbar: true,
                       snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
                       severityColor: 'success',
                       snackBarTimer: '3000', 
                    })
                }
                else{
                    this.setState({noData: "Coming Soon"});
                }
                
            
            })
            .catch(error => {
                console.log(error)
            })  
        }

        // this is for webinar check login to join now button
        checkJoinNow = () => {
            this.checkUserRegister();
            this.getWebinar();
            this.setState({ 
                showComponent: false,
                registerDialog: true,
                name: getName(),
                email: getEmail(),
                contact: getContact(),  
            })
        }
         
        
//   handleClickOpen = () => {
//         if(!isLogin())
//         {
//         this.setState({ showComponent: true })
//         }
//         else{
//         // this.setState({ open: true })
//         this.registerNow();
//         }
//     }*

    handleClickOpen = () => {
        if(!isLogin())
        {
           this.setState({ showComponent: true })
        }
        else{
          // this.setState({ open: true })
           //this.registerNow();
           this.setState({ registerDialog: true})
        }
     }

     registerWebinarClose = () => {
        this.setState({ registerDialog: false, id: '', title: ''})
      }

      
  videoDialog = (e, videoUrl) =>{ 
    if(!isLogin())
    {
       this.setState({ showComponent: true })
    }
    else{
     this.setState({
        dialogOpen: true,
        videoUrl: videoUrl
     })
    }
  }
      
    videoDialogClose = () => {
        //alert("close");
        this.setState({
        dialogOpen: false,
        videoUrl: ''
        })
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
       }

render() {  
        const { classes } = this.props;  
        const points = this.state.eventData.fldt_points;
        const getCurrentTimeStamp = getCurrentYear()+'-'+getCurrentMonth()+'-'+getCurrentDate();
        const currentTime = Moment(getCurrentTime()).format('hh:mm A');
        const getCurrentLocalDate = getCurrentTimeStamp+' '+currentTime;
        const getDbDate = Moment(this.state.eventData.fldv_select_date).format('YYYY-MM-D')+' '+Moment(this.state.eventData.fldt_time).format('hh:mm A');
        return (
        <React.Fragment>  
        <Meta tableName={"webinar"} objectId={this.props.match.params.webinarId} forPage={"inner_page"} />
       {this.state.showComponent?<Sign ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)} />:
       <div>
        <Appbar />
        <div className="sticky-top sign-light">
            <Navmenu />    
            </div>  
        <section className="aboutBox">
            <Container>
                <Row className="d-flex flex-md-row flex-sm-column-reverse flex-column-reverse flex-wrap">
                <Col lg="6" md="12" sm="12" className=" order-lg-1 order-sm-2">
                        <h1>
                        {this.state.eventData.fldv_title} 
                        </h1>
                <List inline>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
            </List>
                <SanitizedHTML allowedAttributes={false} allowedTags={false} html={this.state.eventData.fldv_description} />
                    </Col>
                    <Col lg="6" md="12" sm="12" className="webVideo order-lg-2 order-sm-1">
                        <img src={this.state.eventData.fldv_image_url} className="img-fluid rounded-sm"  alt=""/> 
                        {/* <Video /> */}
                    </Col>
                </Row>

                <Row className="timeBox">
                    <Col lg="3" md="6" sm="6" className="text-center">  
                            <img src={dateImage} className="img-fluid" />
                            <h3>Date</h3>
                            <h4>{Moment(this.state.eventData.fldv_select_date).format('dddd, MMMM Do, YYYY')}</h4>
                            
                    </Col>
                    <Col lg="3" md="6" sm="6" className="text-center">
                            <img src={timeImage} className="img-fluid" />
                            <h3>Time</h3>
                            <h4>{Moment(this.state.eventData.fldt_time).format('hh:mmA')}</h4>
                            
                            
                                
                    </Col>
                    <Col lg="3" md="6" sm="6" className="text-center">
                    <img src={durationImage} className="img-fluid" />
                            <h3>Duration</h3>
                            <h4>{this.state.eventData.fldv_duration} Hours</h4>
                    </Col>
                    <Col lg="3" md="6" sm="6" className="text-center">
                    <img src={priceImage} className="img-fluid" />
                            <h3>Price</h3>
                            <h4>{this.state.eventData.fldv_price}</h4>
                    </Col>
                    
                </Row>
                
            </Container>  
        </section>

            <section className="register">
                <Container>
                    <Row>
                    <Col sm="12" className=" mt-4 d-flex flex-wrap justify-content-center"> 
                   {/*{
                        this.state.joinNow?
                        <Button className="CheckButton1 text-center"> Join Now </Button>:
                        <Button onClick={(e) => this.registerNow(e)} className="CheckButton1 text-center"> Register Now </Button>
                    }*/}

                    { /*this.state.checkJoinNow?
                        <Button className="CheckButton1 text-center"> Check Join Now </Button>:
                        <Button onClick={(e) => this.registerNow(e)} className="CheckButton1 text-center"> Check Register Now </Button>
                    */}
                   {/*{getCurrentLocalDate} {getDbDate}*/}
                   {
                       Moment(getCurrentLocalDate).isSameOrAfter(getDbDate, 'date-time')?<a href={this.state.eventData.fldv_webinar_url} target="_blank"><Button className="CheckButton1 text-center"> Join Now </Button></a>:this.state.joinNow?
                       
                       <Button className="CheckButton1 text-center"> You Have Already Registered </Button>:
                       <Button className="CheckButton1 text-center" onClick={this.handleClickOpen}>
                          Register Now
                       </Button>
                    }

                    {/* {(this.state.eventData.fldv_video_link)?<Button className="CheckButton1 text-center" onClick={(e) => this.videoDialog(e, this.state.eventData.fldv_video_link)}> Watch Now </Button>:""}
                     */}
                    {/*{this.state.joinNow?
                        <Button className="CheckButton1 text-center"> You Have Already Register </Button>:
                        <Button className="CheckButton1 text-center" onClick={this.handleClickOpen}>
                           Register Now
                        </Button>
                     }*/}
                    </Col>
                    </Row>
                </Container>
            </section>

            <section className="joinWebinar">
            
            <Container>
                <Row className="webinarBox">
                    <Col sm="12">
                            <h1>Reasons <br /> To Join My Webinars</h1>
                    </Col>
                    <Col md="8" className="mx-auto">
                        <Row> 
                            {this.state.points.map((arrPoints, index) => {  
                               return <Col sm="6" key={index}>
                                    <div className="d-flex">
                                        <div className="iconCheck">
                                        <CheckIcon className="check"/>    
                                        </div>
                                        <div>
                                            <h3>Point {index+1}</h3>
                                            <p>{arrPoints.point}</p>
                                        </div>
                                    </div>
                                </Col>
                            })}
                            
                        </Row>
                    </Col>
                </Row>
                
            </Container>
            </section>  
            <section>
                <Container>
                <Row className="Testimonial speakBox">
                <Col col="sm-12" className="text-center">
                    <h1>Our Speakers</h1>  
                </Col>
                <Col col="sm-12" className="TestBox">  
                <Card.Deck mb="3" className="justify-content-center">  
               {this.state.speakers.map((arrSpeaker, index) => {
                   return <Card  className="col-lg-3 col-md-6 col-sm-10 p-0" key={index}>
                            <img src={arrSpeaker.speaker_profile_image} className=" mx-auto" alt="" />
                            <Card.Body className="text-center" > 
                            <Card.Title>{arrSpeaker.speaker_name}</Card.Title> 
                            {/* <Card.Subtitle mb="2">{arrSpeaker.fldv_email}</Card.Subtitle> */}
                        </Card.Body>
                        </Card>
                })}
                 
                </Card.Deck>
                
                </Col>
                </Row>
                </Container>
            </section>
        <Footer />
        <Dialog onClose={(e) => this.registerWebinarClose(e)} aria-labelledby="customized-dialog-title" open={this.state.registerDialog}>
                        <DialogTitle id="customized-dialog-title" onClose={(e) => this.registerWebinarClose(e)}>
                           {this.state.eventData.fldv_title}
                        </DialogTitle>
                        <DialogContent>
                           {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
                              <Grid container>
                              <Grid item xs={12} sm={12}>  
                                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="name" label="Name" onChange={(e) => this.handleChange(e)} value={this.state.name} variant="outlined" size="small" />
                              </Grid> 
                              <Grid item xs={12} sm={12}>  
                                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="Email" label="Email" onChange={(e) => this.handleChange(e)} value={this.state.email} variant="outlined" size="small"/>
                              </Grid>
                              <Grid item xs={12} sm={12}>  
                                    <TextField fullWidth id="standard-basic" type="text" className="mt-4" name="Contact" label="Contact" onChange={(e) => this.handleChange(e)} value={this.state.contact} variant="outlined" size="small"/>
                              </Grid>
                              <Grid item xs={12} sm={12}>  
                                 <Button onClick={(e) => this.registerNow(e)} type="submit" fullWidth variant="contained" color="primary" className="mt-4 text-capitalize" >Submit</Button>
                              </Grid>
                              </Grid>
                           {/*</form>*/}
                        </DialogContent>
                        <DialogActions>
                        </DialogActions>
                  </Dialog>
                  
                  <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                     <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                     { this.state.snackBarMessage }
                     </Alert>
                  </Snackbar>
                  
                    <Video_dialog dialogOpen={this.state.dialogOpen} videoUrl={this.state.videoUrl} onClose={(e) => this.videoDialogClose(e)} />

        </div>
            }
        </React.Fragment>
        );
    }
}

 
export default withStyles(styles) (Webinar);