import React, { Component } from 'react'; 
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { apiURL } from '../utils';
import axios from 'axios';   
import Alert from '@material-ui/lab/Alert'; 
import { FormGroup, FormControlLabel, Snackbar, DialogTitle, TextField, Button, Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';   
import DialogContent from '@material-ui/core/DialogContent'; 
import LockOpenIcon from '@material-ui/icons/LockOpen';

const forgotPasswordSchema = yup.object().shape({
    email: yup.string().required("This field is required.").email(), 
}); 


class ForgotPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            dialogOpen: false,
            emailError:false,
            emailErrorMsg: "",
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            snackBarTimer: ''
        }
        this.forgotPassword = this.forgotPassword.bind(this);
        this.forgotPasswordDialogClose  = this.forgotPasswordDialogClose.bind(this);
    }

    componentDidMount(){

    }

    forgotPassword = (e) => {
        this.setState({
            dialogOpen: true
        })
    }

    forgotPasswordDialogClose = (e) => {
        this.setState({
            dialogOpen: false,
            emailError: false,
            emailErrorMsg:""
        })
    }

    
check_email = (email) =>{
    if(email){
    if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)){
    const headers = {
      'content-type': 'multipart/form-data',
      'Accept': 'application/json'
      }
      const body = {"email": email}
      axios.post(apiURL('register/check_email'), body, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === false || resultData.status === 0)
          { 
             this.setState({ emailError: true, emailErrorMsg:"this email address is not registered to a NAVदिशा account"});
          } 
          else{
            this.setState({ emailError: false, emailErrorMsg: ""});
          }
        
      }) 
    }
    else{
      this.setState({ emailError: true, emailErrorMsg:"Please enter valid email address"});
    }
    } 
   }

   
    onSubmit = (values) => {  
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
        const body = { "email": values.email }
        axios.post(apiURL('register/forgot_password'),body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {  
            this.setState({ 
                snackBarMessage:resultData.message,
                showSnackbar: true, 
                severityColor:'success',
                snackBarTimer: 6000,
                dialogOpen: false,
                emailError: false,
                emailErrorMsg:""
                });
            }
            else if(resultData.status === 'email_not_exist')
            {
                this.setState({ 
                    emailError: true,
                    emailErrorMsg:resultData.message
                });
            }
            else{
            this.setState({ snackBarMessage: resultData.message,showSnackbar: true , severityColor:'error', snackBarTimer: 6000});
            }  
        
        })
        .catch(error => {
            console.log(error)
        })   
    }

    
    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ showSnackbar: false }); 
    }
  

    render(){
        return(
            <React.Fragment>
                <div onClick={(e) => this.forgotPassword(e)} className="mt-2 pt-md-2 pt-0 col-md-12 col-sm-12 pl-0 passwordBox" >
                <h6 > <LockOpenIcon  style={{ fontSize:"1rem" }}/> Forgot Password</h6>
                </div>

                <Dialog  fullWidth={true} maxWidth={"sm"} open={this.state.dialogOpen} onClose={(e) => this.forgotPasswordDialogClose(e)}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="boxMain">  
                    <DialogTitle id="max-width-dialog-title">  Forgot Password</DialogTitle>
                    <DialogContent className="dialouge">
                        <Formik
                            initialValues={{
                                email: "", 
                            }}
                            validationSchema={forgotPasswordSchema}
                            onSubmit={values => {
                                if(!this.state.emailError)
                                { 
                                    this.onSubmit(values);
                                } 
                            }}
                            >
                            {({ errors, handleChange, touched }) => ( 
                                <Form style={{ display: 'flex',padding:"0px 25px 25px 25px"}} id="forgot-password"> 
                                <Grid container>
                                    <Grid item xs={12} sm={12}>  
                                        <TextField variant="outlined" fullWidth id="standard-basic" type="text" className="mt-4" name="email" label="Enter Email" onChange={handleChange} onBlur={(e) => this.check_email(e.target.value)} onMouseDown={(e) => this.check_email(e.target.value)}  value={this.state.email} error={errors.email && touched.email || this.state.emailError} helperText={errors.email && touched.email ? errors.email : null || this.state.emailErrorMsg} />
                                    </Grid> 
                                    <Grid item xs={12} sm={12}>  
                                     
                                        <Button type="submit" fullWidth variant="contained" color="primary" className="mt-4 text-capitalize" >Submit</Button>
                                    </Grid>
                                </Grid>
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                </Dialog>
            
                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    }
}

export default ForgotPassword 