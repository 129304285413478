import React, { Component } from 'react';  
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField'; 
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
//import Input from "@material-ui/core/Input";  
//import MenuItem from "@material-ui/core/MenuItem";
//import Select from "@material-ui/core/Select";
import { apiURL } from '../utils'; 
import axios from 'axios';   
import { Grid, Snackbar} from '@material-ui/core';   
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';  
import Alert from '@material-ui/lab/Alert'; 

//const FormControl = require('@material-ui/core/FormControl').default;
//const FormHelperText = require('@material-ui/core/FormHelperText').default;
//const InputLabel = require('@material-ui/core/InputLabel').default;
const styles = theme => ({
root: {
   '& > *': {
      margin: theme.spacing(0),
      width: '100%',
   },
   },
});


const formSchema = yup.object().shape({
   name: yup.string().required("This field is required."),
   contact: yup .string().required("This field is required."),
   email: yup
     .string()
     .email()
     .required("This field is required."),
     subject: yup.string().required("This field is required."),
     message: yup.string().required("This field is required."),
 }); 

class  ContactForm extends Component { 
      
   _isMounted = false; 
   constructor(props){
      super(props);
      this.state = {  
         returnMessage: '',
         showSnackbar: false,
         snackBarMessage: '',
         severityColor:'',
         reload: false,
         contact_contactError: false,
         contact_contactErrorMsg: ''
      } 
   }

   componentDidMount()
   {
      this._isMounted = true; 
   } 

   componentWillUnmount() {
      this._isMounted = false; 
   }

   onSubmitContactUsForm = (values) => { 
      const headers = {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json'
      }
      axios.post(apiURL('contact_us_enquiry/add'),values, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          {
              this.setState({ snackBarMessage: "Thank you for details ! Will Contact you soon",showSnackbar: true, severityColor:'success' });
          }
          else{
            this.setState({ snackBarMessage: "Server error! Please Try after some time",showSnackbar: true , severityColor:'error'});
          } 
          this.setState({ name: "", email: "", contact:"", subject:"", message:""}); 
      })
      .catch(error => {
          console.log(error)
      })  
  }

  snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
       return;
     }
     this.setState({ showSnackbar: false }); 
   }
 
   contactNumberValidate = (e) => {  
      const re = /^[0-9\b]+$/;
      if (!re.test(e.target.value)) { 
          this.setState({ contact_contactError: true, contact_contactErrorMsg:"This field accept only digit."}); 
      } 
      else{ 
          this.setState({ contact_contactError: false, contact_contactErrorMsg:""}); 
      } 
   }

    
   contactNumberLengtValidate = (e) => {  
      const re = /^[0-9\b]+$/;
      if(!re.test(e.target.value) || e.target.value.length !== 10)
      {
        this.setState({ contact_contactError: true, contact_contactErrorMsg:"Contact number must be 10 digit."}); 
      } 
      else if(re.test(e.target.value) || e.target.value.length === 10)
      {
        //alert(2+'->'+e.target.value.length)
        this.setState({ contact_contactError: false, contact_contactErrorMsg:""}); 
      }
      else{ 
          this.setState({ contact_contactError: false, contact_contactErrorMsg:""}); 
      } 
   }

    
   render() {
   const { classes } = this.props;
   const { selected, hasError , selected1} = this.state;
   return (
   <React.Fragment> 
          <Formik
          initialValues={{
            name: "",
            subject: "",
            email: "",
            message: "",
            contact: "",
          }}
          validationSchema={formSchema}
          onSubmit={values => { 
            if(!this.state.contact_contactError)
            {
               this.onSubmitContactUsForm(values)
               //alert(JSON.stringify(values));
            }
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form id="contact-us-form" className="careerForm feedBackForm commentForm" noValidate autoComplete="off">
         <Grid container spacing={1}> 
            <Grid item xs={12} sm={12} md={6}>
               <TextField id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="name" label="Name" value={this.state.name}  error={errors.name && touched.name} onChange={handleChange}/>
               {errors.name && touched.name ? errors.name : null} 
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
               <TextField id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="email" label="Email" value={this.state.email}  error={errors.email && touched.email}  onChange={handleChange}/>
               {errors.email && touched.email ? errors.email : null} 
            </Grid>
            
            <Grid item xs={12} sm={12} md={6}>
               <TextField id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="contact" label="Contact Number" value={this.state.contact}  error={errors.contact && touched.contact || this.state.contact_contactError} onChange={handleChange}  onInput={(e) => this.contactNumberValidate(e)} onBlur={(e) => this.contactNumberLengtValidate(e)} inputProps={{ maxLength: 10 }} />
               {errors.contact && touched.contact ? errors.contact : null || this.state.contact_contactErrorMsg}  
            </Grid>
         
         
            <Grid item xs={12} sm={12} md={6}>
               <TextField id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="subject" label="Subject" value={this.state.subject}  error={errors.subject && touched.subject} onChange={handleChange}/>
               {errors.subject && touched.subject ? errors.subject : null}  
            </Grid>

            <Grid item xs={12} sm={12}>
               <TextareaAutosize
                  rowsMax={4}
                  className="w-100 mt-4 textAreaBox"
                  aria-label="maximum height"
                  placeholder="Message"
                  value={this.state.message}
                  name="message"
                 
                  onChange={handleChange}
                  />
                   {errors.message && touched.message ? errors.message : null}
            </Grid> 
            <Grid item xs={12} sm={12} > 
               <Button type="submit"    className="CheckButton text-center mt-3 mx-auto px-4" >
               Send Now
               </Button>
            </Grid>
         </Grid>
         </Form>
          )}
        </Formik>
        <Snackbar open={this.state.showSnackbar === true} autoHideDuration={6000} onClose={this.snackBarClose}>
            <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
            { this.state.snackBarMessage }
            </Alert>
         </Snackbar>
   </React.Fragment>
   );
   }
}
ContactForm.propTypes = {
classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ContactForm);      