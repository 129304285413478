import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/pastraining.scss";
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Link } from 'react-router-dom';  
import { apiURL, isLogin } from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';   
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from "@material-ui/core/styles";
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import WebinarForm from '../webinar/WebinarForm'; 
import Sign from '../login/Sign'; 
import Video_dialog from "../Video_dialog"; 
import Meta from '../meta/Meta'; 

const styles = theme => ({
   dialogPaper: {
   // minHeight: '90vh',
   // maxHeight: '90vh',
   borderRadius:'25px',
   maxWidth:'48.75rem',
   },
   });


class Past_webinar extends Component {
   _isMounted = false; 
  constructor(props){
    super(props);
    this.state = {  
        pastEventsList: [],
        noData: '',
        open: false, 
        speakers: [],
        joinNow: false,
        showComponent: false,
        id: '',
        title: '', 
        dialogOpen: false,
        videoUrl: '',
    }
    this.getPastEvents = this.getPastEvents.bind(this); 
  }

  checkJoinNow = () => {
      this.checkUserRegister();
      this.getWebinar();
      this.setState({ showComponent: false })
   }
   
   handleClickOpen = (e, id, title) => {
      if(!isLogin())
      {
      this.setState({ showComponent: true })
      }
      else{
      this.setState({ open: true, id: id, title: title }) 
      }
   }

   checkJoinNow = () => {
      this.getPastEvents(); 
      this.setState({ showComponent: false })
   }

   handleClose = (e) => { 
      if(e === true){
         this.setState({ 
            showSnackbar: true,
            snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
            severityColor: 'success',
            snackBarTimer: '3000', 
         }); 
       }
      this.setState({  open: false   }); 
   }
 

  componentDidMount()
  {
    this._isMounted = true; 
    this.getPastEvents(); 
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
 getPastEvents = () =>{
   const headers = {
     'content-type': 'multipart/form-data',
     'Accept': 'application/json'
     }
     const body = { 
         "status": 1,
         "event_type": "PE",
         "user_id": ''
     }
     axios.post(apiURL('webinar/events'), body, {headers: headers})
     .then(response => {
         const resultData = response.data; 
         //alert(resultData.message);
         if(resultData.status === true || resultData.status === 1)
         { 
             this.setState({ pastEventsList: resultData.result});
         }
         else{
             this.setState({noData: "Coming Soon"});
         }
         
       
     })
     .catch(error => {
         console.log(error)
     })  
  }

  
  snackBarClose = (event, reason) => {
   if (reason === 'clickaway') {
       return;
     }
     this.setState({ showSnackbar: false }); 
  }
  
  videoDialog = (e, videoUrl) =>{
   // alert(videoUrl);
   if(!isLogin())
   {
      this.setState({ showComponent: true })
   }
   else{
    this.setState({
       dialogOpen: true,
       videoUrl: videoUrl
    })
   }
 }

 videoDialogClose = () => {
    //alert("close");
    this.setState({
       dialogOpen: false,
       videoUrl: '',
       controls:true
    })
 }

render() {
   const { classes } = this.props;
return (
<React.Fragment>  
      <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />
       {this.state.showComponent?<Sign ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)} />:
       <div>
   <Appbar />
   <div className="sticky-top sign-light">
      <Navmenu />    
    </div>  
   <section className="BannerBox TrainingBox pastWebinar">
      <div class="sixteen-nine">
         <div class="content text-center text-white invisible">
            <h2>Past Webinars</h2>
            <p className="text-uppercase">Past Webinars TO FEED YOUR BRAIN</p>
         </div>
      </div>
   </section>
   <section class="careerMain eventMain">
      <Container>
         <Row className="Blogbox  careerBox EventBox PastTraing position-relative">
            <h1 className="text-center d-block w-100 position-absolute">Past Webinars</h1>
            {
            this.state.pastEventsList.map((arrList, index) => {
            return  <Col key={index} col="sm-12 md-8 lg-4" className="mx-auto">
            <Link to={"/past-webinar-schedule/"+arrList.fldi_id+"/"+arrList.fldv_title.replace(/ /g, '-').toLowerCase()}>
            <Card>
               <div className="position-relative clearfix">
                  <figure>
                     <figcaption>
                        <div className="dateBox text-center " >
                           <h3>{Moment(arrList.fldv_select_date).format('D')}</h3> 
                           <h5>{Moment(arrList.fldv_select_date).format('MMMM')}</h5> 
                           <h6>{Moment(arrList.fldv_select_date).format('YYYY')}</h6>
                        </div>
                     </figcaption>
                     <div className="imgBoxtraining">
                     <img style={{width:"100%"}} src={arrList.fldv_image_url} className="img-fluid" alt=""/>
                     </div>
                     <figcaption >
                        <div className="SpeakerBox text-center ">
                           <div className="Speaker">
                              <div className="d-block  imgBox">
                                 <img src={arrList.speaker_profile_image} className="img-fluid rounded-circle " alt="" />
                              </div>
                              <h4>{arrList.speaker_name}</h4>
                           </div>
                        </div>
                     </figcaption>
                  </figure>
               </div>
               <Card.Body className="h-100">
                  <Card.Text>
                     <div className="headBox text-center ">
                        <h4 >Topic: {arrList.fldv_title}</h4>
                        {/* <h5>
                           <AccessTimeIcon />
                           {Moment(arrList.fldv_select_date).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}
                        </h5> */}
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer>
               <div className="headBox text-center ">
                          <h5>
                           <AccessTimeIcon />
                           {/*{Moment(arrList.fldv_select_date).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}*/}
                           {Moment(arrList.fldv_select_date).format('dddd, D MMM, YYYY')}, {Moment(arrList.fldt_time).format('LT')}
                        </h5>
               </div>  
               </Card.Footer>
            </Card>
            </Link>
            <div className="d-flex justify-content-center position-absolute boxFeedback">
               <Button className="CheckButton text-center"  onClick={(e) => this.handleClickOpen(e, arrList.fldi_id, arrList.fldv_title)}> Share Feedback </Button>
               {/*<a href={arrList.fldv_video_link} target="_blank">*/}  
               {(arrList.fldv_video_link)?<Button onClick={(e) => this.videoDialog(e, arrList.fldv_video_link)} className="CheckButton text-center"> <VideocamIcon /> Watch Now </Button>:""}
               {/*</a>*/}
            </div>   
            </Col>   
              })
            }   
            <div className="clearfix"></div>
            <Col col="sm-12" className="text-center noFound"> <h3>{this.state.noData}</h3></Col>
         </Row>
      </Container>
   </section>
   <div className="customFooter">
      <Footer />
   </div>
    <Dialog open={this.state.open} onClose={() =>
            this.handleClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }} > 
            <h2 className="text-center  headBoxRegister text-uppercase">Share Feedback</h2>
            <DialogContent>
               <WebinarForm webinarId={this.state.id} title={this.state.title} formClose={(e) =>this.handleClose(e)} />
            </DialogContent> 
         </Dialog>
         <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
            <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
            { this.state.snackBarMessage }
            </Alert>
         </Snackbar>

         <Video_dialog dialogOpen={this.state.dialogOpen} videoUrl={this.state.videoUrl}  onClose={(e) => this.videoDialogClose(e)} />
        </div>
            }
</React.Fragment>
);
}
}

export default withStyles(styles) (Past_webinar);