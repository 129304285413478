import React, { Component } from 'react';  
import {  Row, Col, Container } from 'bootstrap-4-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF , } from '@fortawesome/free-brands-svg-icons';
import {  faTwitter , } from '@fortawesome/free-brands-svg-icons';
import {  faSkype , } from '@fortawesome/free-brands-svg-icons';
import {  faLinkedin , } from '@fortawesome/free-brands-svg-icons';
import Button from '@material-ui/core/Button'; 
import { apiURL } from '../utils'; 
import axios from 'axios'; 
import Moment from 'moment';  
import SanitizedHTML from 'react-sanitized-html';


class Team extends Component {

   _isMounted = false; 
   constructor(props){
     super(props);
     this.state = {  
      teamList: []
     }
     this.teamList = this.teamList.bind(this); 
   }
 
   componentDidMount()
   {
     this._isMounted = true; 
     this.teamList(); 
   }
 
   componentWillUnmount() {
     this._isMounted = false;
   }
 
   
   teamList = () =>{
   const headers = {
     'content-type': 'multipart/form-data',
     'Accept': 'application/json'
     }
     axios.post(apiURL('team/get_team'), {headers: headers})
     .then(response => {
         const resultData = response.data; 
         //alert(resultData.message);
         if(resultData.status === true || resultData.status === 1)
         { 
             this.setState({ teamList: resultData.result});
         }
       
     })
     .catch(error => {
         console.log(error)
     })  
  }

render() {
return (
<React.Fragment>
   <section className="MeetTemBox  ">
      <Container>
         <Row className="clearfix">
            <Col col="sm-12">
            <h2>Meet Our Team</h2>
            </Col>
            {
               this.state.teamList.map((arrList, index) => {
            return <Col col="lg-4 md-6 sm-9" key={arrList.fldi_id} className="team-block mt-md-5 mt-4 mx-sm-auto">
            <div className="inner-box">
               <ul className="social-icons">
                  
                  {arrList.fldv_facebook_url !== ""?<li>
                     <a href={arrList.fldv_facebook_url}>
                        <FontAwesomeIcon icon={faFacebookF} />
                     </a>
                  </li>:""}
                  {arrList.fldv_twitter_url !== ""?<li>
                     <a href={arrList.fldv_twitter_url}>
                        <FontAwesomeIcon icon={faTwitter} />
                     </a>
                  </li>:""}
                  {arrList.fldv_skype_url !== ""?<li>
                     <a href={arrList.fldv_skype_url}>
                        <FontAwesomeIcon icon={faSkype} />
                     </a>
                  </li>:""}
                  {arrList.fldv_linkedin_url !== ""?<li>
                     <a href={arrList.fldv_linkedin_url}>
                        <FontAwesomeIcon icon={faLinkedin} />
                     </a>
                  </li>:""}
               </ul>
               <div className="image">
                  <img src={arrList.fldv_image_url} alt={arrList.fldv_name} />
               </div>
               <div className="lower-content">
                  <h3><a href="#">{arrList.fldv_name}</a></h3>
                  <div className="designation">{arrList.fldv_designation}</div>
               </div>
            </div>
            </Col>  
               })
            }
           {/* <Col col="lg-4 md-6 sm-9 " className="team-block mt-md-5 mt-4 mx-sm-auto">
            <div className="inner-box">
               <ul className="social-icons">
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faFacebookF} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faTwitter} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faSkype} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faLinkedin} />
                     </a>
                  </li>
               </ul>
               <div className="image">
                  <img src="../images/team-2.png" alt="" />
               </div>
               <div className="lower-content">
                  <h3><a href="#">Michael Scott</a></h3>
                  <div className="designation">Ul / Ux Designer</div>
               </div>
            </div>
            </Col>
            <Col col="lg-4 md-6 sm-9 " className="team-block mt-md-5 mt-4 mx-sm-auto">
            <div className="inner-box">
               <ul className="social-icons">
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faFacebookF} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faTwitter} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faSkype} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faLinkedin} />
                     </a>
                  </li>
               </ul>
               <div className="image">
                  <img src="../images/team-3.png" alt="" />
               </div>
               <div className="lower-content">
                  <h3><a href="#">Domina Li</a></h3>
                  <div className="designation">Former Developer</div>
               </div>
            </div>
            </Col>
            <Col col="lg-4 md-6 sm-9 " className="team-block mt-md-5 mt-4 mx-sm-auto">
            <div className="inner-box">
               <ul className="social-icons">
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faFacebookF} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faTwitter} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faSkype} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faLinkedin} />
                     </a>
                  </li>
               </ul>
               <div className="image">
                  <img src="../images/team-1.png" alt="" />
               </div>
               <div className="lower-content">
                  <h3><a href="#">Domina Li</a></h3>
                  <div className="designation">Former Developer</div>
               </div>
            </div>
            </Col>
            <Col col="lg-4 md-6 sm-9 " className="team-block mt-md-5 mt-4 mx-sm-auto">
            <div className="inner-box">
               <ul className="social-icons">
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faFacebookF} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faTwitter} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faSkype} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faLinkedin} />
                     </a>
                  </li>
               </ul>
               <div className="image">
                  <img src="../images/team-2.png" alt="" />
               </div>
               <div className="lower-content">
                  <h3><a href="#">Domina Li</a></h3>
                  <div className="designation">Former Developer</div>
               </div>
            </div>
            </Col>
            <Col col="lg-4 md-6 sm-9 " className="team-block mt-md-5 mt-4 mx-sm-auto">
            <div className="inner-box">
               <ul className="social-icons">
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faFacebookF} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faTwitter} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faSkype} />
                     </a>
                  </li>
                  <li>
                     <a href="#">
                        <FontAwesomeIcon icon={faLinkedin} />
                     </a>
                  </li>
               </ul>
               <div className="image">
                  <img src="../images/team-3.png" alt="" />
               </div>
               <div className="lower-content">
                  <h3><a href="#">Domina Li</a></h3>
                  <div className="designation">Former Developer</div>
               </div>
            </div>
            </Col>*/}
         </Row>
      </Container>
   </section>
</React.Fragment>
);
}
}
export default Team;