import React from "react";
import {InlineReactionButtons} from 'sharethis-reactjs';
import {InlineShareButtons} from 'sharethis-reactjs';
import {StickyShareButtons} from 'sharethis-reactjs';
import {InlineFollowButtons} from 'sharethis-reactjs';
import SanitizedHTML from 'react-sanitized-html'
import Meta from '../meta/Meta'; 
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    InstapaperShareButton,
    InstapaperIcon,
  } from "react-share";

class Share extends React.Component {
 
constructor(props){
    super(props);
}


  render () { 
    return (
      <React.Fragment> 
        {/*<h1>Inline Share Buttons</h1>*/}
       {/*<InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'cta',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'whatsapp',
              'linkedin',
              'telegram',
              'facebook',
              'twitter', 
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 40,             // the size of each button (INTEGER)
 
            // OPTIONAL PARAMETERS
            //url: 'https://www.navdisha.co.in/blogs/4/extra-curricular-activities---the-personality-shaper', // (defaults to current url)
            url: this.props.currentUrl,
            image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
            description: this.props.description,       // (defaults to og:description or twitter:description)
            title: this.props.title,            // (defaults to og:title or twitter:title)
           // message: this.props.title,     // (only for email sharing)
           // subject: this.props.title,  // (only for email sharing)
           // username: 'arun@beeonline.co.in' // (only for twitter sharing)
          }}
        />
        <hr />*/}
 
 
 
      <div className="float-right ">  
         {/*<div className="Demo__some-network">
            <InstapaperShareButton
              url={this.props.currentUrl}
              title={this.props.title}
              className="Demo__some-network__share-button"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>*/}

          <div className="Demo__some-network float-right ml-2">
            <TwitterShareButton
              url={this.props.currentUrl}
              title={this.props.title}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="Demo__some-network float-right ml-2">
            <LinkedinShareButton url={this.props.currentUrl} className="Demo__some-network__share-button">
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
          
          <div className="Demo__some-network float-right ml-2">
            <TelegramShareButton
              url={this.props.currentUrl}
              title={this.props.title}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>
        <div className="Demo__some-network float-right ml-2">
        <FacebookShareButton
            url={this.props.currentUrl}
            quote={this.props.title}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div> 
          <div className="Demo__some-network float-right ml-2">
        <WhatsappShareButton
            url={this.props.currentUrl}
            title={this.props.title}
            separator=":: "
            className="Demo__some-network__share-button "
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          </div>   
          </div>   
 
      </React.Fragment>
    );
  }
};
 
// export
export default Share;