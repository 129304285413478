import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import "../style/Appbar.scss";  
import Button from '@material-ui/core/Button';  
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import { isLogin, getName } from '../utils'; 
import { Link, useHistory  } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../images/navdisha.png'; 
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Navmenu from '../components/Navmenu';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Tooltip } from '@material-ui/core';




const useStyles = makeStyles((theme) => ({
  popover:{
       maxWidth:'300px',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {

     
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  
const history = useHistory();

const handleLogOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.localStorage.clear();
    
    handleMenuClose();
    history.push("/");
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}  
     className="profileMenu"
     variant="popover"
    >
      <MenuItem onClick={handleLogOut}>  
         Logout 
         <ExitToAppIcon className="ml-2 exitIcon" />
      </MenuItem>
      {/*<MenuItem onClick={handleMenuClose}>Profile </MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'right', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className="profileMobileMenu"
    >

<MenuItem>
{isLogin()?
            <Button  onClick={handleProfileMenuOpen}> {getName()} </Button>:
            <Link to={'/sign'}><Button> SIGN IN </Button></Link>
        }


</MenuItem>

      <MenuItem>
      <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          {/* <Button variant="contained" color="primary" >
            Open Popover
          </Button> */}
          <div className="notificationBox" {...bindTrigger(popupState)}>
           <IconButton aria-label="show 17 new notifications" color="inherit" >
             <Badge badgeContent={4} color="secondary">
               <NotificationsIcon />
             </Badge>
           </IconButton>

           </div>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'right',
              horizontal: 'right',
            }}   
          >
            <Box p={0}>
             <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Lorem Ipsum has been the industry's  standard dummy text ever sinces" secondary="Jan 9, 2014" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Lorem Ipsum has been the industry's standard dummy text ever since" secondary="Jan 7, 2014" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BeachAccessIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Vacation" secondary="July 20, 2014" />
      </ListItem>
    </List>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>

      </MenuItem>
    
    </Menu>
  );


  useEffect(() => {
    ReactGA.initialize('UA-179012541-1'); 
    ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log('this url '+window.location.pathname + window.location.search);
  }, [])

  return (
    <div className={classes.grow} className="mobileSticky   pb-3 sticky-top">
      {/* <AppBar position="static"  className="customBar1 sticky-top"> */}
      <AppBar position="static"  className="customBar1 sticky-top">  
      <Row className="mx-0">
      <Col>  
      <Toolbar  className="px-0">
         {/* <img src="../assets/images/logo.png" className="img-fluid logoBox"  />  */}
        
          <div className="d-flex justify-content-between w-100">
          <div>
          <Link to={'/'}>  
         <img src={logo} className="img-fluid logoBox" />
         </Link>  
          </div>
          <div> <Navmenu /> </div>    
          <div>

            
         <div className={classes.grow} />
         <div className={classes.sectionDesktop}>   

          {isLogin()?
            <Button  onClick={handleProfileMenuOpen}><PersonAddIcon className="d-block d-lg-none "/> <span className="d-none d-lg-block"> {getName()}</span></Button>:
            <Tooltip title={"Existing Users"} >
            <Link to={{
              pathname: '/sign', 
              state: { value: 0 }
            }}><Button> <PersonAddIcon className="d-block d-lg-none "/>  <span className="d-none d-lg-block"> SIGN IN </span> </Button></Link>
            </Tooltip>
        }
        {/* <Button  onClick={handleProfileMenuOpen}>
           SIGN IN
         </Button>*/}
         {/* <Button> <PersonAddIcon className="d-block d-md-none "/> <span className="d-none d-md-block"> SIGN IN </span> </Button> */}

           <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          {/* <Button variant="contained" color="primary" >
            Open Popover
          </Button> */}
          <div className="notificationBox" {...bindTrigger(popupState)}>
           <IconButton aria-label="show 17 new notifications" color="inherit" >
             <Badge badgeContent={0} color="secondary">
               <NotificationsIcon />
             </Badge>
           </IconButton>

           </div>

         {/* <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'right',
              horizontal: 'right',
            }}

          >
             <Box p={0}>
             <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Lorem Ipsum has been the industry's  standard dummy text ever sinces" secondary="Jan 9, 2014" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Lorem Ipsum has been the industry's standard dummy text ever since" secondary="Jan 7, 2014" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BeachAccessIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Vacation" secondary="July 20, 2014" />
      </ListItem>
    </List>
            </Box>
          </Popover>*/}
        </div>
      )}
    </PopupState>


         </div>
         {/* <div className={classes.sectionMobile}>
           <IconButton
             aria-label="show more"
             aria-controls={mobileMenuId}
             aria-haspopup="true"
             onClick={handleMobileMenuOpen}
           >
             <MoreIcon />
           </IconButton>
         </div> */}

          </div>
          </div> 
        
        

       </Toolbar>
       </Col>
      </Row> 
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
