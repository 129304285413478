import React,  {Component} from 'react';
/*import ReactDom from 'react-dom';*/
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';  
import Footer from '../components/Footer';
import {  Container,  Row, Col } from 'bootstrap-4-react';  
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab"; 
import "../style/Sign.scss"; 
import SignIn from './SignIn';
import Signup from './Signup';
/*import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';*/
import { apiURL, login } from '../utils'; 
import axios from 'axios';  
/*import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';*/
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
/*import { Grid } from "@material-ui/core";
import { faTumblrSquare } from '@fortawesome/free-brands-svg-icons';*/
import signlogoImage from '../images/navdisha.png';
import ForgotPassword from './ForgotPassword';
import { Tooltip, Typography } from '@material-ui/core';

const styles = theme => ({
    bigIndicator: {
      height: 0,
    },
  });


class Sign extends Component { 
    //state = { value: 0 }
    _isMounted = false; 
    constructor(props){
      super(props);
      this.state = {
        //value: (this.props.location)?this.props.location.state.value:0,
        value: 0,
        showTexts: true,
        googleResponse: [],
        facebookResponse: [],
        socialResponse:[],
        
      showSnackbar: false,
      snackBarMessage: '',
      severityColor: '',
      snackBarTimer: ''
      }
    }
 
    componentDidMount()
    {
      
      if(window.innerWidth < '900')
      {
        //alert('< 900'+window.innerWidth);
        window.scrollTo(329, 329);
      }
      else{
        //alert('> 900'+window.innerWidth);
        window.scrollTo(420, 420);
      }
      //window.scrollTo(420, 420);
      this._isMounted = true; 
      window.addEventListener('scroll', this.signNav)
     /* if(this.props.pageProp)
      {
      console.log('root props '+JSON.stringify(this.props.pageProp.history.location.pathname)); 
      }*/

    if(this.props.location && !window.location.search)
      {
        this.setState({
          value: this.props.location.state.value
        })
      } 

      if(window.location.search)
      {
        //alert(true)
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const authEmail = urlParams.get('auth')
          //alert(atob(decodeURIComponent(authEmail)));
          //const decodeAuthEmail = atob(decodeURIComponent(authEmail));
          this.authenticateEmail(authEmail);  
          
      }
 
    }
 
 
    authenticateEmail = (email) => {
      const headers = { 
      'Content-type': 'application/json'
      }
      const body = { 
          "email": email, 
      }
      axios.post(apiURL('authenticate'), body, {headers: headers})
      .then(response => { 
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          { 
            this.setState({ 
              snackBarMessage: "Email address verified successfully. Please login",
              showSnackbar: true , 
              severityColor:'success', 
              snackBarTimer: 3000,
              value: 0,
            }); 
          } 
      })
     }
  

    componentWillUnmount() {
      this._isMounted = false; 
    }



  signNav = () => {
    //console.log(window.scrollY)
    if (window.scrollY >= 0) { 
    //alert('if '+window.scrollY)
        this.setState({ showTexts: false });
    }  
    else  {
      
    //alert('else '+window.scrollY)
      this.setState({ showTexts: true });
    }
  }

  handleChange = (event, value) => {
    //alert(value);
    this.setState({ value }); 
  };

  responseGoogle = (response) => {  
    const body = {
      "name":response.profileObj.name, 
      "email": response.profileObj.email,
      "source": "gmail"
    } 
   return this.loginWithSocial(body); 
    
}

responseFacebook = (response) => {
  //console.log('facebook response', response);
  const body = {
    "name":response.name, 
    "email": response.email,
    "source": "facebook"
  } 
  return this.loginWithSocial(body); 
}


loginWithSocial = (socialData) =>{
  const headers = {
    'content-type': 'multipart/form-data',
    'Accept': 'application/json'
    }
    const body = {"email": socialData.email}
    axios.post(apiURL('register/check_email'), body, {headers: headers})
    .then(response => {
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
          this.setState({ emailError: true, emailErrorMsg:resultData.message});
          localStorage.setItem('user_detail', JSON.stringify(resultData.result));
          localStorage.setItem('login_status', true); 
          if(this.props.pageProp)
          {
            //alert(JSON.stringify(this.props.pageProp.history.location.pathname));
            const path = this.props.pageProp.history.location.pathname; 
            this.props.pageProp.history.push(path);
            if(this.props.checkJoinNow) // this prop send from webinar as per require
            {
              this.props.checkJoinNow(true);
            }
          }
          else{
            this.props.history.push('/');
          }
        } 
        else{ 
          this.setState({value:1, socialResponse: socialData});
        }
      
    })

 }

 getSignUpState = () => {
   this.setState({value: 0});
   this.setState({ 
      snackBarMessage: "Please confirm your email id by clicking on the Activation link shared on your email.",
      showSnackbar: true , 
      severityColor:'success', 
      snackBarTimer: 10000});
 }

snackBarClose = (event, reason) => {
  if (reason === 'clickaway') {
      return;
    }
    this.setState({ showSnackbar: false }); 
}

// this is for webinar check login to join now button
checkJoinNow = () => { 
  this.props.checkJoinNow(true);
}

  render() { 
      const { classes } = this.props;
      const {value} = this.state
       
      return (
          <React.Fragment>   
          <Appbar />  
          <div className={(this.state.showTexts ? ' signNav ' : ' sticky-top sign-light ')}>
            <Navmenu />    
          </div>  
          <section className="logInBox">
          <Container>
          <Row>
          <Col sm="12" md="8" className="mx-auto mainBox  outLine ">
          <Row>
          <Col sm="12">
            <div position="static" key="appbar" className="top text-center">
              <div className="pt-3 signMainbox px-md-5 mt-3">
                <img src={signlogoImage} alt="" className="mt-5" />
                <h2 className="text-uppercase">{(this.state.value === 0)?"SIGN IN":"SIGN UP"}</h2>   
               {/*{(this.state.value === 0)?<p>Sign In to begin with the new learnings and techniques.</p>:
                <p>By making Registration to the portal you can avail benefits of getting updated with the new educational system and get trained with various different techniques of teaching.</p>}*/}
              </div>
              <Tabs classes={{ indicator: classes.bigIndicator }} value={this.state.value} onChange={this.handleChange} > 
                  <Tab className="blueButton text-capitalize" 
                  label={
                      <div>
                        <Typography> Sign In </Typography>
                        <Typography style={{fontSize:"12px"}}> (Existing Users) </Typography>
                      </div>
                    } 
                  />  
                  <Tab className="blueButton  text-capitalize"
                  label={
                      <div>
                        <Typography> Sign Up </Typography>
                        <Typography style={{fontSize:"12px"}}> (New Users) </Typography>
                      </div>
                    } 
                  /> 
              </Tabs>
            </div>
          </Col>
          </Row>
          </Col>
          <Col sm="12" md="9" className="mx-auto mainBox">
            <Row>
              <Col sm="12" key="tab-content" className="tabBox">
              {this.state.value === 0 && <Row>
                <Col sm="12" className="formMainBox">
                  <SignIn  propsData={(this.props.pageProp)?this.props.pageProp:this.props} checkJoinNow={(e) => this.checkJoinNow(e)}/>  
                    
                    {/* <div className="row">
                      <div className="mt-4 col-md-5 col-sm-12 ">
                          <GoogleLogin
                            clientId="621490980909-pnnsqoultsfqe26auv5784f2k7n9n5pq.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                            //clientId="492751910892-q3phq1490s26bl9vi1q7im234e1f32h0.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                            buttonText="LOGIN WITH GOOGLE"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            className="googleBox"
                            cookiePolicy={'single_host_origin'}
                          /> 
                      </div>
    
                    <div className="mt-3 pt-md-3 pt-0 col-md-2 col-sm-12 text-center orBox" >
                         <h3>OR</h3>
                    </div>

                    <div className="mt-md-4 mt-2 col-md-5 col-sm-12 ml-md-auto ">
                         <FacebookLogin
                          //appId="622648051623600" //APP ID NOT CREATED YET
                          appId="3181292698636480"
                          fields="name,email,picture"
                          callback={this.responseFacebook}
                        />   
                    </div>
                </div> */}
                
                {/* start forgot password form */}
                  <ForgotPassword /> 
                {/* end forgot password form */}

                </Col> 

                </Row>      
              }
              {this.state.value === 1 &&  <Row>
                <Col sm="12" className="formMainBox">
                  <Signup getSocialResponse={this.state.socialResponse} setState={(e) => this.getSignUpState(e)} propsData={this.props}/>    
                </Col>
              </Row>}
              </Col>
            </Row>
          </Col>
          </Row>
          <Snackbar  anchorOrigin={{  vertical: "top", horizontal: "center" }}  open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                { this.state.snackBarMessage }
                </Alert>
            </Snackbar>
          </Container>
          </section>             
          <Footer />    
         
          </React.Fragment>
      );
    }
}

export default withStyles(styles)(Sign);

