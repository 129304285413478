import React,  {Component} from 'react'; 
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';  
import Footer from '../components/Footer';
import {  Container,  Row, Col } from 'bootstrap-4-react';    
import { withStyles } from "@material-ui/core/styles";
import { Card } from 'bootstrap-4-react'; 
import "../style/Discussion.scss";   
import Autocomplete from '@material-ui/lab/Autocomplete';   
import { Editor } from '@tinymce/tinymce-react'; 
import { Button, DialogActions, DialogContent, DialogTitle, TextField, Grid, FormControl,InputLabel, Select, MenuItem, FormHelperText, Snackbar}  from '@material-ui/core';
import { apiURL, getID } from '../utils'; 
import axios from 'axios';   
import Alert from '@material-ui/lab/Alert';  
import Sign from '../login/Sign';  
 

   const styles = theme => ({
      bigIndicator: {
         height: 0,
      },
   });
 
class CreateThread extends Component { 
      
   _isMounted = false;  
   constructor(props){
      super(props);
      this.state = {
         value: 0,   
         title:'',
         category:'',
         getCategory: [],
         defaltValues: [],
         hashTagValue: [],
         selectedTags: [],
         editorValue:'',
         editorValid: false,
         fieldName: '',
         errorMsg: '',
         showSnackbar: false,
         snackBarMessage: '',
         severityColor: '',
         snackBarTimer: ''
      }
      this.categoryChangeHandler  = this.categoryChangeHandler.bind(this);
      this.titleChangeHandler     = this.titleChangeHandler.bind(this);
      this.tagChangeHandler       = this.tagChangeHandler.bind(this);
      this.onSubmitForm           = this.onSubmitForm.bind(this);
      this.categoryList           = this.categoryList.bind(this);
      this.tagList                = this.tagList.bind(this);
      this.handleEditorChange     = this.handleEditorChange.bind(this);
      this.handleKeyDown          = this.handleKeyDown.bind(this);

      this._sign = React.createRef();
   }

   componentDidMount()
   {
      this._isMounted = true; 
      window.addEventListener('scroll', this.signNav)
      this.tagList(); 
      this.categoryList(); 

      window.scrollTo(0, 0);
   }
       
   categoryList = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('category/get_all_category'), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({getCategory: resultData.result}); 
          }
          else{ 
            this.setState({getCategory: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }

    handleEditorChange = (content) => {  
        this.setState({
          editorValue: content,
          editorValid: true,
          errorMsg: "",
          fieldName:false
        });
    }
      
   componentWillUnmount() {
      this._isMounted = false; 
   }

   handleChange = (event, value) => {
      this.setState({ value }); 
   };

   tagList = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('tags/get_tags'), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({defaltValues: resultData.result}); 
          }
          else{
            console.log('no data')
            this.setState({defaltValues: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }

    handleKeyDown = (event) => {    
      switch (event.keyCode) {
        case 13:
        case 32:
        case 188: {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.value.length > 0) {
            this.setState({hashTagValue:[...this.state.hashTagValue, event.target.value]});  
            this.setState({errorMsg: "", fieldName:false})
          }
          break;
        }
        default:
      }
    };

    tagChangeHandler = (event, value) => { 
      this.setState({hashTagValue:value}); 
    }

    titleChangeHandler = (e) => {   
      this.setState({
        [e.target.name]: e.target.value
      }); 
      if(this.state.fieldName === "title")
      { 
         this.setState({errorMsg: "", fieldName:false}) 
      } 
       
  }

  categoryChangeHandler = (e) => {   
    this.setState({
      [e.target.name]: e.target.value
    }); 
    if(this.state.fieldName === "category")
    {
       this.setState({errorMsg: "", fieldName:false})
    } 
     
}

  onSubmitForm = (e) => {
   e.preventDefault();
   e.stopPropagation();
   const body =  { 
       'title':this.state.title,
       'tags': this.state.hashTagValue,
       'content': this.state.editorValue,
       'category':this.state.category,
       'user_id': getID()
   }
   const headers = {
       'Content-type': 'application/json'
     }
     axios.post(apiURL('forum/add'), body, {headers: headers})
     .then(response => {
         const resultData = response.data;
         
         console.log(resultData)
         if(resultData.status === true)
         {   
           this._isMounted = false;
           this.props.history.push('/thread-answer/'+resultData.result+'/'+this.state.title.replace(/ /g, '-'));
           this.setState({
             title:'',
             hashTagValue:'',
             editorValue:'',
             category:'',
           })
         }
         else{
            this._isMounted = true;
            this.setState({
              fieldName: resultData.field,
              errorMsg: resultData.message,
              showSnackbar: true, 
              snackBarMessage:  resultData.message,
              severityColor: 'error',
              snackBarTimer: 2000
            })
            
         }
     })
     .catch(error => {
         console.log(error)
     })
 }

 snackBarClose = (event, reason) => {
  if (reason === 'clickaway') {
      return;
    }
    this.setState({ showSnackbar: false }); 
}
 

   render() {
   const { classes } = this.props;
   const {value} = this.state

   return (
      <React.Fragment>
        {(!window.localStorage.getItem('login_status'))?<Sign ref={this._sign} pageProp={this.props} />:
        <div>
         <Appbar />
         {/* <div className="NavBar   pb-0 sticky-top ">
            <Navmenu />
         </div> */}
         <div className="sticky-top sign-light">
            <Navmenu />    
        </div>  
         <section className="BannerBox discussionBanner">
            <div className="sixteen-nine">
               <div className="content text-center text-white">
                  <h2>Discussion Board</h2>
                  <p>Lorem ipsume</p>
               </div>
            </div>
         </section>
         <section className="createThread">
            <Container>
               <Row>
                  <Col sm="12" className="discussionMain" >  
                     <Card>
               <Card.Body>
                        <form id="question" onSubmit={(e) => this.onSubmitForm(e)}>
                        <DialogTitle id="form-dialog-title">Create New Thread</DialogTitle>
                        <DialogContent  style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Title" variant="outlined" name="title" onChange={(e) => this.titleChangeHandler(e)} value={this.state.title} error={this.state.fieldName === 'title'?true:false} helperText={this.state.fieldName === 'title'?this.state.errorMsg:null} />
                                </Grid> 
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" style={{width:'100%'}} error={this.state.fieldName === 'category'?true:false}>
                                        <InputLabel>Select Category</InputLabel>
                                        <Select name="category" value={this.state.category} onChange={(e) => this.categoryChangeHandler(e)} label="Select Category">
                                            <MenuItem value=""> <em>None</em> </MenuItem>
                                            {this.state.getCategory.map((arrCategory, index) => {
                                                return <MenuItem key={arrCategory.fldi_id} value={arrCategory.fldi_id}>{arrCategory.fldv_category}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText>{this.state.fieldName === 'category'?this.state.errorMsg:null}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Editor 
                                        initialValue={this.state.editorVlaue} 
                                        init={{
                                        height: 200,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={(e) => this.handleEditorChange(e)}
                                    /> 
                                    {this.state.fieldName === 'content'?this.state.errorMsg:null}
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>   
                                <Autocomplete multiple freeSolo id="tags-outlined"
                                    options={this.state.defaltValues}
                                    getOptionLabel={option => option.title || option}
                                    value={this.state.hashTagValue}
                                    onChange={(event, newValue) => this.setState({hashTagValue:newValue, errorMsg: "", fieldName:false})} 
                                    filterSelectedOptions
                                    renderInput={params => {
                                    params.inputProps.onKeyDown = this.handleKeyDown;
                                    return (
                                        <TextField {...params} variant="outlined" label="Select Tags" placeholder="Choose Tags" margin="normal"  fullWidth
                                        error={this.state.fieldName === 'tags'?true:false}
                                        helperText={this.state.fieldName === 'tags'?this.state.errorMsg:null}
                                        />
                                    );
                                    }}
                                />
                                </Grid>
                                <Grid item xs={12} sm={12}>  
                                <DialogActions className="px-0" >
                                <Button variant="contained" color="secondary">  Cancel  </Button>
                                 <Button type="submit" variant="contained" color="primary" > Submit </Button>
                                </DialogActions> 
                                </Grid>
                             </Grid>
                        </DialogContent>
                     
                    </form> 
                     </Card.Body>
                     </Card>  
                  </Col>
               </Row>
            </Container> 
            <Snackbar open={this.state.showSnackbar} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
         </section>
         <Footer />
         </div>
         }
         </React.Fragment>
      );
   }
}
export default withStyles(styles)(CreateThread);   