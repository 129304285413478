import React, { Component } from 'react';   
import { Row, Col  } from 'bootstrap-4-react';
import axios from 'axios';   
import { apiURL } from '../utils'; 


class Speaker extends Component {
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {
      bestSpeakerData: [],
    }

    this.bestSpeakerList = this.bestSpeakerList.bind(this);
  }

  componentDidMount() { 
    this._isMounted = true; 
    this.bestSpeakerList(); 
    
}

componentWillUnmount() {
    this._isMounted = false; 
}

  bestSpeakerList = () => {
    const headers = { 
        'Content-type': 'application/json'
    }
    axios.get(apiURL('speaker/best_speaker'), {headers: headers})
    .then(response => {
        
    if(this._isMounted){
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({  
              bestSpeakerData: resultData.result,
            });
        }
    }
    })
    .catch(error => {
        console.log(error)
    })
}

    render() {
      return (
        <React.Fragment>
          {/* <Row className="SpeakerBox">
           <Col col="sm-12">
              
               <h2>Best Speakers</h2>
             
           </Col>
           {this.state.bestSpeakerData.map((arrSpeaker, index) => {    
              return  <Col key={arrSpeaker.fldi_id} col="sm-4" className="Speaker">
                       <div className="float-left mr-3 speakImgBox">
                      <img src={arrSpeaker.fldv_image_url} className="img-fluid rounded-circle" alt={arrSpeaker.fldv_name} />
                      </div>
                        <h4>{arrSpeaker.fldv_name}</h4>
                        <h5>Speaker</h5>
                    </Col>
                    {/* <Col col="sm-4" className="Speaker">
                      <img src="../images/speaker-2.png" className="img-fluid float-left mr-3" alt="" />
                      <h4>Roberto</h4>
                        <h5>Speaker</h5>
                    </Col>
                    <Col col="sm-4" className="Speaker">
                    <img src="../images/speaker-3.png" className="img-fluid float-left mr-3" alt="" />
                        <h4>Maria</h4>
                        <h5>Speaker</h5>
                    </Col> 

                  
            })}
         </Row> */}

        

         <Row className="SpeakerBox">
           <Col col="sm-12">
              
               <h2>Best Speakers</h2>
              
           </Col>
           {this.state.bestSpeakerData.map((arrSpeaker, index) => {    
              return  <Col key={arrSpeaker.fldi_id} col="sm-4" className="Speaker">
                       <div className="float-left mr-3 speakImgBox">
                      <img src={arrSpeaker.fldv_image_url} className="img-fluid rounded-circle" alt={arrSpeaker.fldv_name} />
                      </div>
                        <h4>{arrSpeaker.fldv_name}</h4>
                        <h5>Speaker</h5>
                    </Col>
                    {/* <Col col="sm-4" className="Speaker">
                      <img src="../images/speaker-2.png" className="img-fluid float-left mr-3" alt="" />
                      <h4>Roberto</h4>
                        <h5>Speaker</h5>
                    </Col>
                    <Col col="sm-4" className="Speaker">
                    <img src="../images/speaker-3.png" className="img-fluid float-left mr-3" alt="" />
                        <h4>Maria</h4>
                        <h5>Speaker</h5>
                    </Col> */}

                  
            })}
         </Row>
            
        </React.Fragment>
      );
    }
  }

export default Speaker;