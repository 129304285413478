import React, { Component } from 'react';  
import {  Row, Col, Container } from 'bootstrap-4-react';
import { List } from 'bootstrap-4-react';
import teacherInstructor from '../images/bestinstructor.png';
class Teacher extends Component {
render() {
return (
<React.Fragment>
   <Container className="teacherBoxMain ">
      <Row>
         <Col col="md-6 sm-12" className="teacherImage " >
         <img src={teacherInstructor} className="img-fluid imgBox" />  
         </Col>
         <Col col="md-6 sm-12" className="instructBox">
         <div  className="InstructorHead" >
            <h3>Our Best Instructor </h3>
            <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER ADIPISCING ELIT</p>
         </div>
         <List unstyled className="Instructor">
            <List.Item>Willingness to Put in the Necessary Time.</List.Item>
            <List.Item>Love for the Age Group They Teach.</List.Item>
            <List.Item>An Effective Classroom Management Style.</List.Item>
            <List.Item>Positive Relationships with Other Adults.</List.Item>
            <List.Item>Consistent Excellence.</List.Item>
            <List.Item>Expert Use of Instructional Methods.</List.Item>
            <List.Item>In-Depth Content Knowledge.</List.Item>
         </List>
         </Col>
      </Row>
   </Container>
</React.Fragment>
);
}
}
export default Teacher;