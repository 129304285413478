import React, { Component } from 'react';  
import { Container } from 'bootstrap-4-react';   
import Navmenu from '../components/Navmenu';  
import Footer from '../components/Footer';
import "../style/Glimpses.scss";   
import { withStyles } from "@material-ui/core/styles"; 
import Appbar from '../components/Appbar';       
import Button from '@material-ui/core/Button';
/*import Dialog from '@material-ui/core/Dialog';   
import DialogContent from '@material-ui/core/DialogContent';  
import ReactPlayer from 'react-player/lazy';*/ 
import { List } from 'bootstrap-4-react'; 
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import ArrowRightIcon from '@material-ui/icons/ArrowRight';  
import { apiURL, isLogin } from '../utils';    
import axios from 'axios';  
import Video_dialog from "../Video_dialog"; 
import Sign from '../login/Sign'; 




const styles = theme => ({
    dialogPaper: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    borderRadius:'0px',
    maxWidth:'48.75rem',
    width:'60rem',
    //  height:'100%',
    // padding:'0rem',
  },

  dialog:{
     padding:'0rem',
  },

  paper: {
    padding: theme.spacing(0),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    boxShadow:'0px 4px 10px -1px rgba(0,0,0,0.1)',
    height: '100%',
  }, 
  });

  

class VideoGlimses extends Component {

  _isMounted = false; 
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: '', 
      open: false,
      dialogOpen: false,
      isOpen: false, 
      categoryList: [],
      videoList: [],
      categoryID: null,
      noData: '', 
      selectedTab: 0,
      showComponent: false,
    }

    this.openModal      = this.openModal.bind(this);
    this.videoCategory  = this.videoCategory.bind(this);
    this.clickCategory  = this.clickCategory.bind(this);
    this.videoListData  = this.videoListData.bind(this);
   
}

  componentDidMount(){
    this._isMounted = true; 
    this.videoCategory();
    this.videoListData();
  }

  
  checkJoinNow = () => {
    //this.videoCategory();
    //this.videoListData();
    this.setState({ showComponent: false, open:true, videoUrl: this.state.videoUrl})
 }

  videoCategory = () => {
      const headers = { 
        'Content-type': 'application/json'
      }
      axios.get(apiURL('video_category/get_all_video_category'), {headers: headers})
      .then(response => {
          
      if(this._isMounted){
          const resultData = response.data;   
          if(resultData.status === true || resultData.status === 1)
          { 
            this.setState({categoryList: resultData.result})
          }  
      }
      })
      .catch(error => {
          console.log(error)
      })
  } 

  videoListData = () => {
    this.setState({ loading: true }); 
    const headers = { 
        'Content-type': 'application/json'
    }
    const body = { "category_id": this.state.categoryID}
    axios.post(apiURL('video/get_all_video'), body, {headers: headers})
    .then(response => {
        
    if(this._isMounted){
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({  
              videoList: resultData.result, 
              noData: '',
            }); 
        }
        else{
            this.setState({noData: "Coming Soon", videoList:[]}); 
        }
    }
    })
    .catch(error => {
        console.log(error)
    }) 
  }

  componentWillUnmount() {
    this._isMounted = false; 
  }

  clickCategory = (e, id) => {
    //alert(id)
    this.setState({
      categoryID: id,
      selectedTab: id,  
    }, () => { 
      this.videoListData();  
    }); 
  }
 
  handleClickOpen = (e,videoUrl) => {
    if(!isLogin())
    {
    this.setState({ showComponent: true })
    }
    // else{
    this.setState({ open: true, videoUrl: videoUrl }) 
    //} 
  }

  handleClose = () => {
    //this.setState({ open: false, videoUrl:'' })
    this.setState({
      open: false,
      videoUrl: ''
   })
  }

  openModal () {
      this.setState({isOpen: true})
    }


    render() {
        const { classes } = this.props; 
      return (
        <React.Fragment>
          
       {this.state.showComponent?<Sign ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)} />:
       <div>
          <Appbar />
          <div className="sticky-top sign-light">
              <Navmenu />    
          </div>  
        <section className="BannerBox videoBanner d-none">
            <div class="sixteen-nine">
              <div class="content text-center text-white">
                  <h2>Videos</h2>
                  <p>Lorem ipsume</p>
              </div>
            </div>
        </section>

              <section className="videoLightbox">
                <Container>
                  <Grid container spacing={1}  className="">
                  <Grid item xs={12}  className="categoryMenu1 "> 
                  <List inline>
                    <List.Item  inline onClick={(e) => this.clickCategory(e, 0)} className={this.state.selectedTab === 0?"active":''} >All</List.Item>
                    {this.state.categoryList.map((cat, i) => {
                    return <List.Item key={i} className={this.state.selectedTab === cat.fldi_id?"active":''} inline onClick={(e) => this.clickCategory(e, cat.fldi_id)}>{cat.fldv_category}</List.Item>
                    })}
                    </List>  
                  </Grid>
              
                    {this.state.videoList.map((arrVideo, index) => {
                        return <Grid item xs={12} sm={6} md={4} className="mt-4"> 
                        <Paper className={classes.paper}>
                        <Button className="text-center p-0 imgBox" onClick={(e)=>this.handleClickOpen(e, arrVideo.fldv_video)}>
                              <img src={arrVideo.fldv_image_capture} className="img-fluid" alt={arrVideo.fldv_image_capture}/>
                              <div className="boxIcon">
                              <ArrowRightIcon className="playIconBox" />
                              </div>
                        </Button>
                        <div className="px-4 pt-3 pb-3 cardText" >
                          <p>{arrVideo.fldv_title}</p>
                        </div>
                        </Paper>
                      </Grid>
                    })}

                    <Grid item xs={12} className="mt-4 text-center">    
                          <h1>{this.state.noData}</h1>
                    </Grid>
            </Grid>
            </Container>
            </section>
                  
            <Video_dialog dialogOpen={this.state.open} videoUrl={this.state.videoUrl} onClose={(e) => this.handleClose(e)} />

       {/* <Dialog
          open={this.state.open}
          onClose={() =>
          this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialogPaper }}   
          className="boxMain"
          >
              
          <DialogContent className="dialouge">
              <div className='player-wrapper'>
                <ReactPlayer
                url={this.state.videoUrl}
                className='react-player'
                width='100%'
                height='100%'
                config={{ file: { attributes: {
                autoPlay:false,
                muted: false
                }}}}
                />
              </div>
          </DialogContent>
        </Dialog>*/}

        <Footer />
        </div>
      }
        </React.Fragment>
      );
    }
  }


 

  export default withStyles(styles) (VideoGlimses);