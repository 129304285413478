import  React, {Component} from 'react';
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import { List } from 'bootstrap-4-react';    
import { Nav } from 'bootstrap-4-react';
import Button from '@material-ui/core/Button';
import Footer from '../components/Footer';
import Appbar from '../components/Appbar';  
import Navmenu from '../components/Navmenu';
import Divider from '@material-ui/core/Divider';
import "../style/Glimpses.scss";  
import "../style/News.scss";     
import { Media, BImg, BH5, BH6 } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';     
import { apiURL, getID } from '../utils';   
import SanitizedHTML from 'react-sanitized-html';
import axios from 'axios'; 
import Moment from 'moment'; 
import Meta from "../meta/Meta";
import HashLoader from "react-spinners/HashLoader";

class News extends Component {
constructor(props){
   super(props)
   this.state = {
      newsData: [],
      noData: '',
      loading:true
   }
}   

componentDidMount() {
   window.scrollTo(0, 0);
   this._isMounted = true; 
   this.newsListData();
}

componentWillUnmount() {
   this._isMounted = false; 
}


newsListData = () => {
   this.setState({ loading: true }); 
   const headers = { 
       'Content-type': 'application/json'
   }
   const body = { "id": ""}
   axios.post(apiURL('news/get_all_news'), body, {headers: headers})
   .then(response => {
       
   if(this._isMounted){
       const resultData = response.data; 
       if(resultData.status === true || resultData.status === 1)
       { 
           this.setState({  
            newsData: resultData.result, 
            loading: false
           });
           //this.setState({ loading: false  });
       }
       else{
           this.setState({noData: "Coming Soon", loading: false}); 
       }
   }
   })

   .catch(error => {
       console.log(error)
   }) 
}
   
render() {
return (
<React.Fragment>
      <Meta tableName={"menu"} objectId={15} forPage={"main_page"} />
   <Appbar />
   {/* <div className="NavBar  pb-0 sticky-top ">
      <Navmenu />
   </div> */}
   <div className="sticky-top sign-light">
            <Navmenu />    
    </div>  
   <section className="BannerBox discussionBanner">{/* remove d-none for sow*/}
      <div className="sixteen-nine">
         <div className="content text-center text-white">
            {/*<h2>Circulars/News</h2>
            <p>Update</p>*/}
         </div>
      </div>
   </section>

   {/* <Col sm="12" style={{display:"flex", justifyContent:"center"}}>    
      <HashLoader size={150} color={"#123abc"} loading={this.state.loading} css="" />
   </Col> */}

   <section  className="newsBox">
      <Container>
         <Row className="row aricleBox">
         {this.state.newsData.map((arrNews, index) =>
            <Col md="6" sm="12" className="mt-4 mainBox" key={index}>
            <Link target="blank" to={'news/'+arrNews.fldi_id+'/'+arrNews.fldv_title.replace(/ /g, '-').toLowerCase()}>
            <Media className="h-100">
               <div className="imgWidth mr-3" >
                  <BImg src={arrNews.fldv_image_url} alt={arrNews.fldv_title} className="img-fluid " />
               </div>
               <Media.Body>
                  <BH5 mt="0" >{arrNews.fldv_title}</BH5>
                  <BH6> {Moment(arrNews.fldv_selected_date).format('D MMMM, YYYY')}</BH6>
               </Media.Body>
            </Media>
            </Link>
            <Divider />
            </Col>
             )} 

            {/*<Col sm="6" className="mt-4 mainBox">
            <Media>
               <div className="imgWidth mr-3" >
                  <BImg src="images/article-2.png" alt="" className="img-fluid " />
               </div>
               <Media.Body>
                  <BH5 mt="0" >Chinese clients have been released after agreeing to keep</BH5>
                  <BH6> 20 March, 2018</BH6>
               </Media.Body>
            </Media>
            <Divider />
            </Col>
            <Col sm="6" className="mt-4 mainBox">
            <Media>
               <div className="imgWidth mr-3" >
                  <BImg src="images/article-3.png" alt="" className="img-fluid " />
               </div>
               <Media.Body>
                  <BH5 mt="0" >Top aide possible contender forced to resign over creepy</BH5>
                  <BH6> 20 March, 2018</BH6>
               </Media.Body>
            </Media>
            <Divider />
            </Col>
            <Col sm="6" className="mt-4 mainBox">
            <Media>
               <div className="imgWidth mr-3" >
                  <BImg src="images/article-4.png" alt="" className="img-fluid " />
               </div>
               <Media.Body>
                  <BH5 mt="0" >Chinese clients have been released after agreeing to keep</BH5>
                  <BH6> 20 March, 2018</BH6>
               </Media.Body>
            </Media>
            <Divider />
            </Col>
            <Col sm="6" className="mt-4 mainBox">
            <Media>
               <div className="imgWidth mr-3" >
                  <BImg src="images/article-5.png" alt="" className="img-fluid " />
               </div>
               <Media.Body>
                  <BH5 mt="0" >Top aide possible contender forced to resign over creepy</BH5>
                  <BH6> 20 March, 2018</BH6>
               </Media.Body>
            </Media>
            <Divider />
            </Col>
            <Col sm="6" className="mt-4 mainBox">
            <Media>
               <div className="imgWidth mr-3" >
                  <BImg src="images/article-6.png" alt="" className="img-fluid " />
               </div>
               <Media.Body>
                  <BH5 mt="0" >Chinese clients have been released after agreeing to keep</BH5>
                  <BH6> 20 March, 2018</BH6>
               </Media.Body>
            </Media>
            <Divider />
            </Col>*/}
         </Row>
         
         <span>{this.state.noData}</span>
      </Container>
   </section>
   <Footer />
</React.Fragment>
);
}
}
export default News;