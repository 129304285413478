import React, { Component } from "react";
import ReactDom from 'react-dom';
import Bootstrap, { Carousel, BImg } from 'bootstrap-4-react';
import { Button} from '@material-ui/core'
 import "../style/SecondExample.scss"

 import { Link } from 'react-router-dom';  
 import slide5 from '../images/slide-5.png'; 
 import slide1 from '../images/slide-1.jpg'; 
 import slide3 from '../images/slide-3.jpg'; 
 import slide4 from '../images/slide-4.png'; 
 import slide7 from '../images/slide-8.jpg'; 
 import slide6Mobile from '../images/slide-6-devices.png';
 import googleplay from '../images/google-play.png'; 
 import AppleIcon from '@material-ui/icons/Apple';
 import { isLogin } from '../utils'; 

//  import { Button } from 'bootstrap-4-react';
  
// const first_src = 'data:image/svg+xml;charset=UTF-8,' +
//   '<svg%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20width%3D"286"%20height%3D"180"%20viewBox%3D"0%200%20286%20180"%20preserveAspectRatio%3D"none">' +
//   '<g%20fill%3D"rgba(255%2C255%2C255%2C.75)"%20font-family%3D"Helvetica%2C%20monospace"%20font-size%3D"14pt">' +
//   '<rect%20width%3D"286"%20height%3D"180"%20fill%3D"#777"%2F>' +
//   '<g><text%20x%3D"99.4296875"%20y%3D"96.6">First slide<%2Ftext><%2Fg>' +
//   '<%2Fg><%2Fsvg>';

// const second_src = first_src.replace('First', 'Second');
// const third_src = first_src.replace('First', 'Third');



class Carouselslide extends  Component{

  constructor(props){
    super(props);
    this.redirectSignUp = this.redirectSignUp.bind(this);
  }

    componentWillMount() {
        window.setTimeout(() => Bootstrap.carousel('#carouselExampleCaptions'), 6000);
      }

      redirectSignUp = (e) => {
        e.preventDefault();
        this.props.propsData.history.push({
          pathname : '/sign',
          state :{
          value : 1
          }
          } 
        );
      }

    render(){
    return(
        <React.Fragment>
             <Carousel w="100" id="carouselExampleCaptions" className="SecondExample-1">
        <Carousel.Indicators className="d-none">
          <Carousel.Indicator target="#carouselExampleCaptions" to="0" active />
          {/* <Carousel.Indicator target="#carouselExampleCaptions" to="1" />     */}
          {/*<Carousel.Indicator target="#carouselExampleCaptions" to="2" />
          <Carousel.Indicator target="#carouselExampleCaptions" to="3" />
           <Carousel.Indicator target="#carouselExampleCaptions" to="4" /> */}
        </Carousel.Indicators>
        <Carousel.Inner>

        <Carousel.Item active className="Project1">
          <BImg className="d-none d-md-block" w="100" src={slide7}/>    
          <BImg className="d-block d-md-none" w="100" src={slide6Mobile}/>
          <Carousel.Caption className="ContentBox slide6Content">
              {/* <h2>Terrio</h2>
              <h3>Training Program</h3>
              <p>A exciting mobile game game made in the Unity Engine</p> */}
              {/*<Link to="/sign">  <Button className="CheckButton font-weight-bold"> Register Now </Button> </Link>*/}
              {!isLogin()?<Button onClick={(e) => this.redirectSignUp(e)} className="CheckButton font-weight-bold"> Register Now </Button>:<Link to="/about-us"><Button className="CheckButton font-weight-bold"> Read More </Button></Link>}
            </Carousel.Caption>
          </Carousel.Item>

          
        {/* <Carousel.Item  className="Project1">
          <BImg display="block" w="100" src={slide3}/>
          <Carousel.Caption   className="ContentBox slide3Content">
              <h2>Terrio</h2>
              <h3>Training Program</h3>
              <p>A exciting mobile game game made in the Unity Engine</p> 
              <Link to="/sign">
            <Button onClick={(e) => this.redirectSignUp(e)} className="CheckButton font-weight-bold">
            Register Now
            </Button>
            </Link>
            </Carousel.Caption>
          </Carousel.Item> */}

          
         

          {/* <Carousel.Item className="Project1 d-none">
            <BImg display="block" w="100" src={slide5}/>
            <Carousel.Caption   className="ContentBox ">   
          
              <div class="d-flex justify-content-around   mb-3">
               <div className="slide4Content">
              <div className="d-flex   playStore ">
              <Button variant="contained" color="primary" className="d-flex text-left ">
              <img src={googleplay} style={{marginRight:'0.5rem', marginTop:'1rem'}}/>
               <div>
               <small className="small">GET IT ON </small>   
              <span className="d-block googleStore">Google Pay</span> 
              </div>
              </Button>
              <Button variant="contained" color="primary" className="d-flex text-left">
               <AppleIcon />  
               <div>
              <small className="small text-capitalize">Download on the</small> 
              <span className="d-block googleStore">App Store</span> 
              </div>
              </Button>
              </div>

              </div>

              <div className="slide1Content">
              <a target="_blank" href="https://www.navneet.com/">
            <Button className="CheckButton">
               Read More   
            </Button>
            </a>   
            </div> 

           <div className="slide2Content">
            <a target="_blank" href="http://www.navneetfoundation.net/" className="d-md-block d-none">
            <Button className="CheckButton">
               Know More
            </Button>    
            </a>
            </div>
            </div>



            </Carousel.Caption>
          </Carousel.Item> */}

          {/* <Carousel.Item className="Project1">
          <BImg display="block" w="100" src={slide1}/>   
          <Carousel.Caption   className="ContentBox slide2Content">
              <h2>Hash Code 2019</h2>
              <h3>Training Program</h3>
              <p>My Solution on the 2019 Hash Code by Google Slideshow problem</p> 
             
             <a target="_blank" href="http://www.navneetfoundation.net/" className="d-md-block d-none">
            <Button className="CheckButton">
               Know More
            </Button>    
            </a>
            </Carousel.Caption>
          </Carousel.Item>
          

          <Carousel.Item className="Project1"><BImg display="block" w="100" src={slide4}/>
          <Carousel.Caption   className="ContentBox slide4Content">
               <h2>Classroom</h2>  
              <h3>Training Program</h3>
              <p>A exciting mobile game game made in the Unity Engine</p> 
            <Button  className="CheckButton">   
               Register Now
            </Button>

              <h6>DOWNLOAD </h6>
             
              <div className="d-flex  playStore">
              <Button variant="contained" color="primary" className="d-flex text-left ">
              <img src={googleplay} style={{marginRight:'0.5rem', marginTop:'1rem'}}/>
               <div>
               <small className="small">GET IT ON </small>   
              <span className="d-block googleStore">Google Pay</span> 
              </div>
              </Button>
              <Button variant="contained" color="primary" className="d-flex text-left">
               <AppleIcon />  
               <div>
              <small className="small text-capitalize">Download on the</small> 
              <span className="d-block googleStore">App Store</span> 
              </div>
              </Button>
              </div>
           

               
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel.Inner>

        <Carousel.Prev href="#carouselExampleCaptions" className="d-none">
          <Carousel.Prev.Icon />
        </Carousel.Prev>
        <Carousel.Next href="#carouselExampleCaptions" className="d-none">
          <Carousel.Next.Icon />
        </Carousel.Next>
      </Carousel>
        </React.Fragment>
    ); 
 }
}


export default Carouselslide;