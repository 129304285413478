import React, { Component } from 'react';
import ReactDOM1 from 'react-dom';
import './App.scss';     
//import{Route, Switch, withRouter, HashRouter as Router } from "react-router-dom"; 
//import{Route, Switch, withRouter, BrowserRouter as Router } from "react-router-dom"; 
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from './home/Home';   
import About from './about/About';
// import SecondExample from './home/SecondExample';
import Header from './components/Header';
import Sign from './login/Sign';    
import Training from './training/Training';
import TrainingSchedule from './training/TrainingSchedule';
import PastTraining from './training/PastTraining';
import PastTrainingSchedule from './training/PastTrainingSchedule';
import Article from './glimpses/Article';
import DiscussionBoard from './discussion/DiscussionBoard';
import Category_tag_filter from './discussion/Category_tag_filter'; 
import ReadThread from './discussion/ReadThread';
import CreateThread from './discussion/CreateThread';  
import ArticleSingle from './glimpses/ArticleSingle';
import News from './news/News';
import NewsSingle from './news/NewsSingle';
import Blog from './blog/Blog';
import BlogInner from './blog/BlogInner';
import Faq from './faq/Faq';
import Contact from './contact/Contact-us';
import Sample from './discussion/Sample';  
//import NetworkDetector from './utils/NetworkDetector';

import TermsCondition from './terms-condition/TermsCondition';
import Webinar from './webinar/Webinar'; 
import Upcoming_webinar from './webinar/Upcoming_webinar';
import Past_webinar from './webinar/Past_webinar'; 
import VideoGlimses from './glimpses/VideoGlimses'; 
import PastWebinarSchedule from './webinar/Past-Webinar-Schedule';

//import { initializeFirebase } from './push-notification';
import firebase from './firebase';
import axios from 'axios'; 
import { apiURL, getBuildDate  } from './utils'; 
import PrivacyPolicy from './privacy-policy/Privacy-Policy';
//import ReactGA from 'react-ga';


import withClearCache from "./ClearCache";
import packageJson from "../package.json";
 
/*if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
   
}*/



const ClearCacheComponent = withClearCache(MainApp);

function MainApp(props) {
  return (
    <div className="App"> 
        <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
      
    </div>
  );
}


class App extends React.Component {
  
   constructor(props){
     super(props);
     //this.insertToken = this.insertToken.bind(this);
   }  
   
   componentDidMount(){  

    /*if (firebase.messaging.isSupported()){ 
     const messaging = firebase.messaging();
     messaging.requestPermission().then(() => {
       return messaging.getToken()
     }).then(token => {
       console.log('Token', token);
       this.insertToken(token);
     }).catch(() => {
       console.log("error");
     })
    }*/

    //ReactGA.initialize('UA-179012541-1');
    //ReactGA.pageview('/homepage');
    //ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log('this url '+window.location.pathname + window.location.search);
   }
   
   /*insertToken = (token) => {
    const headers = { 
    'Content-type': 'application/json'
    }
    const body = { 
        "token": token, 
    }
    axios.post(apiURL('firebase_notification/add'), body, {headers: headers})
    .then(response => { 
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
          console.log(resultData)
          this.setState({ setMeta: resultData.result })
        } 
    })
   }*/

   render() 
   { 
     //console.log("Host URL"+process.env.PUBLIC_URL);
    return (
      <React.Fragment>   
        {/*<div style={{textAlign:"center"}}>{(window.localStorage.getItem('login_status'))?"Log In":"Not Login"}</div>*/}
	    {/*<NetworkDetector />*/}
      {/*<ClearCacheComponent />*/}
      {/*console.log('Build date:'+ getBuildDate(packageJson.buildDate))*/}
      <Router basename='/'>
        <div>
          <Switch>
          <Route path='/about-us' component={About} />
          <Route path='/header' component={Header} />
          <Route path='/sign' component={Sign} />
         <Route path='/training/:trainingId/:trainingTitle' component={Training} />
         <Route path='/overview' component={TrainingSchedule} />
         <Route path='/past-trainings' component={PastTraining} />
         <Route path='/past-training-schedule/:trainingId/:trainingTitle' component={PastTrainingSchedule} />
         <Route path='/articles' component={Article} />  
         <Route path='/article/:articleId/:articleTitle' component={ArticleSingle} />  
         <Route path='/discussion-board' component={DiscussionBoard} />
         <Route path='/discussion-board/:objectId' component={Category_tag_filter} />   
         <Route path='/govt-circulars-news' component={News} />     
         <Route path='/news/:newsId/:newsTitle' component={NewsSingle} />
         <Route path='/blogs' component={Blog} />
         <Route path='/blog/:blogId/:blogTitle' component={BlogInner} />
         <Route path='/faq' component={Faq} /> 
         <Route path='/contact-us' component={Contact} /> 
         <Route path='/thread-answer/:threadId/:threadTitle' component={ReadThread} />
         <Route path='/sample/:threadId/:threadTitle' component={Sample} />
         <Route path='/create-new-thread' component={CreateThread} />  
         <Route path='/terms-and-conditions' component={TermsCondition} />  
         <Route path='/privacy-policy' component={PrivacyPolicy} />
         <Route path='/webinar/:webinarId/:webinarTitle' component={Webinar} />    
         <Route path='/upcoming-webinars' component={Upcoming_webinar} />   
         <Route path='/past-webinars' component={Past_webinar} />   
         <Route path='/past-webinar-schedule/:webinarId/:webinarTitle' component={PastWebinarSchedule}/>
         <Route path='/video' component={VideoGlimses} /> 
         <Route path='/' component={Home} /> 
		     <Redirect from='*' to='/' />
        </Switch> 
      </div>
      </Router>
    </React.Fragment>
    );
  }
 }

 export default App;

/*function App() {
  return (
    <React.Fragment>   
      <Router>
        <div>
          <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/about' component={About} exact />
          <Route path='/secondexample' component={SecondExample} exact />
          <Route path='/header' component={Header} exact />
          <Route path='/sign' component={Sign} exact />
         <Route path='/training' component={Training} />
        </Switch> 
      </div>
      </Router>
    </React.Fragment>
  );
}

export default App;*/
