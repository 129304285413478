import React,  {Component} from 'react';
import ReactDom from 'react-dom';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';  
import Footer from '../components/Footer';
import {  Container,  Row, Col } from 'bootstrap-4-react';    
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab"; 
import "../style/Discussion.scss";   
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';  
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Grid, DialogTitle, DialogContent, DialogActions, Dialog, DialogContentText} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react'; 
import { Media, BImg, BH5, BH6 } from 'bootstrap-4-react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Divider from '@material-ui/core/Divider';
import { List } from 'bootstrap-4-react';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ReplyIcon from '@material-ui/icons/Reply';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { apiURL, getID } from '../utils'; 
import axios from 'axios';   
import Moment from 'moment'; 
import SanitizedHTML from 'react-sanitized-html'; 
import Replydialog from './Replydialog';

const top100Films = [
{ title: 'The Shawshank Redemption', year: 1994 },
{ title: 'The Godfather', year: 1972 },
{ title: 'The Godfather: Part II', year: 1974 },
{ title: 'The Dark Knight', year: 2008 },
{ title: '12 Angry Men', year: 1957 },
{ title: "Schindler's List", year: 1993 },
];

const styles = theme => ({
   bigIndicator: {
   height: 0,
   },
});

class Sample extends Component { 
   //state = { value: 0 }
   _isMounted = false; 
   constructor(props){
      super(props);
      this.state = {
         value: 0, 
         forumValues: [],
         forumTotalCount:0,
         forumTitle:'',
         forumContent:'',
         forumCreateDate:'',
         forumCategory:'',
         tags:[],
         editorValue:'',
         answers: [],
         bgcolor: '',
         editorValid: false,
         editorError: '',
         _isValid: false,
         replyEditor: false,
         parent_id: null,
         showDialog: false,
      }
      this.discussionReplyDialogOpen   = this.discussionReplyDialogOpen.bind(this);
      this.discussionReplyDialogClose  = this.discussionReplyDialogClose.bind(this);
      this.getForumByID                = this.getForumByID.bind(this);
      this.getForumAnswers             = this.getForumAnswers.bind(this);
      //this.handleEditorChange          = this.handleEditorChange.bind(this);
      //this.submitAnswer                = this.submitAnswer.bind(this);
   }
   componentDidMount()
   {
      this._isMounted = true; 
      window.addEventListener('scroll', this.signNav);
      this.getForumByID();
      this.getForumAnswers();
   }
   componentWillUnmount() {
      this._isMounted = false; 
   }

   getForumByID = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('forum/get_forum_by_id/'+this.props.match.params.threadId), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({
                  forumValues: resultData.result, 
                  tags: resultData.result.tags,  
              }); 
          }
          else{ 
            this.setState({forumValues: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }

    getForumAnswers = (e) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      axios.get(apiURL('forum/answer/'+this.props.match.params.threadId), {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({
                answers: resultData.result.arrChilds,  
              }); 
          }
          else{
            this.setState({answers: []});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }

   handleChange = (event, value) => {
   this.setState({ value }); 
   };

   discussionReplyDialogOpen = (e) => {    
      e.preventDefault();
      e.stopPropagation(); 
      this.setState({ showDialog: true })
   }

   discussionReplyDialogClose = () => {
      this.componentDidMount();
      this.setState({ showDialog: false }) 
    }

    parentRefresh = () => {
      this.componentDidMount(); 
    }
 
   render() {
   const { classes } = this.props;
   const {value} = this.state
   return (
      <React.Fragment>   
         <section className="">
            <Container>
               <Row>
                  <Col sm="12" className="discussionMain mt-5" >
                  <Row> 
                     <Col sm="12" key="tab-content" className=""> 
                     <Row className="threadBox mt-4" >
                        <Col sm="12" >
                        <h3>{this.state.forumValues.fldv_title}</h3>
                        <SanitizedHTML html={this.state.forumValues.fldv_content} />
                        <span className="category"> {this.state.forumValues.fldv_category} </span> 
                        
                        {this.state.tags.map((arrTags, index) => {
                           return(
                              <span className="badge" key={arrTags.fldv_title}>{arrTags.fldv_title}</span>
                           )
                        })} 
                        </Col>

                        <ReplyAnswer threadTitle={this.state.forumValues.fldv_title} answer={this.state.answers} threadId={this.props.match.params.threadId} pageRender={(e) => this.parentRefresh(e) } />

                        <Col sm="12">
                           <Button variant="contained" size="large" className="shareBox"  startIcon={ <InsertLinkOutlinedIcon  className="replyIcon"   /> } > Share </Button>

                           <Button onClick={(e) => this.discussionReplyDialogOpen(e) } variant="contained" size="large" color="primary" className="shareBox" startIcon={ <ReplyIcon /> } > Reply </Button>

                           <Replydialog threadTitle={this.state.forumValues.fldv_title} threadId={this.props.match.params.threadId} visible={this.state.showDialog} onDialogClose={(e) => this.discussionReplyDialogClose(e) }  parentId={null}/>
                        </Col>

                     </Row>
                     
                     </Col>
                  </Row>
                  </Col>
               </Row>
            </Container>
         </section> 

      </React.Fragment>
      );
   }
}

function ReplyAnswer(props)
{
    const [open, setOpen] = React.useState(false); 
    const [parentId, setParentId] = React.useState(null); 

    const handleClickOpen = (e, parent_id) => {
      e.preventDefault();
      e.stopPropagation(); 
      setOpen(true);
      setParentId(parent_id) 
    };  

   const discussionReplyDialogClose = (e) => { 
      setOpen(false);   
      props.pageRender();  
   }

    return (
        <React.Fragment>
            {props.answer.map((arrAnswers, index) => { 
              return <Col sm="12" key={index}>
              <Media border  className="flex-md-nowrap flex-wrap mt-sm-5 mt-4" >
                 <span className="usersBox green">{arrAnswers.fldv_name.charAt(0)}</span>
                 <Media.Body>
                    <div className="d-flex justify-content-between headBox flex-md-nowrap flex-wrap">
                       <BH5 className="font-weight-bold" >{/*Media heading*/}{arrAnswers.fldv_name}</BH5>
                       <div className="d-flex  flex-md-nowrap flex-wrap">
                          <BH6 className="iconBox ml-sm-4" >
                             <small>
                                <AccessTimeIcon className="iconBox" />
                             </small> 
                             {Moment(arrAnswers.flddt_created_date).format('D MMMM, YYYY')}
                          </BH6>
                       </div>
                    </div>
                    <div>
                       <p> 
                          <SanitizedHTML html={arrAnswers.fldt_content} />
                       </p>
                    </div>
                    <Divider   className="mt-sm-5 mt-4" />
                    <Row className="mt-4">
                       <Col col="6">
                       <List inline>
                          <List.Item inline className="likeIcon mr-3">
                             <ForwardOutlinedIcon/>
                             201
                          </List.Item>
                          <List.Item inline className="likeIcon">
                             <ForwardOutlinedIcon/>
                             201
                          </List.Item>
                       </List>
                       </Col>
                       <Col col="6">
                       <List inline className="float-right rightBox">
                          <List.Item inline className="likeIcon mr-3">
                             <InsertLinkOutlinedIcon/>
                          </List.Item>
                          <List.Item inline className="likeIcon">
                             <BookmarkBorderIcon/>
                          </List.Item>
                          <List.Item inline style={{cursor:"pointer"}} className="likeIcon" onClick={(e) => handleClickOpen(e, arrAnswers.fldi_id)} >
                             <ReplyIcon/> 
                          </List.Item>
                       </List>
                       </Col>
                    </Row>
                       <ReplyAnswer threadTitle={props.threadTitle} answer={arrAnswers.arrChilds} threadId={props.threadId} parentId={arrAnswers.fldi_id} pageRender={(e) => discussionReplyDialogClose(e)} />
                 </Media.Body>
              </Media>
              </Col>
            })} 

            <Replydialog visible={open} threadTitle={props.threadTitle} threadId={props.threadId} parentId={parentId} onDialogClose={(e) => discussionReplyDialogClose(e)} />
         
        </React.Fragment>
    )
}

export default withStyles(styles)(Sample);    