import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';  
// import Home from './components/Home';   
import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from "react-router-dom";
import { hydrate, render } from "react-dom";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
} 

/*ReactDOM.hydrate(
  <React.StrictMode> 
    <BrowserRouter>
    <App />   
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root') 
);*/

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
