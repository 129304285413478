import React, { Component } from 'react';    
import {  Row, Col, Container, Card } from 'bootstrap-4-react';
import { Carousel} from 'bootstrap-4-react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';   
import DialogContent from '@material-ui/core/DialogContent';  
import ReactPlayer from 'react-player/lazy' ; 
import { withStyles } from "@material-ui/core/styles"; 
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { apiURL, getID } from '../utils'; 
import axios from 'axios';    
import Moment from 'moment';  



const styles = theme => ({
   dialogPaper: {
   borderRadius:'0px',
   maxWidth:'48.75rem',
   width:'60rem',
 
 },
 });


class Activities extends Component {
   
  _isMounted = false; 
   constructor(props) {
      super(props);
      this.state = {
        videoUrl: '', 
        open: false,
        otherActivity: [],
        noData: false
      } 
      
  this.getOtherActivities = this.getOtherActivities.bind(this);
  }
   
componentDidMount()
{
  this._isMounted = true; 
  window.scrollTo(0, 0); 
  this.getOtherActivities();
}

getOtherActivities = () =>{
  const headers = {
     'content-type': 'multipart/form-data',
     'Accept': 'application/json'
  }
  const body = {
     "training_id": this.props.trainingId,
     "user_id": getID(),
     "feedback" : this.state.feedback
  } 
  axios.post(apiURL('other_activities/get_all_other_activities'), body, {headers: headers})
     .then(response => {
     const resultData = response.data; 
     if(resultData.status === true || resultData.status === 1)
     { 
        this.setState({  
           otherActivity: resultData.result, 
        }); 
     } 
     else{
      this.setState({noData: true}); 
   } 
  })
  .catch(error => {
     console.log(error)
  })  
}

componentWillUnmount() {
  this._isMounted = false; 
}


   handleClickOpen = (e,videoUrl) => {
      this.setState({ open: true, videoUrl:videoUrl })
   }

   handleClose = () => {
      this.setState({ open: false, videoUrl:'' })
   }

      

render() {
   const { classes } = this.props;
return (
<React.Fragment>
   <Container>
   {!this.state.noData && <Row>
         <Col sm="12">
         <Row className="Blogbox mt-0">
            <Col col="sm-12 " className="text-center">
            <h2>Other Activities</h2>
            </Col>   
         </Row>
         </Col>
         <Col sm="12" className=""> 
         <Carousel w="100" id="carouselExampleCaptions">
         <Carousel.Indicators>
               <Carousel.Indicator target="#carouselExampleCaptions" to="0" active />
               <Carousel.Indicator target="#carouselExampleCaptions" to="1" />
               <Carousel.Indicator target="#carouselExampleCaptions" to="2" />
            </Carousel.Indicators>
            <Carousel.Inner>
               <Carousel.Item  active> 
                  <Row className="Blogbox mt-0">
                     {this.state.otherActivity.map((arrActivity, index) =>{
                           return <Col key={index} col="sm-7 md-4"  className="mx-sm-auto " >
                           <Card className="shadow-sm"> 
                              {(arrActivity.fldv_video_url !== " ")?
                              <Button className="text-center p-0 imgBox" onClick={(e)=>this.handleClickOpen(e, arrActivity.fldv_video_url)}>
                              <img src={arrActivity.fldv_image_capture} className="img-fluid" alt={arrActivity.fldv_title}/>
                                 <div className="boxIcon">
                                 <ArrowRightIcon className="playIconBox" />
                                    </div>
                                 </Button>:<img src={arrActivity.fldv_image_url} className="img-fluid" alt={arrActivity.fldv_title}/>}
      
                              <Card.Body className=" " >
                                 <Card.Title>
                                    {arrActivity.fldv_title}
                                 </Card.Title>
                                 <Card.Text> 
                                    {Moment(arrActivity.fldv_selected_date).format('D MMMM, YYYY')}
                                 </Card.Text>
                              </Card.Body>
                           </Card>
                           </Col>

                     })}
                  </Row>
               </Carousel.Item>
            </Carousel.Inner>
            <Carousel.Prev href="#carouselExampleCaptions">
               <Carousel.Prev.Icon />
            </Carousel.Prev>
            <Carousel.Next href="#carouselExampleCaptions">
               <Carousel.Next.Icon />
            </Carousel.Next>
         </Carousel>
         </Col>
      </Row>}
   </Container>

   
   <Dialog open={this.state.open} onClose={() => this.handleClose()}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" classes={{ paper: classes.dialogPaper }} className="boxMain">
          <DialogContent className="dialouge">
              <div className='player-wrapper'>
                <ReactPlayer url={this.state.videoUrl} className='react-player' width='100%' height='100%' muted config={{ file: { attributes: {
                autoPlay: false, muted: true  }}}}  />
              </div>
          </DialogContent>
        </Dialog>
</React.Fragment>
);
}
}

export default withStyles(styles) (Activities);