import  React, {Component} from 'react';
import {TextField, Grid, Snackbar} from '@material-ui/core';
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import { List } from 'bootstrap-4-react';
import { Nav } from 'bootstrap-4-react';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";  
import Dialog from '@material-ui/core/Dialog';   
import DialogContent from '@material-ui/core/DialogContent';
import HelpUsForm from './HelpUsForm';


import "../style/Footer.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {  faFacebookF, faVimeoV , faTwitter , faPinterestP  } from '@fortawesome/free-brands-svg-icons';
import {  faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup'; 
import Simple from './Simple';
import { apiURL } from '../utils'; 
import axios from 'axios';   
import Alert from '@material-ui/lab/Alert'; 
import { NavLink } from 'react-router-dom';  

import footerLogo from '../images/footerlogo.png';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignupSchema = yup.object().shape({
  help_name: yup.string().required("This field is required."),
  help_contact: yup 
    .string()
    .required("This field is required."),
  help_email: yup
    .string()
    .email('email must be valid email')
    .required("This field is required."),
  help_message: yup.string().required("This field is required."),
}); 

const SubscribeSchema = yup.object().shape({
  subscribe_email: yup
    .string()
    .email("subscribe email must be a valid email")
    .required("This field is required."),
});



const styles = theme => ({
  dialogPaper: {
  minHeight: '90vh',
  maxHeight: '90vh',
  borderRadius:'25px',
  maxWidth:'42.75rem',
  },
  });


class Footer extends Component {
    constructor(props){
      super(props)
      this.state = {
          returnMessage: '',
          showSnackbar: false,
          snackBarMessage: '',
          severityColor:'',
          reload: false,
          open: false,
          help_contactError: false,
          help_contactErrorMsg: '', 
      }
      this.changeHandler      = this.changeHandler.bind(this);
      this.onSubmitGetInTouch = this.onSubmitGetInTouch.bind(this);
      
    }

    changeHandler = (e) => {
      this.setState({ 
          [e.target.name]: e.target.value,
      }); 
     // console.log(e.target.name +'===='+e.target.value);
    }

    snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
        this.setState({ showSnackbar: false }); 
  }

  componentDidMount(){
    this.setState({ help_name: null, help_email: null, help_contact:null, help_message: null});
    this.setState({ subscribe_email: null})
  }
    
    onSubmitGetInTouch = (values) => { 
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
        //axios.post(apiURL('get_in_touch/add'),values, {headers: headers})
        axios.post(apiURL('help_us_to_improve/add'),values, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.setState({ snackBarMessage: resultData.message, showSnackbar: true, severityColor:'success' });
            }
            else{
              this.setState({ snackBarMessage: "Something went wrong! Please Try after some time",showSnackbar: true , severityColor:'error'});
            } 
            this.setState({ help_name: "", help_email: "", help_contact:"", help_message: ""});
            this.componentDidMount();
           
        })
        .catch(error => {
            console.log(error)
        })  
    }

    onSubmitSubscribeEmail = (values) => { 
      const headers = {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json'
      }
      axios.post(apiURL('subscribe/add'),values, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          {
              this.setState({ snackBarMessage: "Thank you for subscribing",showSnackbar: true, severityColor:'success' });
          }
          else{
            this.setState({ snackBarMessage: "Server error! Please Try after some time",showSnackbar: true , severityColor:'error'});
          } 
          this.setState({ subscribe_email: ""});
          this.componentDidMount();
         
      })
      .catch(error => {
          console.log(error)
      })  
  }


  handleClickOpen = () => {
    this.setState({ open: true })
    }
    handleClose = () => {
    this.setState({ open: false })
    }

    numberValidate = (e) => {  
      const re = /^[0-9\b]+$/;
      if (!re.test(e.target.value)) { 
          this.setState({ help_contactError: true, help_contactErrorMsg:"This field accept only digit."}); 
      }  
      else{ 
          this.setState({ help_contactError: false, help_contactErrorMsg:""}); 
      } 
    }

    
    numberLengtValidate = (e) => {  
      const re = /^[0-9\b]+$/;
      if(!re.test(e.target.value) || e.target.value.length !== 10)
      {
        //alert(1+'->'+e.target.value.length)
        this.setState({ help_contactError: true, help_contactErrorMsg:"Contact number must be 10 digit."}); 
      } 
      else if(re.test(e.target.value) || e.target.value.length === 10)
      {
        //alert(2+'->'+e.target.value.length)
        this.setState({ help_contactError: false, help_contactErrorMsg:""}); 
      }
      else{ 
        //alert(3+'->'+e.target.value.length)
        this.setState({ help_contactError: false, help_contactErrorMsg:""}); 
      } 
    }

    render() {
      const { classes } = this.props;
      return (
        <div>
          <div style={{marginTop:"5rem"}}></div>
      <section className="Newsletter d-none">
          <Container>
            <Row>
              <Col col="sm-6 " className="pt-3">
                {/* <h3>Submit to our Newsletter</h3> */}
                <h3>Subscribe to our Newsletter</h3>   
                {/*<p>Reference site about Lorem Ipsum</p> */}
              </Col>
              <Col col="sm-6">
              <Formik
                  initialValues={{
                    subscribe_email: "",
                  }}
                  validationSchema={SubscribeSchema}
                  onSubmit={values => { 
                    this.onSubmitSubscribeEmail(values)
                  }}
                >
                {({ errors, handleChange, touched }) => (
               <Form  autoComplete="off" className="CustomForm">
                <TextField id="filled-basic" onChange={handleChange} value={this.state.subscribe_email} name="subscribe_email" label="Your email" variant="filled" className="TextBox"  error={errors.subscribe_email && touched.subscribe_email} />
               <Button type="submit" variant="contained">Subscribe </Button>
               <div className="w-100 small ml-1 mt-1" style={{color:"red"}}>{errors.subscribe_email && touched.subscribe_email? errors.subscribe_email: null} </div>
               </Form> 
               )}
               </Formik>
              </Col>
            </Row>
          </Container>
     </section>

     <footer className="FooterBox">   
       {/*<Simple />*/}
       <Container>
          <Row>
            <Col sm="12">
            {/* <Button className="CheckButton checkBtnSchedule text-center" onClick={this.handleClickOpen}>
            Share Feedback
            </Button> */}
            <Dialog
               open={this.state.open}
               onClose={() =>
               this.handleClose()}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
               classes={{ paper: classes.dialogPaper }}
               >
               {/* 
               <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
               */}
               {/* <h2 className="text-center  headBoxRegister text-uppercase">Help Us to improve</h2> */}
               <DialogContent>
                
                  <HelpUsForm />
               </DialogContent>
               {/* 
               <DialogActions>
                  <Button onClick={() => this.handleClose()} color="primary">
                  Disagree
                  </Button>
                  <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                  Agree
                  </Button>
               </DialogActions>
               */}   
            </Dialog>
            </Col>
            <Col col="sm-6 md-6 lg-3">
              <h4>Help us to improve</h4> 
          <Formik
          initialValues={{
            help_name: "",
            help_contact: "",
            help_email: "",
            help_message: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { resetForm })=> { 
            if(!this.state.help_contactError)
            {
              this.onSubmitGetInTouch(values);
              resetForm({ values: ''});
            }
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form id="get-in-touch" className="footerForm">
                <Grid container>
                    <Grid item xs={12} sm={12}>   
                      <TextField id="outlined-basic" type="text" name="help_name" id="help_name" value={this.state.help_name} placeholder="Name" onChange={handleChange} variant="outlined"  error={errors.help_name && touched.help_name}  />
                       <div className="w-100 small" style={{color: "red"}}> {errors.help_name && touched.help_name? errors.help_name: null} </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>   
                      <TextField id="outlined-basic" type="text" name="help_email" id="help_email" value={this.state.help_email} placeholder="Email" onChange={handleChange} variant="outlined" error={errors.help_email && touched.help_email} />
                      <div className="w-100 small" style={{color: "red"}}> {errors.help_email && touched.help_email ? errors.help_email : null}  </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>   
                      <TextField id="outlined-basic" type="text" name="help_contact" id="help_contact" value={this.state.help_contact} placeholder="Phone No." onChange={handleChange} onInput={(e) => this.numberValidate(e)} onBlur={(e) => this.numberLengtValidate(e)} variant="outlined" error={errors.help_contact && touched.help_contact || this.state.help_contactError}  inputProps={{ maxLength: 10 }}/>
                      <div className="w-100 small" style={{color: "red"}}>  {errors.help_contact && touched.help_contact ? errors.help_contact: null || this.state.help_contactErrorMsg} </div>
                    </Grid>
                    <Grid item xs={12} sm={12} className="textLable">   

                      
                      {/*<TextField id="outlined-basic" multiline rowsMin={3} rowsMax={4} name="help_message" id="help_message" value={this.state.help_message} label="Message" onChange={handleChange} variant="outlined" error={errors.help_message && touched.help_message } />
                      <div className="w-100 small" style={{color: "red"}}>  {errors.help_message && touched.help_message ? errors.help_message: null } </div>*/}

                      <TextareaAutosize id="outlined-basic" type="text" aria-label="help_message" name="help_message" value={this.state.help_message} className="mt-2 textAreaBox"  rowsMin={3} placeholder="Message" onChange={handleChange} />
                      <div className="w-100 small" style={{color: "red"}}>  {errors.help_message && touched.help_message ? errors.help_message: null} </div>

                    </Grid>  
                    <Grid item xs={12} sm={12}>  
                      <Button variant="contained" color="primary" type="submit" className="submitBox btn btn-primary mr-2" > Submit</Button>
                    </Grid>

                   
                  </Grid>
                  </Form>
          )}
        </Formik>
            </Col>
            <Col col="sm-6 md-6 lg-3">
              <h4>Quick Links</h4>
              <Nav className="ml-0" flex="column">
                <NavLink  exact activeClassName="active_class" className="nav-link "   to="/about-us">About Us</NavLink>
                {/* <NavLink  exact activeClassName="active_class" className="nav-link"   to="/trainingschedule">Trainings Schedule</NavLink> */}

                <NavLink  exact activeClassName="active_class" className="nav-link"   to="/past-trainings">Trainings </NavLink>
                {/* <NavLink exact activeClassName="active_class" className="nav-link"   to="/create-new-thread">Discussion Board</NavLink> */}
                {/* <NavLink exact activeClassName="active_class" className="nav-link"   to="/news">Circulars/News</NavLink> */}
                <NavLink exact activeClassName="active_class" className="nav-link"   to="/past-webinars">Webinars</NavLink>
                <NavLink exact activeClassName="active_class" className="nav-link"   to="/govt-circulars-news">Govt. Circulars/GR</NavLink>
                <NavLink exact activeClassName="active_class" className="nav-link"   to="/articles">Articles</NavLink>
                <NavLink exact activeClassName="active_class" className="nav-link"   to="/blogs">Blogs</NavLink>
               {/* // <NavLink exact activeClassName="active_class" className="nav-link"   to="/upcoming-webinar">Upcoming Webinars</NavLink> */}
                {/* <NavLink exact activeClassName="active_class" className="nav-link"   to="/past-webinar">Past Webinars</NavLink> */}
              </Nav>
            </Col>
            <Col col="sm-6 md-6 lg-3">
              <h4>Help</h4>
              <Nav className="ml-0" flex="column">
                <NavLink exact activeClassName="active_class" className="nav-link" to={{ pathname: '/sign', state: { value: 0 } }}>Sign in</NavLink>
                <NavLink exact activeClassName="active_class" className="nav-link" to={{ pathname: '/sign', state: { value: 1 } }}>Sign Up</NavLink>
                {/* <NavLink exact activeClassName="active_class" className="nav-link" to="/faq">FAQ's</NavLink> */}
                <NavLink exact activeClassName="active_class" className="nav-link" to="/terms-and-conditions">Terms & Conditions</NavLink>
                {/* <Nav.ItemLink onClick={this.handleClickOpen} style={{cursor:"pointer"}} >Help Us to Improve</Nav.ItemLink> */}  
              <NavLink exact activeClassName="active_class" className="nav-link" to="/contact-us">Contact Us</NavLink>
                <NavLink exact activeClassName="active_class" className="nav-link" to="/privacy-policy">Privacy Policy</NavLink>
            </Nav>
            </Col>
            <Col col="sm-6 md-6 lg-3">
               <h4>Connect With Us</h4>   
        <List inline className="SocialBox ml-0 pl-5">
        {/* <List.Item inline> <img src="../images/facebook.png" alt="" /></List.Item>
        <List.Item inline><img src="../images/twitter.png" alt="" />  </List.Item>
        <List.Item inline> <img src="../images/vimeo.png" alt="" /></List.Item>
        <List.Item inline> <img src="../images/pinterest.png" alt="" /></List.Item> */}

        <List.Item inline><a href="https://www.facebook.com/NavneetFoundation/" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></List.Item>
        <List.Item inline><a href="https://twitter.com/Navneet_CSR" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></List.Item>
        {/* <List.Item inline><FontAwesomeIcon icon={faVimeoV} /></List.Item>
        <List.Item inline><FontAwesomeIcon icon={faPinterestP} /></List.Item> */}

      </List> 
               <a href="http://www.navneetfoundation.net" target="_blank">
              <img src={footerLogo} className="img-fluid mt-3" />
              </a>
            </Col>
          </Row>
       </Container>
     </footer>

     <footer className="FooterBottom">
        <Container>
          <Row>
            <Col col="sm-6">
              <p>©Navneet Foundation 2020. All rights reserved.</p>   
            </Col>
            <Col col="sm-6" className="text-sm-right text-left">
               <p>Made by Bee Online</p>
            </Col>
            <Col col="sm-12">
              <p>
                <small>
                  Disclaimer:The opinions expressed in the articles published on this portal are the personal opinion of the respective authors. The information and opinion appearing in these articles do not reflect the views and/or representations of Navneet Foundation (NF) and NF does not assume any responsibility or liability for the same. 
                </small>
              </p>
            </Col>   
          </Row>
        </Container>
     </footer>
     <Snackbar open={this.state.showSnackbar === true} autoHideDuration={6000} onClose={this.snackBarClose}>
        <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
        { this.state.snackBarMessage }
        </Alert>
    </Snackbar>
    </div>        
      );
    }
  }


  export default withStyles(styles) (Footer);