import React, { Component } from 'react';  
import ReactDOM from 'react-dom';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';   
import {  Container,  Row, Col, List  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
// import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import ContactForm from '../contact/ContactForm.jsx';  
import "../style/Contact.scss";
const styles = theme => ({
root: {
maxWidth: "100%",  
},   
});
class Contact extends Component {   
   componentDidMount() {
      window.scrollTo(0, 0)
    }
render() {  
return (
<React.Fragment>
   <Appbar />
   {/* <div className="NavBar   pb-0 sticky-top ">
      <Navmenu />
   </div> */}
   <div className="sticky-top sign-light">
            <Navmenu />    
    </div>  
   <section className="BannerBox discussionBanner contactBanner">
      <div className="sixteen-nine">
         <div className="content text-center text-white d-none">
            <h2>Contact Us</h2>
            <p>Lorem Ipsum</p>
         </div>
      </div>
   </section>
   <section className="commentBox contactBox">
      <Container>
         <Row>
            <Col sm="12" className="text-center">
            <h3>For any enquiry or support contact us on the below given information.</h3>
            <h2>Please feel free to contact us.</h2>
            </Col>
            <Col sm="12">
            <ContactForm />
            </Col>
            {/* <Col sm="9" className="mt-sm-5 mt-4 text-center mx-auto">
            <h3 className="supportBox">Our Support Guys or <br></br> Make Appointment With Our Consultant</h3>
            <p>Please contact us using the information below. <br></br>
               For additional information on our management consulting services, please visit the appropriate page on our site.
            </p>
            </Col> */}
            <Col sm="12" className="mt-sm-5 mt-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.08975178522!2d72.8342693149048!3d19.015766358804544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cef28535c3d9%3A0xc9046dc6bf92426e!2sNavneet%20Education%20Limited!5e0!3m2!1sen!2sin!4v1598254541734!5m2!1sen!2sin"  frameborder="0" className="mapBox" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </Col>
         </Row>
      </Container>
   </section>
   <section className="addressBox">
      <Container>
         <Row>
            <Col sm="4">
            <img src="images/address-men.png" className="img-fluid"/>
            </Col>
            <Col sm="8">
            <Row>
               <Col md="6" sm="12" className="mt-sm-0 mt-3">
               <h3>Mumbai</h3>
               <div className="textBox">
                  <p>Navneet Bhavan, <br /> Bhavani Shankar Road, Dadar (W).  <br /> Mumbai - 400 028. India.</p>
                  <List unstyled className="pl-0 ml-0">
                     <List.Item>Tel: +91 22 66626565</List.Item>
                     <List.Item >Fax: +91 22 66626470</List.Item>
                  </List>
                  {/* <List unstyled>
                     <List.Item>CIN: L22200MH1984PLC034055</List.Item>
                  </List> */}
               </div>
               </Col>
               <Col md="6" sm="12" className="mt-sm-0 mt-3">
               <h3>Ahmedabad</h3>
               <div className="textBox">
                  <p>Navneet House, <br /> Gurukul Road, Memnager, <br /> Ahmedabad 380052. India.</p>
                  <List unstyled className="pl-0 ml-0">
                     <List.Item>Tel: +91 22 66626565</List.Item>
                     <List.Item >Fax: +91 22 66626470</List.Item>
                  </List>
                  {/* <List unstyled>
                     <List.Item>Mail:inquiry@navneet.com</List.Item>
                  </List> */}
               </div>
               </Col>
            </Row>
            </Col>
         </Row>
      </Container>
   </section>
   <div className="customFooter">
      <Footer />
   </div>
</React.Fragment>
);
}
}
export default withStyles(styles) (Contact);  