import React from 'react';
import SanitizedHTML from 'react-sanitized-html'; 
import moment from 'moment'; 

const date = new Date();

export const basePath = () => {
    return window.location.origin.toString()+"/";
}

export const login = (userData) => { 
    localStorage.setItem('login_status', true);
    localStorage.setItem('user_detail', JSON.stringify(userData));
}

export const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('isLoggedin');
}

export const isLogin = () => {
    if(window.localStorage.getItem('login_status')){
        return true;
    } else{
        return false;
    }
}

export const getID = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.fldi_id;   
    } else{
        return false;
    }
}

export const getName = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.fldv_name;   
    } else{
        return false;
    }
}

export const getUsername = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.fldv_username;   
    } else{
        return false;
    }
}


export const getEmail = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.fldv_email;   
    } else{
        return false;
    }
}


export const getContact = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.fldv_contact;   
    } else{
        return false;
    }
}


export const getPassword = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.fldv_password;   
    } else{
        return false;
    }
}

export const passwordStatus = () => {
    if(window.localStorage.getItem('login_status')){
        const userID = JSON.parse(localStorage.getItem('user_detail'));
        return userID.flg_password_status;   
    } else{
        return false;
    }
}
 
export const apiURL = (method) => {
    //return "https://beelive.in/navneet/api/"+method;
    return "https://www.navdisha.co.in/navneet/api/"+method;        
}
 
export const getCurrentDate = () => {
    return date.getDate();
}

export const getCurrentMonth = () => {
    let currentMonth =  date.getMonth() + 1;
    return (currentMonth<10)? 0+''+currentMonth : currentMonth;
}
export const getCurrentYear = () => {
    return date.getFullYear();
}

export const getCurrentTime = () => {
    return date.toLocaleString();
}

export const sanitizeHtml = (str) => {
    return <SanitizedHTML html={str} />
}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
    return buildDate;
  };

 