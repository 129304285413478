import React, { Component } from 'react';  
import ReactDOM from 'react-dom';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/Faq.scss";
import {  Container,  Row, Col, Jumbotron,  Display4  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import Meta from '../meta/Meta';  
const styles = theme => ({
root: {
maxWidth: "100%",  
},
});
class Panel extends React.Component {
constructor(props) {
super(props);
this.state = {
height: 0
};
}
componentDidMount() {
   window.scrollTo(0, 0);
   
window.setTimeout(() => {
const el = ReactDOM.findDOMNode(this);
const height = el.querySelector('.panel__inner').scrollHeight;
this.setState({
height
});
}, 333);
}
render () {
const { label, content, activeTab, index, activateTab } = this.props;
const { height } = this.state;
const isActive = activeTab === index;
const innerStyle = {
height:  `${isActive ? height : 0}px`
}
return (
<div className='panel' role='tabpanel' aria-expanded={ isActive }>
   <button className='panel__label' 	role='tab' onClick={ activateTab }>
      <div className="iconBox"></div>
      <h4> { label }  </h4>
   </button>
   <div className='panel__inner'
   style={ innerStyle }
   aria-hidden={ !isActive }>
   <p className='panel__content'>
      { content }
   </p>
</div>
</div>
);
}
}
class Faq extends Component {   
constructor(props) {
super(props);
this.state = {
activeTab: 0,
panels: [
{
label: 'Should we do all the activities given in the book?',
content: 'The activities given in the book are to clarify the context and the concept. The activities should be done because there is no Question paper but Activity sheet. These textual activities are to help the student to understand and do them. These activities help the student to think and comprehend the concepts and then to perform the activities. So, these activities need to be done. It could be Homework or Class work but it should be discussed with the students as part of evaluation to ensure their comprehension. Every activity has a purpose and they aim at developing and evaluating specific skills. So, by neglecting the activities, we will be depriving students from developing those skills.',
},
{
label: 'Are warm up activities required to be done?',
content: 'Yes. They are important to set the tempo of learning. It is an integral part of getting ready to learn. These activities help in setting the tone for the prose or poetry to be introduced. It helps in improving the child’s General Knowledge. It is a fun activity and makes learning both enjoyable and involving.',
},	
{
label: 'Do we need to read and explain the poem? ',
content: 'This is a matter of teaching technique. Each teacher has her/his style of teaching, which is dependent on the poem and the class. The poem can be read by the students. This will help in development of reading skills. Questions can be asked so that the child can think and answer. This develops the ability to guess and put the response in the correct frame of context. The teacher can explain certain lines which the child may find difficult to grasp. A poem is a different genre, so the words are used differently. Also,at times the poet uses poetic license. The images in the poem are different from the real context. So, word pictures and poetic devices need to be highlighted and explained.',
},
{
label: 'Is Project work to be done?',
content: 'Yes, it is better done. It will take the children beyond the course/textbook. Project work will instill in them a Research Attitude. It will help the child to Read, Research and Infer. This is one way of instilling Reading habits in the child. It also teaches the children to present their views and write a summary or report. Project work also helps the student to learn through experience by using the technique of self-learning.',
},
{
label: 'Should Essay writing be done when it is not in the Activity Sheet? ',
content: 'Essay writing can help in thinking deeply on all aspects of a given topic. It can help expression, use of appropriate language, emotions to be verbalized, associate current affairs that are to be critically analyzed and thus help gain proficiency over the language. This is also another way to foster reading habits and develop the subskills of reading. It will also help the student to acquire writing skills and presentation techniques.This will greatly help the student to enjoy the language and go beyond the book. In the process, values and reality will be experienced and so the resultant personality development will be enhanced.'
},
]
};
this.activateTab = this.activateTab.bind(this);
}
activateTab(index) {
this.setState(prev => ({
activeTab: prev.activeTab === index ? -1 : index
}));
}
render() {  
// const classes = styles();
const { classes } = this.props;  
const { activeTab } = this.state;
return (
<React.Fragment>
   <Meta tableName={"menu"} objectId={17} forPage={"main_page"} />
   <Appbar />
   {/* <div className="NavBar  pb-0 sticky-top ">
      <Navmenu />
   </div> */}
   <div className="sticky-top sign-light">
            <Navmenu />    
    </div>  
   <section className="BannerBox discussionBanner faqBanner ">
      <div className="sixteen-nine">
         <div className="content text-center text-white d-none">
            <h2>Faq</h2>
            <p>Lorem Ipsum</p>
         </div>
      </div>
   </section>
   <section className="faqBoxMain">
      <Container>
         <Row>
           <Col md="12" className="askeBox">
            <h4>Frequenty Asked Question</h4>
            <h2>Got Any Questions?</h2>
            <p>
            Some teachers have doubts or confusion regarding how concepts are to be
               taught. These doubts could be textbook based or could be connected to the
               Activity sheet.
            </p>
            <p>
            We highlight below 5 such questions which we have identified from the various
interactions we have had with teachers.
            </p>
            <Button type="submit"    className="CheckButtonFaq text-center mt-3" >
            Get More Help
            </Button>
            </Col>
            <Col md="5" className="askeBox">
            <h4>Frequenty Asked Question</h4>
            <h2>Got Any Questions?</h2>
            <p>
            Some teachers have doubts or confusion regarding how concepts are to be
               taught. These doubts could be textbook based or could be connected to the
               Activity sheet.
            </p>
            <p>
            We highlight below 5 such questions which we have identified from the various
interactions we have had with teachers.
            </p>
            <Button type="submit"    className="CheckButtonFaq text-center mt-3" >
            Get More Help
            </Button>
            </Col>
            <Col md="7" className="accordionBox">
            <div className='accordion ml-auto' role='tablist'>
               {this.state.panels.map((panel, index) =>
               <Panel
               key={ index }
               activeTab={ activeTab }
               index={ index }
               { ...panel } 
               activateTab={ this.activateTab.bind(null, index) }
               />
               )}
            </div>
            </Col>
         </Row>
      </Container>
   </section>
   <Footer />
</React.Fragment>
);
}
}
export default withStyles(styles) (Faq);