import React,  {Component} from 'react'; 
import { withStyles } from "@material-ui/core/styles";   
import { Grid, DialogTitle, DialogContent, DialogActions, Dialog, Button, Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import { Editor } from '@tinymce/tinymce-react';  
import { apiURL, getID } from '../utils'; 
import axios from 'axios';    

const styles = theme => ({
    bigIndicator: {
    height: 0,
    },
 });

 class Replydialog extends Component {   _isMounted = false; 
    
    constructor(props, context){
       super(props, context);
       this.state = {   
          editorValue:'', 
          editorValid: false,
          editorError: '',
          _isValid: false,
          replyEditor: false,
          parent_id: null,
          showDialogModal: this.props.visible,
          fieldName: '',
          errorMsg: '',
       }  

       this.submitAnswer                = this.submitAnswer.bind(this);
       this.handleEditorChange          = this.handleEditorChange.bind(this);
       //this.discussionReplyDialogOpen   = this.discussionReplyDialogOpen.bind(this);
       this.discussionReplyDialogClose  = this.discussionReplyDialogClose.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showDialogModal != nextProps.visible) {
          this.setState({
            showDialogModal: nextProps.visible
           });
          }
        }
    
    componentDidMount()
    {
       this._isMounted = true;  
    }

    componentWillUnmount() {
       this._isMounted = false; 
    }
 
    handleEditorChange = (content, editor) => { 
        this.setState({
            editorValue: content, 
            editorValid: true, 
            errorMsg: "", 
            fieldName:false
        });
    }

    /*discussionReplyDialogOpen = (e, parentID) => { 
        this.setState({ replyEditor: true, parent_id: parentID  })
     }*/
  
     discussionReplyDialogClose = (e) => { 
        this.setState({ showDialogModal: false })
        this.props.onDialogClose(this.state.showDialogModal);  
     }

    submitAnswer = (e) => { 
        e.preventDefault();
        e.stopPropagation();
        const headers = {
            'Content-type': 'application/json'
          } 
     
          const body = {
              'content': this.state.editorValue,
              'forum_id': this.props.threadId,
              'parent_id': this.props.parentId, 
              'user_id' : getID(),
          }  
         // alert(JSON.stringify(body));
          //return false;
          axios.post(apiURL('forum/add_answer'), body, {headers: headers})
          .then(response => {
              const resultData = response.data;
              console.log(resultData);
              if(resultData.status === true)
              {
                this.componentDidMount();
                this.setState({ replyEditor: false });
                this.discussionReplyDialogClose();
                /*this.setState({ 
                    editorValue:'', 
                  })*/
              } 
              else{
                 this._isMounted = true;
                 this.setState({
                   fieldName: resultData.field,
                   errorMsg: resultData.message,
                   showSnackbar: true, 
                   snackBarMessage:  resultData.message,
                   severityColor: 'error',
                   snackBarTimer: 2000
                 })
                 
              }
              //document.getElementById("post-answer").reset(); 
          })
          .catch(error => {
              console.log(error)
          })
     }

     snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
      }

     render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Dialog id="post-answer-container" open={this.state.showDialogModal} fullWidth={true} maxWidth="lg" >
                    <form  id="post-answer" onSubmit={(e) => this.submitAnswer(e)}>
                    <DialogTitle id="form-dialog-title"> Thread Title : {this.props.threadTitle} {/*this.state.forumValues.fldv_title*/} </DialogTitle>
                        <DialogContent  style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Editor 
                                        initialValue={this.state.editorVlaue}
                                        init={{
                                        height: 170,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        initialValue={this.state.editorVlaue}
                                        onEditorChange={(e) => this.handleEditorChange(e)} 
                                    /> 
                                    {this.state.fieldName === 'content'?this.state.errorMsg:null}
                                </Grid> 
                                
                             </Grid>
                        </DialogContent>
                        <DialogActions> 
                            <Button variant="contained" color="secondary" onClick={(e) => this.discussionReplyDialogClose(e)}>  Cancel  </Button>
                            <Button type="submit" style={{ cursor: 'pointer' }} variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
     }

 }

 export default withStyles(styles)(Replydialog);   
