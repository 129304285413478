import React, { Component } from 'react';  
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import {TextField, Grid, InputAdornment, IconButton, FormGroup, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@material-ui/core';
//import PasswordField from 'material-ui-password-field';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";  
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { apiURL } from '../utils'; 
import axios from 'axios';    
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './style.css'; 
import { Formik, Form } from 'formik';
import * as yup from 'yup';  
//import { ETIME } from 'constants';
//import { Link, useHistory  } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert'; 
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import NumberVerified from './NumberVerified';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

 const FormControl = require('@material-ui/core/FormControl').default;
 const FormHelperText = require('@material-ui/core/FormHelperText').default;
 const InputLabel = require('@material-ui/core/InputLabel').default;


 
 
 const styles = theme => ({
    root: {
        '& > *': {
          margin: theme.spacing(0),
          width: '100%',
        },
      },
  });


  
  const signUpSchema = yup.object().shape({
    status: yup.string().required("Please select status."),
    title: yup.string().required("Please select title."),
    name: yup.string().required("This field is required."),
    //middle_name: yup.string().required("This field is required."),
    surname: yup.string().required("This field is required."),
    //number: yup.string().required("This field is required.").test('len', 'Must be exactly 10 characters', val => val.length === 10),
    number: yup.number().required("This field is required."),
    email: yup.string().required("This field is required.").email(),
    subject_taught: yup.string().required("This field is required."),
    choose_school_collage: yup.string().required("This field is required.").nullable(),
    school_collage: yup.string().required("This field is required.").nullable(),
    state: yup.string().required("Please select state.").nullable(),
    city: yup.string().required("Please select city.").nullable(),
    zila: yup.string().required("Please select zila.").nullable(),
    boards: yup.string().required("This field is required."),
    medium: yup.string().required("Please select medium."),
    faculty: yup.string().required("Please select faculty."),
    username: yup.string().required("This field is required."),
    password: yup.string().required("This field is required."),  
    agree: yup.string().required('Please Check, I agree to the Terms & Conditions'),
  }); 


  
  const disabledSignUpSchema = yup.object().shape({
    status: yup.string().required("Please select status."),
    title: yup.string().required("Please select title."),
    name: yup.string().required("This field is required."),
    surname: yup.string().required("This field is required."),
    number: yup.number().required("This field is required."),
    email: yup.string().required("This field is required.").email(),
    state: yup.string().required("Please select state.").nullable(),
    city: yup.string().required("Please select city.").nullable(),
    zila: yup.string().required("Please select zila.").nullable(),
    username: yup.string().required("This field is required."),
    password: yup.string().required("This field is required."),  
    agree: yup.string().required('Please Check, I agree to the Terms & Conditions'),
  }); 
  
class Signup extends Component { 
  _isMounted = false; 
  constructor(props){
    super(props);
    this.state = { 
      checked: false,
      state: null,
      city:null,
      hasError: false,
      stateList: [],
      cityList:[],
      zilaList: [],
      password: "",
      showPassword: false,
      emailError:false,
      emailErrorMsg: "",
      numberError:false,
      numberErrorMsg: "",
      usernameError:false,
      usernameErrorMsg: "",
      showSnackbar: false,
      snackBarMessage: '',
      severityColor: '',
      snackBarTimer:'',

      authMobile: '',
      otpBoxOpen: false,
      contactNumberVerified: false,
      sendOTPString: false,
      mobileNumber: '',
      isNumberVerified: false,
      isNumberVerifiedErrorMsg: '', 
      get_school_collage: '',
      schooolCollageList: [],
      disabledField: "no",
    }
    this.get_state        = this.get_state.bind(this);
    this.get_city         = this.get_city.bind(this);
    this.passwordChange   = this.passwordChange.bind(this);
    this.check_email      = this.check_email.bind(this);
    this.check_number     = this.check_number.bind(this);
    this.sendOTP          = this.sendOTP.bind(this);
    this.otpBoxClose      = this.otpBoxClose.bind(this);
    this.onClickOTPClose  = this.onClickOTPClose.bind(this);
    this.check_username   = this.check_username.bind(this);
  }

    
componentDidMount()
{
  this._isMounted = true;
  this.get_state();
  if(this.props.getSocialResponse){
    this.check_email(this.props.getSocialResponse.email); 
  } 
}

    handleChangeState(value) {
      this.setState({ state: value });
      this.get_city(value); 
      this.get_zila(value);
    }

    choose_school_collage = (value) =>  {
      this.setState({ get_school_collage: value }); 
     // alert(value)
    }

    get_school_collage_key = (key) => {
      //alert(key)
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        const body = {"key": key, "ik" : this.state.get_school_collage}
        axios.post(apiURL('school_collage/get_school_collage'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            console.log(resultData.result);
            if(resultData.status === true || resultData.status === 1)
            { 
              this.setState({ schooolCollageList: resultData.result});
            } 
           /* else{
              this.setState({ emailError: false, emailErrorMsg: ""});
            }*/
          
        }) 
      
    }

    handleChangeCity(value) {
      this.setState({ city: value });
    }

    handleChangeZila(value) {
      this.setState({ zila: value });
    }


    check_email = (email) =>{

      if(email){
      if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)){
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        const body = {"email": email}
        axios.post(apiURL('register/check_email'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
              this.setState({ emailError: true, emailErrorMsg:resultData.message});
            } 
            else{
              this.setState({ emailError: false, emailErrorMsg: ""});
            }
          
        }) 
      }
      else{
        this.setState({ emailError: true, emailErrorMsg:"Please enter valid email address"});
      }
      } 
    }

    check_number = (e) =>{
      const re = /^[0-9\b]+$/;
      if (re.test(e.target.value)) {
      if(e.target.value){
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        const body = {"contact": e.target.value}
        axios.post(apiURL('register/check_number'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
              this.setState({ numberError: true, numberErrorMsg:resultData.message});
            } 
            else{
              this.setState({ numberError: false, numberErrorMsg: ""}); 
            }
          
        }) 
      } 
      }else{
        this.setState({ numberError: true, numberErrorMsg:"Please enter valid only numbers"});
      }
    }

    
    numberLengthValidate = (e) => {
      if(e.target.value.length !== 10){
        this.setState({ isNumberVerified: true, isNumberVerifiedErrorMsg:"Mobile number must be exactly 10 digits"});
      }
      else{
        this.setState({ isNumberVerified: false, isNumberVerifiedErrorMsg: ""}); 
      }
    }


    sendOTPString = (e) => 
    {
      if(e.target.value.length === 10 && !this.state.numberError)
      {
          this.setState({ 
            numberError: false, 
            numberErrorMsg: "", 
            sendOTPString: true, 
            mobileNumber: e.target.value,
            //isNumberVerified: false,
            //isNumberVerifiedErrorMsg: '',
          }); 
      }
      else{
        this.setState({ 
          numberError: false, 
          numberErrorMsg: "", 
          sendOTPString: false, 
          mobileNumber: e.target.value,
          //isNumberVerified: true,
          //isNumberVerifiedErrorMsg: 'Please verify your mobule number!',
        }); 
      }
    }

    sendOTP = (e) => { 
      //alert(e.target.value)
      //const number = document.getElementById('number').value;
      //alert(this.state.mobileNumber)
      if(!this.state.contactNumberVerified && e.target.value.length === 10 && !this.state.numberError){
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        const body = {
          "number": e.target.value
          //"number": this.state.mobileNumber,
        }
        axios.post(apiURL('register/send_otp'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
              this.setState({ 
                authMobile: atob(decodeURIComponent(resultData.result)),
                  otpBoxOpen: true, 
                  sendOTPString: false,
                  mobileNumber: document.getElementById('number').value
                });
              localStorage.setItem('otp', resultData.result); 
            }  
        }) 
      }
      else{
        console.log("number verifed successfully")
        this.setState({ sendOTPString: false});
      }
    }

    otpBoxClose = (event) => { 
      if(event === true){
      this.setState({
          authMobile: '',
          otpBoxOpen: false,
          contactNumberVerified: true,
          sendOTPString: false, 
          isNumberVerified: false,
          isNumberVerifiedErrorMsg: '',
      })
      }
      /*else{
        this.setState({
          authMobile: '',
          otpBoxOpen: false,
          contactNumberVerified: false,
          sendOTPString: false
      })
      }*/
    }

    onClickOTPClose = (event) => { 
      if(event === true){
        this.setState({
          authMobile: '',
          otpBoxOpen: false,
          contactNumberVerified: false,
          isNumberVerified: true,
          isNumberVerifiedErrorMsg: 'Please verify mobile number',
          //sendOTPString: false
        })
      } 
    }

    changeNumber = (event) => {
      if(event === true){
        this.setState({
          authMobile: '',
          otpBoxOpen: false,
          contactNumberVerified: false,
          isNumberVerified: false,
          isNumberVerifiedErrorMsg: '',
          //sendOTPString: false
        })
      } 
    }

    
    check_username = (e) =>{
      if(e.target.value){
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        const body = {"username": e.target.value}
        axios.post(apiURL('register/check_username'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
              this.setState({ usernameError: true, usernameErrorMsg:resultData.message});
            } 
            else{
              this.setState({ usernameError: false, usernameErrorMsg: ""});
            }
          
        }) 
      } 
    }


    get_state = () =>{
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        axios.get(apiURL('common/get_state'), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({ stateList: resultData.result});
            } 
          
        })
        .catch(error => {
            console.log(error)
        })  
    }

    
    get_city = (stateId) =>{
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        axios.get(apiURL('common/get_city/'+stateId), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({ cityList: resultData.result});
            } 
          
        })
        .catch(error => {
            console.log(error)
        })  
    }

    
    get_zila = (stateId) =>{
      const headers = {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json'
        }
        axios.get(apiURL('common/get_zila/'+stateId), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({ zilaList: resultData.result});
            } 
          
        })
        .catch(error => {
            console.log(error)
        })  
    }

    passwordChange = (event) => {
      this.setState({ password: event.target.value })
      
    };

    handleClickShowPassword = (event) => {
      this.setState({ showPassword: !this.state.showPassword })
    };

    handleMouseDownPassword = (event) => {
      event.preventDefault();
      this.setState({ showPassword: !this.state.showPassword })
    };

    componentWillUnmount() {
      this._isMounted = false; 
    }
    

    handleCheckboxChange = event => {
      this.setState({ checked: event.target.checked }) 
      if(event.target.checked === false)
      {
        this.setState({
          checkBoxError: true,
          checkBoxErrorMsg: "Please Check, I agree to the Terms & Conditions"
        })
      }
      else{
        this.setState({
          checkBoxError: false,
          checkBoxErrorMsg: ""
        })
      }
    }


    handleClick() {
      this.setState({ hasError: false });
      if (!this.state.selected) {
        this.setState({ hasError: true });
      }
    }

    onSubmit = (values) => { 
      const headers = {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json'
      }
      const body = { 
          "name": values.name, "email": values.email, "contact": values.number,
          "subject": values.subject_taught, "sch_col": values.school_collage,
          "city": values.city, "state": values.state,
          "username": values.username, "password": values.password,
          "source": (this.props.getSocialResponse.source)?this.props.getSocialResponse.source:"web",
          "origin":(window.location.origin.toString()),
          "status" : values.status,
          "title" : values.title,
          "surname" : values.surname,
          "middle_name": values.middle_name,
          "choose_school_collage" : values.choose_school_collage,
          "zila" : values.zila,
          "boards" : values.boards,
          "medium" : values.medium,
          "faculty" : values.faculty,
        }
      axios.post(apiURL('register'),body, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          { 
            this.props.setState(0);   
            
            this.setState({ snackBarMessage: "Please confirm your email id by clicking on the Activation link shared on your the email.",showSnackbar: true , severityColor:'success', snackBarTimer: 10000});
              /*
              this.props.propsData.history.push('/sign');
              const history = useHistory();
              history.push("/sign");
              //alert("register succfully");*/
          }
          else{
            this.setState({ snackBarMessage: resultData.message,showSnackbar: true , severityColor:'error'});
          }  
        
      })
      .catch(error => {
          console.log(error)
      })  
    }

    snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
        this.setState({ showSnackbar: false }); 
    }

    handleChangeStatus = (value) => {
      if(value === "Others")
      {
        this.setState({ disabledField: "yes"})
      }
      else{
        this.setState({ disabledField: "no"})
      }
    }
 


    render() {
        const { classes } = this.props;
        //const { selected, hasError , selected1} = this.state;
        const filter = createFilterOptions();

      return (
              <React.Fragment> 
                <Formik
                  initialValues={{
                    status: "",
                    title: "",
                    name: (this.props.getSocialResponse)?this.props.getSocialResponse.name: "",
                    middle_name: "",
                    surname: "",
                    number: "",
                    email: (this.props.getSocialResponse)?this.props.getSocialResponse.email: "",
                    subject_taught: "",
                    choose_school_collage: "",
                    school_collage: "",
                    state: "",
                    city: "",
                    zila: "",
                    boards: "",
                    medium: "",
                    faculty: "",
                    username: "",
                    password: "",  
                    agree: "",
                  }}
                  validationSchema={(this.state.disabledField === "yes")?disabledSignUpSchema:signUpSchema}
                  onSubmit={values => { 
                    //alert(JSON.stringify(values));
                    /*if(!this.state.checkBoxError && !this.state.isNumberVerified && this.state.emailError)
                    {
                      this.onSubmit(values)
                      alert(JSON.stringify(values));
                      console.log(JSON.stringify(values)); 
                    } */
                    (!this.state.checkBoxError && !this.state.isNumberVerified && !this.state.numberError && !this.state.emailError)?this.onSubmit(values):console.log("error");
                    //(!this.state.checkBoxError && !this.state.isNumberVerified && !this.state.numberError && !this.state.emailError)?alert(JSON.stringify(values)):alert("error");
                  }}
                >
                {({ errors, handleChange, touched, setFieldValue }) => ( 
                <Form id="login" className={classes.root}>
                  <Grid container> 
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.status && touched.status} className="mt-4">
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select type="text" name="status" value={this.state.status} onChange={event => this.handleChangeStatus(event.target.value)} input={<Input id="status" />} >
                          <MenuItem value="Principal">Principal</MenuItem>
                          <MenuItem value="Teacher">Teacher</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                        <FormHelperText>{errors.status && touched.status ? errors.status : null}</FormHelperText>
                      </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={12}> 
                      <Grid container>
                      <Grid item xs={2} sm={2}>
                          <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.title && touched.title} className="mt-4">
                            <InputLabel htmlFor="status">Title</InputLabel>
                            <Select type="text" name="title" value={this.state.status} onChange={event => this.handleChangeState(event.target.value)} input={<Input id="title" />} >
                              <MenuItem value="Mr">Mr</MenuItem>
                              <MenuItem value="Mrs">Mrs</MenuItem>
                              <MenuItem value="Miss">Miss</MenuItem>
                            </Select>
                            <FormHelperText>{errors.title && touched.title ? errors.title : null}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={10} sm={10}> 
                          <TextField type="text" fullWidth variant="standard" className="mt-4" name="name" label="First Name" value={(this.props.getSocialResponse)?this.props.getSocialResponse.name:this.state.name}  onChange={handleChange} error={errors.name && touched.name} helperText={errors.name && touched.name ? errors.name : null} />
                        </Grid>
                      </Grid>
                    </Grid> 


                    {/*<Grid item xs={12} sm={12}> 
                        <TextField type="text" fullWidth variant="standard" className="mt-4" name="middle_name" label="Middle Name" value={this.state.middle_name}  onChange={handleChange} error={errors.middle_name && touched.middle_name} helperText={errors.middle_name && touched.middle_name ? errors.middle_name : null} />
                    </Grid>*/}

                    <Grid item xs={12} sm={12}> 
                        <TextField type="text" fullWidth variant="standard" className="mt-4" name="middle_name" label="Middle Name" value={this.state.middle_name}  onChange={handleChange}  />
                    </Grid>

                    
                    <Grid item xs={12} sm={12}> 
                        <TextField type="text" fullWidth variant="standard" className="mt-4" name="surname" label="Last Name" value={this.state.surname}  onChange={handleChange} error={errors.surname && touched.surname} helperText={errors.surname && touched.surname ? errors.surname : null} />
                    </Grid>

                    <Grid item xs={12} sm={12}>  
                      <TextField type="text" fullWidth variant="standard"  className="mt-4" id="number" name="number" label="Mobile No." value={this.state.number} onChange={handleChange} onBlur={(e) => {this.check_number(e); this.sendOTP(e); this.numberLengthValidate(e)}} onKeyUp={(e) => this.check_number(e)} error={errors.number && touched.number || this.state.numberError || this.state.isNumberVerified } helperText={errors.number && touched.number ? errors.number : null || this.state.numberErrorMsg || this.state.isNumberVerifiedErrorMsg} />
                      
                      {/*{this.state.sendOTPString && !this.state.contactNumberVerified && !this.state.numberError?<div className="w-100 small" style={{color:"green", fontWeight:"bold"}}>Please Verify your mobile number on click <span onClick={(e) => this.sendOTP(e)} style={{cursor:"pointer"}}>send OTP</span> </div>:""}*/}
                      {this.state.contactNumberVerified?<div className="w-100 small" style={{color:"green", fontWeight:"bold"}}>Mobile number verified successfully</div>:""}
                    </Grid>

                    <Grid item xs={12} sm={12}>  
                      <TextField type="text"  fullWidth variant="standard" className="mt-4" name="email" label="Email Address" value={(this.props.getSocialResponse)?this.props.getSocialResponse.email:this.state.email} onChange={handleChange} onBlur={(e) => this.check_email(e.target.value)} error={errors.email && touched.email || this.state.emailError} helperText={errors.email && touched.email ? errors.email : null || this.state.emailErrorMsg}  />
                    </Grid>

                    <Grid item xs={12} sm={12}> 
                      {
                        (this.state.disabledField === "yes")?
                        <TextField disabled type="text"  fullWidth variant="standard" className="mt-4" name="subject_taught" label="Subject taught" value="" />:
                        <TextField type="text"  fullWidth variant="standard" className="mt-4" name="subject_taught" label="Subject taught" value={this.state.subject_taught} onChange={handleChange} error={errors.subject_taught && touched.subject_taught} helperText={errors.subject_taught && touched.subject_taught ? errors.subject_taught : null} />
                      } 
                    </Grid>

                    <Grid item xs={12} sm={12}>
                    {
                      (this.state.disabledField === "yes")?
                            <FormControl fullWidth variant="standard" disabled className="mt-4">
                              <InputLabel htmlFor="choose_school_collage">School or College</InputLabel>
                              <Select type="text" name="choose_school_collage" value="" input={<Input id="choose_school_collage" />} >
                                <MenuItem value="School">School</MenuItem>
                                <MenuItem value="College">College</MenuItem>
                              </Select> 
                            </FormControl>:
                            <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.choose_school_collage && touched.choose_school_collage} className="mt-4">
                            <InputLabel htmlFor="choose_school_collage">School or College</InputLabel>
                            <Select type="text" name="choose_school_collage" onChange={(e) => this.choose_school_collage(e.target.value)} value={this.state.choose_school_collage}  input={<Input id="choose_school_collage" />} >
                              <MenuItem value="School">School</MenuItem>
                              <MenuItem value="College">College</MenuItem>
                            </Select>
                            <FormHelperText>{errors.choose_school_collage && touched.choose_school_collage ? errors.choose_school_collage : null}</FormHelperText>
                          </FormControl>
                        }
                    </Grid>


                    <Grid item xs={12} sm={12}>
                      {
                        (this.state.disabledField === "yes")?
                        <FormControl disabled fullWidth variant="standard" className="mt-4">
                        <Autocomplete disabled
                          id="free-solo-demo"
                          freeSolo 
                          options={this.state.schooolCollageList.map((option) => option.title)}  
                          renderInput={(params) => (
                            <TextField {...params} label="School Name/College Name" onChange={(e) => this.get_school_collage_key(e.target.value)} name="school_collage" margin="normal" variant="standard"/>
                          )}
                        /> 
                      </FormControl>:
                      <FormControl fullWidth variant="standard" className="mt-4" onBlur={handleChange}  >
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo onBlur={handleChange}
                            options={this.state.schooolCollageList.map((option) => option.title)}  
                            renderInput={(params) => (
                              <TextField {...params} label="School Name/College Name" onChange={(e) => this.get_school_collage_key(e.target.value)} name="school_collage" margin="normal" variant="standard" error={errors.school_collage && touched.school_collage}  helperText={errors.school_collage && touched.school_collage ? errors.school_collage : null}/>
                            )}
                          /> 
                        </FormControl> 
                        }
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.state && touched.state} className="mt-4">
                        <InputLabel htmlFor="state">State</InputLabel>
                        <Select type="text" name="state" value={this.state.state} onChange={event => this.handleChangeState(event.target.value)} input={<Input id="state" />} >
                          <MenuItem value="">Select State</MenuItem>
                          {
                            this.state.stateList.map((arrState, index) => {
                              return <MenuItem key={arrState.id} value={arrState.id}>{arrState.name}</MenuItem>
                          })
                          } 
                        </Select>
                        <FormHelperText>{errors.state && touched.state ? errors.state : null}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth variant="standard" onBlur={handleChange}  error={errors.city && touched.city} className="mt-4">
                        <InputLabel  htmlFor="city">City</InputLabel>
                          <Select  type="text" name="city"  value={this.state.city} onChange={event => this.handleChangeCity(event.target.value)} input={<Input id="city" />} >
                          <MenuItem value="">Select City</MenuItem>
                          {
                            this.state.cityList.map((arrCity, index) => {
                              return <MenuItem key={arrCity.id} value={arrCity.id}>{arrCity.city}</MenuItem>
                          })
                          } 
                          </Select>
                        <FormHelperText>{errors.city && touched.city ? errors.city : null}</FormHelperText>
                      </FormControl>
                    </Grid>

                    

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth variant="standard" onBlur={handleChange}  error={errors.zila && touched.zila} className="mt-4">
                        <InputLabel  htmlFor="zila">Zila</InputLabel>
                          <Select  type="text" name="zila"  value={this.state.zila} onChange={event => this.handleChangeZila(event.target.value)} input={<Input id="zila" />} >
                          <MenuItem value="">Select Zila</MenuItem>
                          {
                            this.state.zilaList.map((arrZila, index) => {
                              return <MenuItem key={arrZila.id} value={arrZila.id}>{arrZila.name}</MenuItem>
                          })
                          } 
                          </Select>
                        <FormHelperText>{errors.zila && touched.zila ? errors.zila : null}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {
                        (this.state.disabledField === "yes")?
                        <FormControl disabled fullWidth variant="standard" className="mt-4">
                        <InputLabel htmlFor="boards">Board</InputLabel>
                        <Select type="text" name="boards" value="" input={<Input id="boards" />} >
                          <MenuItem value="SSC">SSC</MenuItem>
                          <MenuItem value="CBSC">CBSC</MenuItem>
                          <MenuItem value="ICSE">ICSE</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select> 
                          </FormControl>:
                          <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.boards && touched.boards} className="mt-4">
                          <InputLabel htmlFor="boards">Board</InputLabel>
                          <Select type="text" name="boards" value={this.state.boards} onChange={event => this.handleChangeState(event.target.value)} input={<Input id="boards" />} >
                            <MenuItem value="SSC">SSC</MenuItem>
                            <MenuItem value="CBSC">CBSC</MenuItem>
                            <MenuItem value="ICSE">ICSE</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                          <FormHelperText>{errors.boards && touched.boards ? errors.boards : null}</FormHelperText>
                        </FormControl>
                      } 
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {
                        (this.state.disabledField === "yes")?
                        <FormControl disabled fullWidth variant="standard" className="mt-4">
                            <InputLabel htmlFor="medium">Medium</InputLabel>
                            <Select type="text" name="medium" value="" input={<Input id="medium" />} >
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </Select> 
                          </FormControl>:
                        <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.medium && touched.medium} className="mt-4">
                          <InputLabel htmlFor="medium">Medium</InputLabel>
                          <Select type="text" name="medium" value={this.state.medium} input={<Input id="medium" />} >
                            <MenuItem value="Marathi">Marathi</MenuItem>
                            <MenuItem value="English">English</MenuItem>
                            <MenuItem value="Hindi">Hindi</MenuItem>
                            <MenuItem value="Urdu">Urdu</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                          <FormHelperText>{errors.medium && touched.medium ? errors.medium : null}</FormHelperText>
                        </FormControl>    
                      }
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {
                        (this.state.disabledField === "yes")?
                        <FormControl disabled fullWidth variant="standard" className="mt-4">
                        <InputLabel htmlFor="faculty">Faculty</InputLabel>
                        <Select type="text" name="faculty" value="" input={<Input id="faculty" />} >
                          <MenuItem value="Primary">Primary</MenuItem>
                          <MenuItem value="Secondry">Secondry</MenuItem>
                          <MenuItem value="Junior College">Junior College</MenuItem>
                        </Select> 
                        </FormControl>:
                        <FormControl fullWidth variant="standard" onBlur={handleChange} error={errors.faculty && touched.faculty} className="mt-4">
                        <InputLabel htmlFor="faculty">Faculty</InputLabel>
                        <Select type="text" name="faculty" value={this.state.faculty} input={<Input id="faculty" />} >
                          <MenuItem value="Primary">Primary</MenuItem>
                          <MenuItem value="Secondry">Secondry</MenuItem>
                          <MenuItem value="Junior College">Junior College</MenuItem>
                        </Select>
                        <FormHelperText>{errors.faculty && touched.faculty ? errors.faculty : null}</FormHelperText>
                      </FormControl>
                      }
                      
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                      <TextField type="text" fullWidth variant="standard"  className="mt-4" name="username" label="Username" value={this.state.username} onChange={handleChange} onBlur={(e) => this.check_username(e)} error={errors.username && touched.username || this.state.usernameError} helperText={errors.username && touched.username ? errors.username : null || this.state.usernameErrorMsg} />
                    </Grid>
 
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth className="mt-4"> 
                          <TextField label='Password' variant="standard"
                              type={this.state.showPassword ? "text" : "password"}  id="password"
                              onChange={(e) => this.passwordChange(e, 'password')} onBlur={handleChange} onKeyUp={handleChange} onKeyDown={handleChange} 
                              error={errors.password && touched.password}  helperText={errors.password && touched.password ? errors.password : null} 
                              InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={(e) => this.handleClickShowPassword(e)} onMouseDown={(e) => this.handleMouseDownPassword(e)} >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                        </FormControl>
                    </Grid>
                     
                    <Grid item xs={12} sm={12}> 
                      <FormControl required error={errors.agree && touched.agree || this.state.checkBoxError} component="fieldset" className="mt-4" size="small"> 
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={this.state.checked} onClick={this.handleCheckboxChange} onKeyUp={handleChange} onKeyDown={handleChange} onChange={handleChange} name="agree" />}
                            label="I agree to the Terms & Conditions"
                          />  
                        </FormGroup>
                        <FormHelperText> {errors.agree && touched.agree ? errors.agree : null || (this.state.checkBoxError)?this.state.checkBoxErrorMsg:""}</FormHelperText>
                      </FormControl>
                    </Grid>
                
                    <Grid item xs={12} sm={12}>
                      <Button type="submit" fullWidth variant="contained" color="primary" className="mt-4 text-capitalize" > Create New Account  </Button>
                    </Grid>  
                  </Grid>
                </Form>
                )}
                </Formik>
                
                <Snackbar anchorOrigin={{  vertical: "top", horizontal: "center" }} open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}  key={{  vertical: "top" ,  horizontal: "center" }}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>

                <NumberVerified otpBoxOpen={this.state.otpBoxOpen} otpValue={this.state.authMobile} onClickOTPClose={(e) => this.onClickOTPClose(e)} otpBoxClose={(e) => this.otpBoxClose(e)} mobileNumber={this.state.mobileNumber} changeNumber={(e) => this.changeNumber(e)}/>
              </React.Fragment>
      );
    }
  }

  Signup.propTypes = {
    classes: PropTypes.object.isRequired
  };

  export default withStyles(styles)(Signup);