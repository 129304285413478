import React, { Component } from 'react';  
import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import PasswordField from 'material-ui-password-field';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";  
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { apiURL } from '../utils'; 
import axios from 'axios';   
import { Grid} from '@material-ui/core';   
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
const FormControl = require('@material-ui/core/FormControl').default;
const FormHelperText = require('@material-ui/core/FormHelperText').default;
const InputLabel = require('@material-ui/core/InputLabel').default;
const styles = theme => ({
root: {
'& > *': {
margin: theme.spacing(0),
width: '100%',
},
},
});
class HelpUsForm extends Component { 
_isMounted = false; 
constructor(props){
super(props);
this.state = { 
checked: false,
selected: null,
selected1:null,
hasError: false,
stateList: [],
}
this.get_state = this.get_state.bind(this);
}
componentDidMount()
{
this._isMounted = true;
this.get_state();

window.scrollTo(0, 0);

}
get_state = () =>{
const headers = {
'content-type': 'multipart/form-data',
'Accept': 'application/json'
}
axios.get(apiURL('common/get_state'), {headers: headers})
.then(response => {
const resultData = response.data; 
if(resultData.status === true || resultData.status === 1)
{ 
this.setState({ stateList: resultData.result});
} 
})
.catch(error => {
console.log(error)
})  
}
componentWillUnmount() {  
this._isMounted = false; 
}
handleCheckboxChange = event => {
this.setState({ checked: event.target.checked })
}
handleChange(value) {
this.setState({ selected: value });
}
// handleChangeCity(value) {
// this.setState({ selected1: value });
// }
handleClick() {
this.setState({ hasError: false });
if (!this.state.selected) {
this.setState({ hasError: true });
}
}
// handleClickCity() {
// this.setState({ hasError: false });
// if (!this.state.selected1) {
// this.setState({ hasError: true });
// }
// }
render() {
const { classes } = this.props;
const { selected, hasError , selected1} = this.state;
return (
<React.Fragment>
   <form className="careerForm feedBackForm" noValidate autoComplete="off" >
      <Grid container>
         {/* <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl} error={hasError} className="mt-4">
               <InputLabel  className="ml-3"  htmlFor="city">Career Guideline </InputLabel>
               <Select  
                  type="text"
                  name="careerguideline"
                  value={selected}
                  onChange={event =>
                  this.handleChange(event.target.value)}
                  input={<Input id="filled-basic"  variant="filled"  />}
                  >
                  <MenuItem  value="mumbai">Mumbai</MenuItem>
                  <MenuItem  value="banglore">Banglore</MenuItem>
               </Select>
               {hasError && 
               <FormHelperText>This is required!</FormHelperText>
               }
            </FormControl>
         </Grid> */}
         <Grid item xs={12} sm={12}>
            <TextField id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="name" label="Name" />
         </Grid>
         <Grid item xs={12} sm={12}>
            <TextField id="filled-basic" autocomplete="off"  variant="filled" type="text" className="mt-4" name="email" label="Email" />
         </Grid>
         <Grid item xs={12} sm={12}>
            <TextField id="filled-basic" autocomplete="off"  variant="filled" className="mt-4" name="mobile number" label="Mobile No." />
         </Grid>
         <Grid item xs={12} sm={12}>
            <TextareaAutosize
               rowsMax={4}
               className="w-100 mt-4 textAreaBox"
               aria-label="maximum height"
               placeholder="Maximum 4 rows"
               defaultValue="Message"
               />
         </Grid>
         {/* 
         <Grid item xs={12} sm={12} className="text-center">
            <label className="mt-4">
               <Checkbox
                  checked={this.state.checked}
                  onChange={this.handleCheckboxChange}
                  />
               <span style={{ marginLeft: 8 }}>I accept <span className="colorBox"> Terms & Conditions </span></span>
            </label>
         </Grid>
         */}
         <Grid item xs={12} sm={12} > 
            <Button type="submit"    className=" CheckButton text-center mt-3" >
            SUBMIT
            </Button>
         </Grid>
      </Grid>
   </form>
</React.Fragment>
);
}
}
HelpUsForm.propTypes = {
classes: PropTypes.object.isRequired
};
export default withStyles(styles)(HelpUsForm);