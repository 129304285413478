import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import {  Container,  Row, Col, Card  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';    
import "../style/training.scss";
import "../style/Blog.scss";    
import { apiURL, getID } from '../utils';   
import SanitizedHTML from 'react-sanitized-html';
import axios from 'axios'; 
import Moment from 'moment';  
import Meta from '../meta/Meta'; 

class Blog extends Component {

   constructor(props) {
      super(props);
      this.state = {
         activeTab: 0,
         blogData: [],
         noData: ''
      }; 
      this.blogListData = this.blogListData.bind(this)
   }
   componentDidMount() { 
      this._isMounted = true; 
      this.blogListData(); 
      window.scrollTo(0, 0);
  }

  componentWillUnmount() {
      this._isMounted = false; 
  }


  blogListData = () => {
      this.setState({ loading: true }); 
      const headers = { 
          'Content-type': 'application/json'
      }
      const body = { "id": ""}
      axios.post(apiURL('blog/get_all_blog'), body, {headers: headers})
      .then(response => {
          
      if(this._isMounted){
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          { 
              this.setState({  
               blogData: resultData.result, 
              });
              //this.setState({ loading: false  });
          }
          else{
              this.setState({noData: "Coming Soon"}); 
          }
      }
      })
  
      .catch(error => {
          console.log(error)
      }) 
  }
render() {
return (
<React.Fragment>
   <Meta tableName={"menu"} objectId={16} forPage={"main_page"} />
   <Appbar />
   {/* <div className="NavBar   pb-0 sticky-top ">
      <Navmenu />
   </div> */}
   <div className="sticky-top sign-light">
         <Navmenu />    
    </div>  
   <section className="BannerBox discussionBanner blogs">
      <div className="sixteen-nine">
         <div className="content text-center text-white invisible">
            <h2>Blogs</h2>
            <p>lorem ipsume</p>
         </div>
      </div>
   </section>
   <section className="careerMain eventMain">
      <Container>
         <Row className="Blogbox careerBox EventBox  row-Box">
         
         {this.state.blogData.map((arrBlogs, index) =>
            <Col col="sm-12 md-6 lg-4" key={index}>
            <Link target="blank" to={'blog/'+arrBlogs.fldi_id+'/'+arrBlogs.fldv_title.replace(/ /g, '-').toLowerCase()}>
            <Card h="100" >
               <div className="position-relative clearfix">
                  <figure>  
                     <img src={arrBlogs.fldv_image_url} className="img-fluid " alt={arrBlogs.fldv_title}/>  
                  </figure>
               </div>
               <Card.Body>
                  <Card.Text>
                     <div className="headBox text-center ">
                     <h5>{arrBlogs.fldv_title}</h5>
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer  className="text-center d-none">
                  <h5 > 
                     {Moment(arrBlogs.fldv_selected_date).format('D MMMM, YYYY')}
                  </h5>
               </Card.Footer>
            </Card>
            </Link>
            </Col> 
             )} 
            
            {/*<Col col="sm-12 md-4">
            <Card  h="100" >
               <div className="position-relative clearfix">
                  <figure>  
                     <img src="../images/article-2.png" className="img-fluid " alt=""/>  
                  </figure>
               </div>
               <Card.Body>
                  <Card.Text>
                     <div className="headBox text-center ">
                        <h5 >Top aide possible contender forced to resign over creepy Top aide possible contender forced to resign over creepy.</h5>
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer  className="text-center">
                  <h5 >
                     20 March , 2020
                  </h5>
               </Card.Footer>
            </Card>
            </Col>  
            <Col col="sm-12 md-4">
            <Card h="100" >
               <div className="position-relative clearfix">
                  <figure>  
                     <img src="../images/article-3.png" className="img-fluid " alt=""/>  
                  </figure>
               </div>
               <Card.Body>
                  <Card.Text>
                     <div className="headBox text-center ">
                        <h5 >Top aide possible contender forced to resign over creepy.</h5>
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer  className="text-center">
                  <h5 >
                     20 March , 2020
                  </h5>
               </Card.Footer>
            </Card>
            </Col>   
            <Col col="sm-12 md-4">
            <Card  h="100">
               <div className="position-relative clearfix">
                  <figure>  
                     <img src="../images/article-4.png" className="img-fluid " alt=""/>  
                  </figure>
               </div>
               <Card.Body>
                  <Card.Text>
                     <div className="headBox text-center ">
                        <h5 >Top aide possible contender forced to resign over creepy.</h5>
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer  className="text-center">
                  <h5 >
                     20 March , 2020
                  </h5>
               </Card.Footer>
            </Card>
            </Col>   
            <Col col="sm-12 md-4">
            <Card h="100" >
               <div className="position-relative clearfix">
                  <figure>  
                     <img src="../images/article-5.png" className="img-fluid " alt=""/>  
                  </figure>
               </div>
               <Card.Body>
                  <Card.Text>
                     <div className="headBox text-center ">
                        <h5>Top aide possible contender forced to resign over creepy. Top aide possible contender forced to resign over creepy.</h5>
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer  className="text-center">
                  <h5 >
                     20 March , 2020
                  </h5>
               </Card.Footer>
            </Card>
            </Col>   
            <Col col="sm-12 md-4">
            <Card h="100">
               <div className="position-relative clearfix">
                  <figure>  
                     <img src="../images/article-6.png" className="img-fluid " alt=""/>  
                  </figure>
               </div>
               <Card.Body>
                  <Card.Text>
                     <div className="headBox text-center ">
                        <h5 >Top aide possible contender forced to resign over creepy.</h5>
                     </div>
                  </Card.Text>
               </Card.Body>
               <Card.Footer  className="text-center">
                  <h5 >
                     20 March , 2020
                  </h5>
               </Card.Footer>
            </Card>
            </Col> */}  
         </Row>
         <h3 className="text-center d-block w-100 mt-4">{this.state.noData}</h3>
      </Container>
   </section>
   <Footer />
</React.Fragment>
);
}
}
export default Blog;