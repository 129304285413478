import React, { Component } from 'react';  
import { Alert } from 'bootstrap-4-react';
import { Collapse, Card, Row, Col, Container } from 'bootstrap-4-react';


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';  
import styles from "../style/Header.scss";  

import Lightbox from 'react-image-lightbox';    
import 'react-image-lightbox/style.css';

const images = [
    '//placekitten.com/1500/500',
    '//placekitten.com/4000/3000',
    '//placekitten.com/800/1200',
    '//placekitten.com/1500/1500',
  ];


const imgs = [{author:"The Lazy Artist Gallery", tag:"People", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/1.jpg?raw=true"},
              {author:"Daria Shevtsova", tag:"Food", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/2.jpg?raw=true"},
              {author:"Kasuma", tag:"Animals", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/3.jpg?raw=true"},
              {author:"Dominika Roseclay", tag:"Plants", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/4.jpg?raw=true"},
              {author:"Scott Webb", tag:"Plants", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/5.jpg?raw=true"},
               {author:"Jeffrey Czum", tag:"People", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
              {author:"Dominika Roseclay", tag:"Food", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/7.jpg?raw=true"},
              {author:"Valeria Boltneva", tag:"Animals", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/8.jpg?raw=true"}
];

const filters = [
    {name:"People", status: false},
    {name:"Animals", status: false},
    {name:"Plants", status: false},
    {name:"Food", status: false}
  ];

  const Filters = ({onClickAll, all, onClick, filters}) =>
  <form>
    <ul>
      <li onClick={onClickAll}>
        <input 
          type="checkbox"
          checked={all}
        />
        <label htmlFor="all">All</label>
      </li>
       {filters.map(
        (filter, i)=>
          <li  key={i} data-index={i} onClick={onClick} >
            <input 
              id={filter.name} 
              type="checkbox" 
              checked={filter.status}
            />
            <label htmlFor={filter.name}>{filter.name}</label>
          </li>)}
    </ul>
  </form>

const Cards = ({imgs}) =>
  <ul>
    {imgs.map(
      (img, i)=>
      <li  key={i}>
        <figure>
          <img src={img.src} alt={img.author}/>
          <figcaption> 
            <div>{img.author} </div>
            <span>{img.tag}</span>
          </figcaption>
        </figure>
      </li>)}
  </ul>






class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      imgs, 
      filters,
      all: true, 
      photoIndex: 0,
      isOpen: false, 
      images: [{author:"The Lazy Artist Gallery", tag:"People", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/1.jpg?raw=true"},
      {author:"Daria Shevtsova", tag:"Food", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/2.jpg?raw=true"},
      {author:"Kasuma", tag:"Animals", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/3.jpg?raw=true"},
      {author:"Dominika Roseclay", tag:"Plants", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/4.jpg?raw=true"},
      {author:"Scott Webb", tag:"Plants", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/5.jpg?raw=true"},
       {author:"Jeffrey Czum", tag:"People", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
      {author:"Dominika Roseclay", tag:"Food", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/7.jpg?raw=true"},
      {author:"Valeria Boltneva", tag:"Animals", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/8.jpg?raw=true"}
    ] ,
    imageSource: null,
    }
    this.lightBoxOpen = this.lightBoxOpen.bind(this);
}





// const [open, setOpen] = React.useState(false);


handleClickOpen = () => {
  this.setState({ open: true })
}



handleClose = () => {
  this.setState({ open: false })
}





setFilter = (e) =>{
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;
   
    filters[index].status = !filters[index].status;
    this.setState({
      filters
    });
    
    this.updateFilters();
    this.updateImgs();
  }
  
  setAll = () =>{
    const {filters} = this.state;
    
    filters.forEach( 
      filter => {
        filter.status = false;
      }
    );
    
    this.setState({
      all: true,
      filters
    });
  }
  
  updateFilters(){
    const allFiltersTrue = filters.every( filter => filter.status === true);
    const allFiltersFalse = filters.every( filter => filter.status === false);
    
    if(allFiltersTrue||allFiltersFalse){
      this.setAll();
    }else{
      this.setState({
        all: false
      });
    } 
  }
  
  updateImgs() {
    const { filters, all } = this.state;
    let newImgs = [];
    let a = 0;
    
    imgs.forEach((img, imgKey) => { 
      filters.forEach((filter, filterKey)=> {  
        if((img.tag==filter.name)&&(filter.status==true)){
          newImgs[a] = img;
          a++;
        }
      })
    });
        
    this.setState({
      imgs: newImgs
    });
  }


  lightBoxOpen = (e, imageSource) => {
      e.preventDefault();
      //alert(imageSource);
      this.setState({ isOpen: true, imageSource: imageSource });
  }


    render() {

        const {filters, all, photoIndex, isOpen} = this.state;  


      return (
        <React.Fragment>

          <Container>
            <Row>
              <Col>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type</p>
              </Col>
            </Row>
          </Container>


          <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>  
        Open alert dialog
      </Button>
      <Dialog
        open={this.state.open}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            Disagree
          </Button>
          <Button onClick={() => this.handleClose()} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>



      <div>
        <Filters 
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters} />
        {(all)?(
            <ul>    
                {this.state.images.map((arrImg, index) => {
                    return <li  key={index} onClick={(e) => this.lightBoxOpen(e, arrImg.src)} >
                    <figure>
                    <img src={arrImg.src} alt={arrImg.author}/>
                    <figcaption> 
                        <div>{arrImg.author} </div>
                        <span>{arrImg.tag}</span>
                    </figcaption>
                    </figure>
                </li>
                })} 
            </ul>
          ):(
            <ul>
            {this.state.images.map((arrImg, index) => {
                    return <li  key={index} onClick={(e) => this.lightBoxOpen(e, arrImg.src)} >
                    <figure>
                    <img src={arrImg.src} alt={arrImg.author}/>
                    <figcaption> 
                        <div>{arrImg.author} </div>
                        <span>{arrImg.tag}</span>
                    </figcaption>
                    </figure>
                </li>
                })} 
            </ul>
        )}  

        
     
      </div>

      {isOpen && (
        <Lightbox
            mainSrc={this.state.imageSource}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}


     


        {/*<button type="button" onClick={() => this.setState({ isOpen: true })}>
          Open Lightbox
        </button>*/}

        <button type="button" onClick={(e) => this.lightBoxOpen(e)} >
          Open Lightbox
        </button>
 
    
        </React.Fragment>
      );
    }
  }


  export default Header;





// // import React from 'react';
// // // import {loadFlickrPublicFeed, loadYandexPublicFeed} from '../actions/photos';
// // // import {loadFlickrPublicFeed, loadYandexPublicFeed} from '../actions/photos';
// // import {photoStore} from '../stores';   
// // // import FeedView from './FeedView';   
// // import "../style/Glimpses.scss";  
// // import FeedView from '../glimpses/FeedView';

   
// // class Header extends React.Component {
// //     constructor() {
// //         super();

// //         this.state = {
// //             photos : photoStore.getAll()
// //         };
// //     }

// //     componentDidMount() {
// //         photoStore.addChangeListener(this.change);

// //         // loadYandexPublicFeed();
// //         // loadFlickrPublicFeed();
// //     }

// //     componentWillUnmount() {
// //         photoStore.removeChangeListener(this.change);
// //     }

// //     change = () => {
// //         this.setState({
// //             photos : photoStore.getAll()
// //         })
// //     };


// //     render() {
// //         const {photos} = this.state;
// //         return (
// //             <div>
// //                 <h1>PUBLIC FEED <small>from Flickr, Yandex</small></h1>
// //                 <FeedView photos={photos}/> 
                
// //             </div>
// //         );
// //     }
// // }

// // export default Header;


// import React, { Component } from 'react'    
// import ReactDOM from 'react-dom';
// import "../style/Header.scss";  

// class Panel extends React.Component {
// 	constructor(props) {
// 		super(props);
		
// 		this.state = {
// 			height: 0
// 		};
// 	}

// 	componentDidMount() {
// 		window.setTimeout(() => {
// 			const el = ReactDOM.findDOMNode(this);
// 			const height = el.querySelector('.panel__inner').scrollHeight;
// 			this.setState({
// 				height
// 			});
// 		}, 333);
// 	}

// 	render () {
// 		const { label, content, activeTab, index, activateTab } = this.props;
// 		const { height } = this.state;
// 		const isActive = activeTab === index;
// 		const innerStyle = {
// 			height:  `${isActive ? height : 0}px`
// 		}

// 		return (
// 			<div className='panel' role='tabpanel' aria-expanded={ isActive }>
// 				<button className='panel__label' 	role='tab' onClick={ activateTab }> { label } 	</button>
// 				<div className='panel__inner'
// 					style={ innerStyle }
// 					aria-hidden={ !isActive }>
// 					<p className='panel__content'>
// 						{ content }
// 					</p>
// 				</div>
// 			</div>
// 		);
// 	}
// }

// class Header extends React.Component {
// 	constructor(props) {
// 		super(props);
		
// 		this.state = {
//       activeTab: 0,
//       panels: [
//         {
//           label: 'Seriously, Don\'t Use Icon Fonts',
//           content: 'Icons are everywhere. These "little miracle workers" (as John Hicks described them) help us reinforce meaning in the interfaces we design and build. Their popularity in web design has never been greater; the conciseness and versatility of pictograms in particular make them a lovely fit for displays large and small. But icons on the web have had their fair share of challenges.',
//         },
//         {
//           label: 'Screen Readers Actually Read That Stuff',
//           content: 'Most assistive devices will read aloud text inserted via CSS, and many of the Unicode characters icon fonts depend on are no exception. Best-case scenario, your "favorite" icon gets read aloud as "black favorite star." Worse-case scenario, it\'s read as "unpronounceable" or skipped entirely.',
//         },	
//         {
//           label: 'They Fail Poorly and Often',
//           content: 'When your icon font fails, the browser treats it like any other font and replaces it with a fallback. Best-case scenario, you\'ve chosen your fallback characters carefully and something weird-looking but communicative still loads. Worse-case scenario (and far more often), the user sees something completely incongruous, usually the dreaded "missing character" glyph.',
//         },
//         {
//           label: 'They\'re a Nightmare if You\'re Dyslexic',
//           content: 'Many dyslexic people find it helpful to swap out a website\'s typeface for something like OpenDyslexic. But icon fonts get replaced as well, which makes for a frustratingly broken experience.',
//         },
//         {
//           label: 'There\'s Already a Better Way',
//           content: 'SVG is awesome for icons! It\'s a vector image format with optional support for CSS, JavaScript, reusability, accessibility and a bunch more. It was made for this sort of thing.'
//         },
//       ]
// 		};
		
// 		this.activateTab = this.activateTab.bind(this);
// 	}
	
// 	activateTab(index) {
// 		this.setState(prev => ({
// 			activeTab: prev.activeTab === index ? -1 : index
// 		}));
// 	}
	
// 	render() {
// 		//const { panels } = this.props;
// 		const { activeTab } = this.state;
// 		return (
// 			<div className='accordion' role='tablist'>
// 				{this.state.panels.map((panel, index) =>
// 					<Panel
// 						key={ index }
// 						activeTab={ activeTab }
// 						index={ index }
// 						{ ...panel } 
// 						activateTab={ this.activateTab.bind(null, index) }
// 					/>
// 				)}
// 			</div>
// 		);
// 	}
// }




// export default Header;







// import React, { Component } from 'react';  
// import ReactDOM from "react-dom";
// import PhotoGrid from "react-photo-feed";
// import "react-photo-feed/library/style.css";
// import {  Container,  Row, Col } from 'bootstrap-4-react';

// const demoPhotos = [
// 	{
// 		id : 1, src : "https://images.unsplash.com/photo-1508921912186-1d1a45ebb3c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80",
// 		bigSrc : "https://images.unsplash.com/photo-1508921912186-1d1a45ebb3c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
// 	},
// 	{
// 		id : 2, src : "https://farm5.staticflickr.com/4240/35527849422_25a0a67df6_n.jpg",
// 		bigSrc : "https://farm5.staticflickr.com/4240/35527849422_25a0a67df6_b.jpg"
// 	},
// 	{
// 		id : 3, src : "https://farm5.staticflickr.com/4077/34824083444_f5f050e31c_n.jpg",
// 		bigSrc : "https://farm5.staticflickr.com/4077/34824083444_f5f050e31c_b.jpg"
// 	},
// 	{
// 		id : 4, src : "https://farm5.staticflickr.com/4240/35527849422_25a0a67df6_n.jpg",
// 		bigSrc : "https://farm5.staticflickr.com/4240/35527849422_25a0a67df6_b.jpg"
// 	},
// 	{
// 		id : 5, src : "https://farm5.staticflickr.com/4077/34824083444_f5f050e31c_n.jpg",
// 		bigSrc : "https://farm5.staticflickr.com/4077/34824083444_f5f050e31c_b.jpg"
// 	},
// 	{
// 		id : 6, src : "https://farm5.staticflickr.com/4240/35527849422_25a0a67df6_n.jpg",
// 		bigSrc : "https://farm5.staticflickr.com/4240/35527849422_25a0a67df6_b.jpg"
// 	},
// 	{
// 		id : 7, src : "https://farm5.staticflickr.com/4077/34824083444_f5f050e31c_n.jpg",
// 		bigSrc : "https://farm5.staticflickr.com/4077/34824083444_f5f050e31c_b.jpg"
// 	}
// ];


// class Header extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
     
//     }
   
// }
//     render() {
//       return (
//         <React.Fragment>
//           <Container>
//             <Row>
//               <Col>
//                 <PhotoGrid columns={3} photos={demoPhotos} />
//               </Col>
//             </Row>
//           </Container>
//         </React.Fragment>
//       );
//     }
//   }


//   export default Header;